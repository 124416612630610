import clsx from "clsx";
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Tooltip from '@material-ui/core/Tooltip';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Alert from "@material-ui/lab/Alert";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { isNumber } from "lodash";

import KidsIcon from "./KidsIcon.js";
import ManIcon from "./ManIcon.js";
import DoorIcon from "./DoorIcon.js";
import { RoomTypePicker } from "../ItineraryType/RoomTypePicker";

import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';

import axios from "axios";
import { validateRoomTypes } from "../ItineraryType/Functions/validateRoomTypes";
import GetCookie from "../Common/Functions/GetCookie";
import UpdateCart from "../Itinerary/Functions/UpdateCart.js";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
    formControlRoot: {
        borderLeft: 0,
        borderTopRightRadius: "20px",
        borderBottomRightRadius: "20px",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        padding: '6px 13px !important',
        position: "relative",
        height: 41
    },
    paddingPassenger: {
        paddingLeft: 16,
        paddingRight: 32
    },
    paperStepper: {
        position: "absolute",
        top: "100%",
        zIndex: "1 !important",
        background: "rgb(255, 255, 255) !important",
        borderRadius: "32px !important",
        boxShadow: "rgb(0 0 0 / 20%) 0px 6px 20px !important",
        marginTop: "12px !important",
        padding: "16px 0px 16px 16px !important"
    },
    paperChild: {
        maxHeight: "calc(100vh - 220px) !important",
        overflowX: 'hidden'
    },
    flexGrow: {
        WebkitBoxFlex: "1 !important",
        flexGrow: "1 !important"
    },
    itemPassenger: {
        WebkitBoxPack: "justify !important",
        WebkitBoxAlign: "center !important",
        color: "rgb(34, 34, 34) !important",
        paddingTop: "16px !important",
        paddingBottom: "16px !important",
        paddingRight: "4px !important",
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: "space-between !important",
        minWidth: "330px !important"
    },
    stepperPassenger: {
        WebkitBoxPack: "justify !important",
        WebkitBoxAlign: "center !important",
        display: "inline-flex !important",
        alignItems: "center !important",
        justifyContent: "space-between !important",
        width: "104px !important",
        height: "32px !important",
        color: "rgb(34, 34, 34) !important",
        fontWeight: "400 !important",
        lineHeight: "20px !important"
    },
    iconStepper: {
        cursor: "pointer",
        fontSize: 29
    },
    titleStepper: {
        fontSize: "16px !important",
        lineHeight: "20px !important",
        fontWeight: "600 !important"
    },
    labelStepper: {
        fontSize: "14px !important",
        lineHeight: "18px !important",
        fontWeight: "400 !important",
        color: "rgb(113, 113, 113) !important"
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    buttonContainer: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white',
        width: 'calc(100% - 15px)'
    },
    orangeText: {
        color: "#E6592F"
    },
    sectionTitle: {
        textAlign: "left",
        paddingLeft: 10,
        paddingRight: 40
    },
    title: {
        fontSize: 19,
        fontWeight: "bold"
    },
    customIconButton: {
        '&:focus': {
            outline: 'none !important'
        }
    },
    customSelect: {
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: "16px 14px"
        },
        "fontSize": 14
    },
    selectRoom: {
        fontSize: 13,
        marginTop: 10,
        marginBottom: 15,
        fontStyle: "italic"
    },
    warningAlert: {
        marginRight: 15
    },
    listItem: {
        padding: '0 16px'
    },
    listItemLabel: {
        marginBottom: 0
    },
    bold: {
        fontWeight: 'bold'
    },
    buttonWhite: {
        backgroundColor: 'white'
    },
    responsiveSize: {
        fontSize: 10
    },
    responsiveIcon: {
        fontSize: "12px !important"
    }
});

const HeaderPassenger = ({ isOpen, setIsOpen, onValidate, circuitId, circuitVersion, startDate, forBlocks, loading }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [spinValidate, setSpinValidate] = useState(false);

    const smallScreen = useMediaQuery(theme.breakpoints.between(960, 1258));

    const { enqueueSnackbar } = useSnackbar();
    const store_travelers = useSelector((store) => store.trip.travelers);
    const traveler_groups = useSelector((store) => store.trip.traveler_groups);
    const current_groups = useSelector((store) => store.accommodation.current_groups);
    const start_date = useSelector((store) => store.trip.start_date);
    const end_date = useSelector((store) => store.trip.end_date);
    const accommodation_cart = useSelector((store) => store.accommodation.cart);
    const car_cart = useSelector((store) => store.cars_search.cart);
    const flight_cart = useSelector((store) => store.flight.cart);
    const manual_item_list = useSelector((store) => store.cart.manual_item_list);
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const data_trip = useSelector((store) => store.trip.data_trip);
    const tripStartDate = useSelector((state) => state.trip.start_date);
    const all_data = useSelector((store) => store.trip.all_data);
    const prices_terrestrial = useSelector((store) => store.trip.prices_terrestrial);
    const tripCircuitId = useSelector((store) => store.trip.all_data?.from_circuit_id);
    const isDisabled = ['cercledesvoyages', 'continentsinsolites', 'terreslointaines'].includes(quotation_code) ? (data_trip.status_contract === "CONFIRMED" && data_trip.status_modification === "FIXED_PV") : false;

    const [adult, setAdult] = useState([]);
    const [baseAdult, setBaseAdult] = useState([]);
    const [children, setChildren] = useState([]);
    const [baseChildren, setBaseChildren] = useState([]);
    const [travelers, setTravelers] = useState([...store_travelers]);
    const [baseGroups, setBaseGroups] = useState([]);
    const [groups, setGroups] = useState([]);
    const [dates] = useState({ start_date: start_date, end_date: end_date });
    const [update, setUpdate] = useState(false);
    const [error, setError] = useState(false);
    const [maxPax, setMaxPax] = useState(null);
    const [maxAdultPax, setMaxAdultPax] = useState(null);
    const [maxChildPax, setMaxChildPax] = useState(null);
    const [adalteRooms, setAdalteRooms] = useState([]);
    const [pickups, setPickups] = useState(null);
    const [adalteLanguageCode, setAdalteLanguageCode] = useState(null);
    const [adalteErrors, setAdalteErrors] = useState({});
    const isAdalte = (
        data_trip?.identifier &&
        JSON.parse(data_trip.identifier).adalte_rooms
    ) ||
        forBlocks;

    const nb_cart_item = (accommodation_cart.length) + (car_cart.length) + (flight_cart.length) + (manual_item_list.length);
    useEffect(() => {
        if (isOpen === "passenger" && prices_terrestrial !== undefined && prices_terrestrial !== null) {
            let terrestrial_price = prices_terrestrial.find(terrestrial => terrestrial.periods.includes(all_data.circuit_period_id));
            if (terrestrial_price !== undefined) {
                let max_pax = 0;
                let max_adult_pax = 0;
                let max_child_pax = 0;
                if (terrestrial_price.model.variable.includes('NUMBER_OF_PERSONS') || terrestrial_price.model.variable.includes('NUMBER_OF_PERSONS_PRICE')) {
                    if (terrestrial_price.values.length === 1) {
                        if (terrestrial_price.values[0].value[1] > max_pax) {
                            max_pax = terrestrial_price.values[0].value[1];
                        }
                    } else {
                        let age_model = terrestrial_price.values.find((value) => { return value.variable === 'AGE' });
                        if (age_model !== undefined) {
                            let is_adult = age_model.value[0] >= 18;
                            terrestrial_price.values.map((value) => {
                                if (value.variable === 'NUMBER_OF_PERSONS' || value.variable === 'NUMBER_OF_PERSONS_PRICE') {
                                    if (is_adult) {
                                        if (value.value[1] > max_adult_pax) {
                                            max_adult_pax = value.value[1];
                                        }
                                    } else {
                                        if (value.value[1] > max_child_pax) {
                                            max_child_pax = value.value[1];
                                        }
                                    }
                                }
                            });
                        } else {
                            terrestrial_price.values.map((value) => {
                                if (value.variable === 'NUMBER_OF_PERSONS' || value.variable === 'NUMBER_OF_PERSONS_PRICE') {
                                    if (value.value[1] > max_pax) {
                                        max_pax = value.value[1];
                                    }
                                }
                            });
                        }
                    }
                }
                if (max_pax !== 0) {
                    setMaxPax(max_pax);
                }
                if (max_adult_pax !== 0) {
                    setMaxAdultPax(max_adult_pax);
                }
                if (max_child_pax !== 0) {
                    setMaxChildPax(max_child_pax);
                }
            }
        }
    }, [isOpen, adult, children]);
    useEffect(() => {
        if (traveler_groups.length !== 0) {
            let tmp_groups = [];
            traveler_groups.map((group) => {
                if (group.default) {
                    tmp_groups.push(group);
                }
            });
            setGroups(tmp_groups);
            setBaseGroups(JSON.parse(JSON.stringify(tmp_groups)));
        }
    }, [traveler_groups]);
    useEffect(() => {
        if (groups.length !== 0) {
            getCompo();
        }
    }, [groups]);
    useEffect(() => {
        getCompo();
        if (traveler_groups.length !== 0) {
            let tmp_groups = [];
            traveler_groups.map((group) => {
                if (group.default) {
                    tmp_groups.push(group);
                }
            });
            setGroups(tmp_groups);
            setBaseGroups(JSON.parse(JSON.stringify(tmp_groups)));
        }
    }, []);
    useEffect(() => {
        if (update) {
            getCompo();
        }
    }, [update]);
    useEffect(() => {
        getCompo();
    }, [store_travelers]);

    const removeTravelers = (id) => {
        let new_travelers = [];
        let tmp_groups = [...groups];
        let copy_groups = [];

        for (let i = 0; i < travelers.length; i++) {
            if (travelers[i].id !== id) {
                new_travelers.push(travelers[i]);
            } else {
                let room_index = tmp_groups.findIndex(el => el.travelers.includes(id));
                if (room_index !== undefined && room_index !== -1) {
                    let tmp_group = { ...tmp_groups[room_index] };
                    let tmp_traveler = [...tmp_group.travelers];
                    let tmp_travelers_list = [...tmp_group.travelers_list];
                    tmp_traveler = tmp_traveler.filter((el) => {
                        return el !== id;
                    });
                    tmp_travelers_list = tmp_travelers_list.filter((el) => {
                        return el.id !== id;
                    });
                    tmp_group.travelers = tmp_traveler;
                    tmp_group.travelers_list = tmp_travelers_list;
                    tmp_groups[room_index] = tmp_group;

                    if (tmp_groups[room_index].travelers_list.length === 0) {
                        tmp_groups.splice(room_index, 1);
                    }
                }
            }
        }
        setGroups(tmp_groups);
        setTravelers(new_travelers);
        setUpdate(true);
    };
    const addTravelers = (type) => {
        const { headers } = CheckBeforeRequest();
        let adults = 1;
        let children = 1;
        for (let i = 0; i < travelers.length; i++) {
            if (moment.utc(end_date).diff(moment.utc(travelers[i].birth_date), "y") >= 18) {
                adults++;
            } else {
                children++;
            }
        }
        axios({
            method: "POST",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/travelers/`,
            data: {
                first_name: t("global.first_name"),
                last_name: t("global.last_name"),
                birth_date: (type === "ADULT" ? moment.utc().subtract(30, "y").format("YYYY-MM-DD") : moment.utc().subtract(5, "y").format("YYYY-MM-DD"))
            }
        }).then(function (response) {
            let data = Object.assign({}, response.data);
            let new_travelers = [...travelers];
            data.room = null;
            new_travelers.push(data);
            setTravelers(new_travelers);
            setUpdate(true);
        }).catch(function (error) {
            //TODO: show snackbar error
        });
    };
    const handleOpen = (type) => {
        if (!isDisabled) {
            if (isOpen === null || isOpen !== "passenger") {
                setIsOpen("passenger");
            } else {
                if (type === "cancel") {
                    cancel();
                    getCompo();
                }
                setIsOpen(null);
            }
        } else {
            enqueueSnackbar(t("header.warning_amendment"), { variant: "warning" });
        }
    };
    const getCompo = () => {
        let tmp_adult = [];
        let tmp_child = [];
        let tmp_base_adult = [];
        let tmp_base_child = [];
        let new_travelers = [];
        let numero_adult = 1;
        let numero_child = 1;

        travelers.map((traveler) => {
            let tmp_traveler = Object.assign({}, traveler);
            tmp_traveler.default_name = false;
            let age = moment.utc(end_date).diff(moment.utc(tmp_traveler.birth_date), "y");
            groups.map((el, el_index) => {
                if (el.travelers.includes(traveler.id)) {
                    tmp_traveler.room = el_index;
                }
            });
            if (tmp_traveler.room === -1) {
                tmp_traveler.room = null;
            }
            if (tmp_traveler.first_name === t('global.first_name') && tmp_traveler.last_name === t('global.last_name')) {
                tmp_traveler.default_name = true;
            }
            if (age >= 18) {
                tmp_traveler.age_label = tmp_traveler.default_name ? `${t("flight_search.adult")} ${numero_adult}` : `${tmp_traveler.first_name} ${tmp_traveler.last_name}`;
                numero_adult += 1;
                tmp_adult.push(tmp_traveler);
            }
            else {
                tmp_traveler.age_label = tmp_traveler.default_name ? `${t("flight_search.child")} ${numero_child} (${age})` : `${tmp_traveler.first_name} ${tmp_traveler.last_name} (${age})`;
                numero_child += 1;
                tmp_child.push(tmp_traveler);
            }
            new_travelers.push(tmp_traveler);
        });
        store_travelers.map((traveler) => {
            if (moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y") >= 18) {
                tmp_base_adult.push(traveler);
            } else {
                tmp_base_child.push(traveler);
            }
        });
        setAdult(tmp_adult);
        setChildren(tmp_child);
        setBaseAdult(tmp_base_adult);
        setBaseChildren(tmp_base_child);
        setTravelers(new_travelers);
        setUpdate(false);
    };
    const cancel = () => {
        setTravelers([...store_travelers]);
        setAdult(baseAdult);
        setChildren(baseChildren);
        setGroups(baseGroups);
    };
    let travelers_without_group = [];
    let travelers_with_group = [];
    travelers.map((traveler) => {
        let has_group = false;
        for (let i = 0; i < groups.length; i++) {
            for (let j = 0; j < groups[i].travelers_list.length; j++) {
                if (groups[i].travelers_list[j].id === traveler.id) {
                    has_group = true;
                    travelers_with_group.push(traveler);
                    break;
                }
            }
        }
        if (!has_group) {
            travelers_without_group.push(traveler);
        }
    });
    const checkEqualGroups = (group_1, group_2) => {
        if (!Array.isArray(group_1) || !Array.isArray(group_2) || group_1.length !== group_2.length) {
            return false;
        }
        let cpy_group_1 = group_1.concat().sort();
        let cpy_group_2 = group_2.concat().sort();
        for (let i = 0; i < cpy_group_1.length; i++) {
            if (cpy_group_1[i] !== cpy_group_2[i]) {
                return false;
            }
        }
        return true;
    };
    const newGroupCallback = (new_current_groups, groups_length, groups) => {
        const { headers } = CheckBeforeRequest();
        if (new_current_groups.length === groups_length) {
            let request = {
                travelers: []
            };
            for (let i = 0; i < travelers.length; i++) {
                request.travelers.push(travelers[i].id);
            }
            axios({
                method: "PATCH",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/?token=${GetCookie("trip_token")}`,
                data: JSON.stringify(request)
            }).then(function (response) {
                let new_travelers = [];
                let trav_arr = [];
                response.data.travelers.map((traveler) => {
                    let copy_traveler = Object.assign({}, traveler);
                    trav_arr.push(traveler.id);
                    let age = moment(end_date).diff(moment(copy_traveler.birth_date), "y");
                    if (age >= 12) {
                        copy_traveler.flight_type = "ADT";
                    } else if (age >= 2) {
                        copy_traveler.flight_type = "CNN";
                    } else {
                        copy_traveler.flight_type = "INF";
                    }
                    copy_traveler.passenger_group = null;
                    new_travelers.push(copy_traveler);
                });
                dispatch({
                    type: "TRIP_SET_TRAVELER_GROUPS",
                    payload: {
                        traveler_groups: new_current_groups
                    }
                });
                dispatch({
                    type: "TRIP_SET_TRAVELERS",
                    payload: {
                        travelers: new_travelers
                    }
                });
                setTravelers(new_travelers);
                if (nb_cart_item > 0) {
                    let groups_id = [];
                    new_current_groups.map((group) => {
                        groups_id.push(group.id);
                    });
                    UpdateCart(
                        "COMP",
                        dispatch,
                        null,
                        null,
                        groups_id,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        adalteRooms?.map((room) => ({
                            ...room,
                            group_passenger_id: groups_id[parseInt(room.index) - 1]
                        })),
                        pickups,
                        adalteLanguageCode
                    );
                }
                handleOpen();
                setSpinValidate(false);
            }).catch(function (error) {
                //TODO: show snackbar error
            });
        }
    };
    const updateChildrenAge = (id, age) => {
        const { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            let request = {
                birth_date: moment.utc().subtract(age, "y").format("YYYY-MM-DD")
            };
            axios({
                method: "PATCH",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/travelers/${id}/`,
                data: JSON.stringify(request)
            }).then(function (response) {
                console.log(response.data);
                let new_travelers = [];
                for (let i = 0; i < travelers.length; i++) {
                    if (travelers[i].id !== id) {
                        new_travelers.push(travelers[i]);
                    } else {
                        new_travelers.push(response.data);
                    }
                }
                setTravelers(new_travelers);
                setUpdate(true);
            }).catch(function (error) {
                //TODO: show snackbar error
            });
        }
    };
    const requestCallback = (groups, new_current_groups, index) => {
        const { headers } = CheckBeforeRequest();
        console.log('groups:', groups);
        axios({
            method: "POST",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/travelers-group/?token=${GetCookie("trip_token")}`,
            data: {
                travelers: groups[index].travelers,
                default: true
            }
        }).then(response => {
            console.log(response.data);
            new_current_groups.push(response.data);
            newGroupCallback(new_current_groups, groups.length, groups);
            if (index !== groups.length - 1) {
                console.log('still looping');
                requestCallback(groups, new_current_groups, index + 1);
            }
        }).catch(error => {
            console.log('error:', error);
        });
    };
    const setParameters = (flag) => {
        if (isAdalte) {
            const [valid, errors] = validateRoomTypes(
                adalteRooms,
                (groups?.length ?? 0),
                t('home.modal.room-type-validation-required'),
                adalteRooms.findIndex((item) => {
                    return item.chosen_language_code;
                }) >= 0,
                adalteLanguageCode
            );
            if (!valid) {
                setAdalteErrors(errors);
                return;
            }
        }
        const { headers } = CheckBeforeRequest();
        setSpinValidate(true);
        let new_current_groups = [];
        if (travelers_without_group.length === 0 || flag) {
            for (let i = 0; i < groups.length; i++) {
                if (groups[i].travelers.length === 0) {
                    groups.splice(i, 1);
                    i = 0;
                }
            }
            let same = groups.length === baseGroups.length;
            if (same) {
                groups.map((group) => {
                    let already_exists = false;
                    baseGroups.map((current_group) => {
                        if (!already_exists && checkEqualGroups(group.travelers_list, current_group.travelers_list)) {
                            already_exists = true;
                        }
                    });
                    if (!already_exists) {
                        same = false;
                    }
                });
            }
            if (!same) {
                let has_adult = true;
                groups.map((group) => {
                    let has_group_adult = false;
                    group.travelers_list.map((traveler) => {
                        if (moment().diff(traveler.birth_date, "y") > 18) {
                            has_group_adult = true;
                        }
                    });
                    if (!has_group_adult) {
                        has_adult = false;
                    }
                });
                if (!has_adult) {
                    enqueueSnackbar(t("accommodation.room_without_adult"), {
                        variant: "warning"
                    });
                    setSpinValidate(false);
                } else {
                    groups.map((group) => {
                        let already_exists = false;
                        group.travelers = [];
                        group.travelers_list.map((traveler) => {
                            group.travelers.push(traveler.id);
                        });
                        traveler_groups.map((traveler_group) => {
                            axios({
                                method: "PATCH",
                                headers: headers,
                                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/travelers-group/${traveler_group.id}/`,
                                data: {
                                    default: false
                                }
                            }).then(function (response) {
                                console.log(response.data);
                            }).catch(function (error) {
                                //TODO: show snackbar error
                            });
                        });
                        //}
                    });
                    requestCallback(groups, new_current_groups, 0);
                }
            } else {
                dispatch({
                    type: "MATERIAL_UPDATE_PARAMETERS",
                    payload: {
                        start_date: dates.start_date,
                        end_date: dates.end_date,
                        current_groups: current_groups
                    }
                });
                dispatch({
                    type: "TRIP_SET_TRAVELERS",
                    payload: {
                        travelers: travelers
                    }
                });
                if (nb_cart_item > 0) {
                    let trav_arr = [];
                    groups.map((group) => {
                        if (group.default) {
                            trav_arr.push(group.id);
                        }
                    });
                    let groups_id = [];
                    current_groups.map((group) => {
                        groups_id.push(group.id);
                    });
                    UpdateCart(
                        "COMP",
                        dispatch,
                        null,
                        null,
                        groups_id,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        adalteRooms?.map((room) => ({
                            ...room,
                            group_passenger_id: groups_id[parseInt(room.index) - 1]
                        })),
                        pickups,
                        adalteLanguageCode
                    );
                }
                setSpinValidate(false);
                handleOpen();
            }
        } else {
            setSpinValidate(false);
            setError(true);
            setIsOpen("passengerAlert");
        }
    };

    useEffect(() => {
        const data = data_trip?.identifier ?
            JSON.parse(data_trip.identifier) :
            null;
        const rooms = data?.adalte_rooms ?? null;
        if (rooms) {
            setAdalteRooms(rooms);
            setAdalteLanguageCode(
                data?.chosen_language_code ?? null
            );
            setPickups(data?.chosen_pickup ?? null);
        }
    }, [data_trip]);

    return (
        <Fragment>
            {
                !isDisabled && !forBlocks && (
                    <Button onClick={handleOpen} variant={"outlined"} fullWidth className={clsx(classes.formControlRoot, classes.buttonWhite, { [classes.responsiveSize]: smallScreen })} disableRipple={true}>
                        <ManIcon style={{ verticalAlign: 'sub', opacity: '80%', color: '#212529', height: 18 }} className={clsx({ [classes.responsiveIcon]: smallScreen })} />
                        <span style={{ paddingRight: 4, fontWeight: "bold" }}>{baseAdult.length}</span>
                        <KidsIcon style={{ verticalAlign: 'sub', opacity: '80%', color: '#212529', height: 18 }} className={clsx({ [classes.responsiveIcon]: smallScreen })} />
                        <span style={{ paddingLeft: 4, fontWeight: "bold" }}>{baseChildren.length}</span>
                        <DoorIcon style={{ verticalAlign: 'sub', opacity: '80%', color: '#212529', height: 18 }} className={clsx({ [classes.responsiveIcon]: smallScreen })} />
                        <span style={{ paddingLeft: 4, fontWeight: "bold" }}>{baseGroups.length}</span>
                    </Button>
                )
            }
            {
                isDisabled && !forBlocks && (
                    <Tooltip title={t("header.warning_amendment")}>
                        <Button onClick={handleOpen} variant={"outlined"} fullWidth className={clsx(classes.formControlRoot, classes.buttonWhite, { [classes.responsiveSize]: smallScreen })} disableRipple={true}>
                            <ManIcon style={{ verticalAlign: 'sub', opacity: '80%', color: '#212529', height: 18 }} className={clsx({ [classes.responsiveIcon]: smallScreen })} />
                            <span style={{ paddingRight: 4, fontWeight: "bold" }}>{baseAdult.length}</span>
                            <KidsIcon style={{ verticalAlign: 'sub', opacity: '80%', color: '#212529', height: 18 }} className={clsx({ [classes.responsiveIcon]: smallScreen })} />
                            <span style={{ paddingLeft: 4, fontWeight: "bold" }}>{baseChildren.length}</span>
                            <DoorIcon style={{ verticalAlign: 'sub', opacity: '80%', color: '#212529', height: 18 }} className={clsx({ [classes.responsiveIcon]: smallScreen })} />
                            <span style={{ paddingLeft: 4, fontWeight: "bold" }}>{baseGroups.length}</span>
                        </Button>
                    </Tooltip>
                )
            }
            {
                (isOpen === "passenger" || isOpen === "passengerAlert") && (
                    <Paper
                        className={classes.paperStepper}
                        elevation={5}
                        children={
                            <ClickAwayListener disableReactTree={true} onClickAway={() => {
                                handleOpen("cancel");
                                //if (!error && isOpen === "passenger") {
                                //}
                            }}>
                                <div className={classes.paperChild}>
                                    {
                                        !forBlocks &&
                                        <>
                                            <div className={classes.sectionTitle}>
                                                <span className={classes.title}>{t("global.traveler_number")}</span>
                                            </div>
                                            {
                                                maxPax !== null &&
                                                <Grid item>
                                                    <Typography className={classes.genericText}>{maxPax + ' ' + t('poi.max_pax')}</Typography>
                                                </Grid>
                                            }
                                            {
                                                maxAdultPax !== null &&
                                                <Grid item>
                                                    <Typography className={classes.genericText}>{t('poi.max_adult_pax', { max: maxAdultPax })}</Typography>
                                                </Grid>
                                            }
                                            {
                                                maxChildPax !== null &&
                                                <Grid item>
                                                    <Typography className={classes.genericText}>{t('poi.max_child_pax', { max: maxChildPax })}</Typography>
                                                </Grid>
                                            }
                                            <List className={classes.paddingPassenger}>
                                                <ListItem className={classes.itemPassenger}>
                                                    <div className={classes.flexGrow}>
                                                        <div className={classes.titleStepper}>{t("flight_search.adults")}</div>
                                                        <div className={classes.labelStepper}>{t("header.18_more")}</div>
                                                    </div>
                                                    <div className={classes.stepperPassenger}>
                                                        <IconButton className={classes.customIconButton} disabled={adult.length === 0} disableRipple={true} onClick={() => {
                                                            removeTravelers(adult[adult.length - 1].id);
                                                        }}>
                                                            <RemoveCircleOutlineOutlinedIcon className={classes.iconStepper} />
                                                        </IconButton>
                                                        <div>{adult.length}</div>
                                                        <IconButton className={classes.customIconButton} disableRipple={true} onClick={() => {
                                                            addTravelers("ADULT");
                                                        }} disabled={maxPax !== null ? adult.length + children.length === maxPax : (maxAdultPax !== null ? adult.length === maxAdultPax : false)}>
                                                            <AddCircleOutlineOutlinedIcon className={classes.iconStepper} />
                                                        </IconButton>
                                                    </div>
                                                </ListItem>
                                                <Divider />
                                                <ListItem className={classes.itemPassenger}>
                                                    <div className={classes.flexGrow}>
                                                        <div className={classes.titleStepper}>{t("flight_search.children")}</div>
                                                        <div className={classes.labelStepper}>{t("header.0_to_17")}</div>
                                                    </div>
                                                    <div className={classes.stepperPassenger}>
                                                        <IconButton disabled={children.length === 0} className={classes.customIconButton} onClick={() => {
                                                            removeTravelers(children[children.length - 1].id);
                                                        }}>
                                                            <RemoveCircleOutlineOutlinedIcon className={classes.iconStepper} />
                                                        </IconButton>
                                                        <div>{children.length}</div>
                                                        <IconButton onClick={() => {
                                                            addTravelers("CHILDREN");
                                                        }} className={classes.customIconButton} disabled={maxPax !== null ? adult.length + children.length === maxPax : (maxChildPax !== null ? children.length === maxChildPax : false)}>
                                                            <AddCircleOutlineOutlinedIcon className={classes.iconStepper} />
                                                        </IconButton>
                                                    </div>

                                                </ListItem>
                                            </List>
                                            <Grid container className={classes.paddingPassenger} spacing={2}>
                                                {
                                                    children.map((child, child_index) => {
                                                        let age = moment.utc(end_date).diff(moment.utc(child.birth_date), "y");
                                                        return (
                                                            <Grid item xs={6} key={child_index}>
                                                                <FormControl variant={"outlined"} fullWidth>
                                                                    <InputLabel id={`label-input-age-${child_index}`}>{`${t("header.child_age")}${child_index + 1}`}</InputLabel>
                                                                    <Select
                                                                        labelId={`label-input-age-${child_index}`}
                                                                        id={`input-age-${child_index}`}
                                                                        value={age}
                                                                        //onChange={handleChange}
                                                                        MenuProps={{ disablePortal: true }}
                                                                        label={`${t("header.child_age")} 1000`}
                                                                        className={classes.customSelect}
                                                                        onChange={(e) => {
                                                                            updateChildrenAge(child.id, e.target.value);
                                                                        }}
                                                                    >
                                                                        <MenuItem value={0}>0</MenuItem>
                                                                        <MenuItem value={1}>1</MenuItem>
                                                                        <MenuItem value={2}>2</MenuItem>
                                                                        <MenuItem value={3}>3</MenuItem>
                                                                        <MenuItem value={4}>4</MenuItem>
                                                                        <MenuItem value={5}>5</MenuItem>
                                                                        <MenuItem value={6}>6</MenuItem>
                                                                        <MenuItem value={7}>7</MenuItem>
                                                                        <MenuItem value={8}>8</MenuItem>
                                                                        <MenuItem value={9}>9</MenuItem>
                                                                        <MenuItem value={10}>10</MenuItem>
                                                                        <MenuItem value={11}>11</MenuItem>
                                                                        <MenuItem value={12}>12</MenuItem>
                                                                        <MenuItem value={13}>13</MenuItem>
                                                                        <MenuItem value={14}>14</MenuItem>
                                                                        <MenuItem value={15}>15</MenuItem>
                                                                        <MenuItem value={16}>16</MenuItem>
                                                                        <MenuItem value={17}>17</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                        );
                                                    })
                                                }
                                            </Grid>
                                            <br />
                                            <div className={classes.sectionTitle}>
                                                <span className={classes.title}>{t("home.modal.room_number")}</span>
                                            </div>
                                            <List className={classes.paddingPassenger}>
                                                <ListItem className={classes.itemPassenger}>
                                                    <div className={classes.flexGrow}>
                                                        <div className={classes.titleStepper}>{t("global.rooms")}</div>
                                                    </div>
                                                    <div className={classes.stepperPassenger}>
                                                        <IconButton disabled={groups.length <= 1} className={classes.customIconButton} onClick={() => {
                                                            if (groups.length > 1) {
                                                                let groups_cpy = [];
                                                                for (let i = 0; i < groups.length - 1; i++) {
                                                                    groups_cpy.push({
                                                                        travelers: [],
                                                                        travelers_list: []
                                                                    });
                                                                    setGroups(groups_cpy);
                                                                }
                                                            }
                                                        }}>
                                                            <RemoveCircleOutlineOutlinedIcon className={classes.iconStepper} />
                                                        </IconButton>
                                                        <div>{groups.length}</div>
                                                        <IconButton disabled={groups.length >= travelers.length} className={classes.customIconButton} onClick={() => {
                                                            if (groups.length < travelers.length) {
                                                                let groups_cpy = groups.slice();
                                                                groups_cpy.push({
                                                                    travelers: [],
                                                                    travelers_list: []
                                                                });
                                                                setGroups(groups_cpy);
                                                            }
                                                        }}>
                                                            <AddCircleOutlineOutlinedIcon className={classes.iconStepper} />
                                                        </IconButton>
                                                    </div>
                                                </ListItem>
                                            </List>
                                            <div className={classes.sectionTitle}>
                                                <span className={classes.title}>{t("home.modal.room_allocation")}</span>
                                                <Typography className={classes.selectRoom}>{t("home.modal.select_room")}</Typography>
                                            </div>
                                            {
                                                travelers_without_group.length > 0 && (
                                                    <Fragment>
                                                        <Alert className={classes.warningAlert} severity={travelers_without_group.length === adult.length + children.length ? "error" : "warning"} variant={"filled"}>{t("booking.prices_changed_1") + " " + travelers_without_group.length + " " + t("header.unassigned travelers") + " : "}</Alert>
                                                        {/*<Typography className={classes.sectionTitle}> </Typography> */}
                                                        {
                                                            travelers_without_group.map((traveler, index_traveler) => {
                                                                return (
                                                                    <Typography className={`${classes.sectionTitle} ${classes.orangeText}`} key={index_traveler}>{traveler.age_label}</Typography>
                                                                )
                                                            })
                                                        }
                                                        <br />
                                                    </Fragment>
                                                )
                                            }
                                            <Grid container style={{ width: "480px" }}>
                                                {
                                                    groups.map((group, index_group) => {
                                                        return (
                                                            <Grid key={index_group} item md={6} xs={12}>
                                                                <Typography className={classes.bold}>{t("accommodation.room")} {index_group + 1} :</Typography>
                                                                <List>
                                                                    {
                                                                        travelers.map((traveler, index_traveler) => {
                                                                            return (
                                                                                <ListItem key={index_traveler} className={classes.listItem}>
                                                                                    <FormControlLabel className={classes.listItemLabel} control={<Checkbox checked={group.travelers.includes(traveler.id)} onChange={(e) => {
                                                                                        let groups_cpy = groups.slice();
                                                                                        if (e.target.checked) {
                                                                                            groups_cpy.map((group_check, group_check_index) => {
                                                                                                const index = group_check.travelers.indexOf(traveler.id);
                                                                                                if (index > -1) {
                                                                                                    groups_cpy[group_check_index] = Object.assign({}, groups[group_check_index]);
                                                                                                    groups_cpy[group_check_index].travelers = groups_cpy[group_check_index].travelers.slice();
                                                                                                    groups_cpy[group_check_index].travelers.splice(index, 1);
                                                                                                    groups_cpy[group_check_index].travelers_list = groups_cpy[group_check_index].travelers_list.slice();
                                                                                                    groups_cpy[group_check_index].travelers_list.splice(index, 1);
                                                                                                }
                                                                                            });
                                                                                            groups_cpy[index_group] = Object.assign({}, groups[index_group]);
                                                                                            groups_cpy[index_group].travelers = groups_cpy[index_group].travelers.slice();
                                                                                            groups_cpy[index_group].travelers.push(traveler.id);
                                                                                            groups_cpy[index_group].travelers_list = groups_cpy[index_group].travelers_list.slice();
                                                                                            groups_cpy[index_group].travelers_list.push(traveler);
                                                                                        } else {
                                                                                            const index = groups_cpy[index_group].travelers.indexOf(traveler.id);
                                                                                            if (index > -1) {
                                                                                                groups_cpy[index_group].travelers = groups_cpy[index_group].travelers.slice();
                                                                                                groups_cpy[index_group].travelers.splice(index, 1);
                                                                                                groups_cpy[index_group].travelers_list = groups_cpy[index_group].travelers_list.slice();
                                                                                                groups_cpy[index_group].travelers_list.splice(index, 1);
                                                                                            }
                                                                                        }
                                                                                        setGroups(groups_cpy);
                                                                                        setUpdate(true);
                                                                                    }} />} label={traveler.age_label} />
                                                                                </ListItem>
                                                                            )
                                                                        })
                                                                    }
                                                                </List>
                                                            </Grid>
                                                        );
                                                    })
                                                }
                                            </Grid>
                                        </>
                                    }
                                    <Grid container style={{ width: "480px" }}>
                                        {
                                            isAdalte &&
                                            (
                                                isNumber(circuitId) ||
                                                isNumber(tripCircuitId)
                                            ) &&
                                            <RoomTypePicker
                                                circuitId={
                                                    circuitId ?? tripCircuitId
                                                }
                                                version={
                                                    circuitVersion ??
                                                    parseInt(GetCookie('trip_id_version') ?? '-1')
                                                }
                                                startDate={
                                                    // eslint-disable-next-line no-nested-ternary
                                                    startDate ?
                                                        window.moment.utc(startDate) :
                                                        (
                                                            tripStartDate ?
                                                                window.moment.utc(tripStartDate) :
                                                                window.moment.utc()
                                                        )
                                                }
                                                adultTravelers={adult}
                                                childTravelers={children}
                                                groups={groups}
                                                pickups={pickups}
                                                onChangePickups={setPickups}
                                                adalteLanguageCode={adalteLanguageCode}
                                                onChangeAdalteLanguageCode={setAdalteLanguageCode}
                                                rooms={adalteRooms}
                                                onChangeRooms={setAdalteRooms}
                                                errors={adalteErrors}
                                                onChangeErrors={setAdalteErrors}
                                                includeAge={false}
                                                forVersionUpdate={!forBlocks}
                                            />
                                        }
                                    </Grid>
                                    <DialogActions className={classes.buttonContainer}>
                                        <Button onClick={() => {
                                            handleOpen("cancel");
                                        }}>{t("accommodation.cancel")}</Button>
                                        {
                                            spinValidate ? (
                                                <CircularProgress size={17} />
                                            ) : (
                                                <Button
                                                    variant={"contained"}
                                                    startIcon={
                                                        loading &&
                                                        <CircularProgress size="1em" />
                                                    }
                                                    className={classes.orangeButton}
                                                    onClick={() => {
                                                        if (onValidate) {
                                                            const [valid, errors] = validateRoomTypes(
                                                                adalteRooms,
                                                                (groups?.length ?? 0),
                                                                t('home.modal.room-type-validation-required'),
                                                                isAdalte &&
                                                                (
                                                                    isNumber(circuitId) ||
                                                                    isNumber(tripCircuitId)
                                                                ),
                                                                adalteLanguageCode
                                                            );
                                                            if (!valid) {
                                                                setAdalteErrors(errors);
                                                                return;
                                                            }
                                                            onValidate(adalteRooms);
                                                        } else {
                                                            setParameters(false);
                                                        }
                                                    }}
                                                    disabled={(isAdalte && (adalteRooms.length === 0 || pickups === null || adalteLanguageCode === null)) || loading}
                                                >
                                                    {t("global.validate")}
                                                </Button>
                                            )
                                        }
                                    </DialogActions>
                                </div>
                            </ClickAwayListener>
                        }
                        style={
                            forBlocks ?
                                { position: 'static' } :
                                undefined
                        }
                    />
                )
            }
            <Dialog open={error} style={{ borderRadius: "32px !important" }}>
                <DialogContent style={{ fontWeight: "bold" }}>
                    {travelers_with_group.length === 0 ? t("trip.no_travelers_in_room") : t("trip.traveler_not_assigned_to_room")}
                </DialogContent>
                <DialogActions>
                    {
                        travelers_with_group.length === 0 && (
                            <Button variant={"contained"} className={classes.orangeButton} onClick={() => {
                                setError(false);
                            }}>{t("global.edit")}</Button>
                        )
                    }
                    {
                        travelers_with_group.length >= 1 && (
                            <Fragment>
                                <Button onClick={() => {
                                    setError(false);
                                }}>{t("accommodation.cancel")}</Button>
                                <Button variant={"contained"} className={classes.orangeButton} onClick={() => {
                                    setError(false); setParameters(true);
                                }}>{t("itinerary.continue")}</Button>
                            </Fragment>
                        )
                    }
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};
export default HeaderPassenger;
