import React, { useEffect, useState, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSnackbar } from 'notistack';

import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';

import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import CloseIcon from '@material-ui/icons/Close';
import Folder from '@material-ui/icons/Folder';

import clsx from "clsx";
import GetTrip from "../LeftMenuPanel/Functions/GetTrip";
import GetUser from "../LeftMenuPanel/Functions/GetUser";
import GetCustomFilters from "../LeftMenuPanel/Functions/GetCustomFilters";
import TripListRow from "./TripListRow";
import TripListFilters from "./TripListFilters";
import DialogColumn from "./DialogColumn";
//---- CSS ----//
import "../../../Style/Menu/TripList.css";

import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import axios from "axios";
import DuplicateModal from "../TripList/DuplicateModal.jsx";
import MaterialDuplicateDialog from "../../ItineraryType/MaterialDuplicateDialog";
import GetUserParam from "../LeftMenuPanel/Functions/GetUserParam";

const useStyles = makeStyles({
    table: {
        minWidth: 650
    },
    stickyOverflow: {
        overflowX: 'auto'
    },
    sticky: {
        position: 'sticky',
        top: 70,
        backgroundColor: 'white',
        zIndex: 1
    },
    paddingCell: {
        "& .mui-jss-MuiTableCell-root": {
            fontSize: 12
        }
    },
    marginBottom70: {
        marginBottom: 70
    },
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    list: {
        width: 500,
        padding: 15
    },
    paddingList: {
        padding: 15
    },
    title: {
        flex: '1 1 100%'
    },
    drawer: {
        "& .mui-jss-MuiDrawer-paper": {
            zIndex: 1,
            paddingTop: 70
        }
    },
    mobileDrawer: {
        "& .mui-jss-MuiDrawer-paper": {
            zIndex: 1,
            width: "100%"
        }
    },
    fileTitle: {
        fontWeight: "bold",
        textTransform: "uppercase",
        fontSize: 20
    },
    button: {
        float: "right",
        background: "#E6592F",
        color: "white"
    },
    blueColor: {
        color: '#4A75DA',
        fontSize: 12,
        fontWeight: 500,
        marginLeft: 8
    },
    paginationItemActive: {
        outline: "5px auto -webkit-focus-ring-color"
    },
    pagination: {
        "& button:focus": {
            outline: "none !important"
        }
    },
    searchBar: {
        padding: '9px 0',
        maxWidth: "88%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "inline-block",
        height: 18
    },
    textFieldBorder: {
        '& fieldset': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderRight: 0
        }
    },
    actif: {
        border: 'solid 1px',
        backgroundColor: 'rgb(235, 242, 249)'
    }
});
let timeout = null;

const TripList = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const downDesktop = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const dispatch = useDispatch();
    const { pass_check, headers } = CheckBeforeRequest();
    const { enqueueSnackbar } = useSnackbar();
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const column_type = {
        REFERENCE: t("menu.file"),
        TARGET_CLIENT: t("menu.trip_list.agency"),
        CONTACT: !["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines'].includes(quotation_code) ? t("menu.trip_list.applicant") : t("menu.trip_list.client"),
        DESTINATION: t("header.destination"),
        NB_PAX: t("menu.trip_list.pax"),
        START_DATE: t("itinerary.departure_date"),
        END_DATE: t("itinerary.return_date"),
        CREATOR: t("menu.trip_list.creator_header"),
        MODIFIED_DATE: t("menu.edition_day"),
        CREATE_DATE: t("menu.created_date"),
        USER_TO: !["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines'].includes(quotation_code) ? t("menu.trip_list.user_to") : t("menu.trip_list.user_bo"),
        USER_TA: !["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines'].includes(quotation_code) ? t("menu.trip_list.user_ta") : t("menu.trip_list.seller"),
        NAME: t("menu.trip_list.file_name"),
        CONFIRMATION_DATE: t("menu.trip_list.date_of") + " " + t("menu.trip_list.confirmation"),
        NB_NIGHT: t("global.nights"),
        TO_AGENCY: t("menu.trip_list.to_agency"),
        COMPLETION_PERCENTAGE: t("menu.trip_list.completion_percentage"),
        MARGIN: t("menu.margin_title"),
        PAID: t("menu.trip_list.total_paid"),
        REST: t("global.rest"),
        TOTAL_COST: t("global.total_price")
    };
    let init_columns = [];
    if (['cercledesvoyages', 'continentsinsolites', 'tropicalementvotre', 'terreslointaines'].includes(quotation_code)) {
        init_columns = [
            {
                field_name: t("menu.file"),
                can_edit: false,
                active: true,
                order: 0
            },
            {
                field_name: t("menu.trip_list.client"),
                can_edit: true,
                active: true,
                order: 1
            },
            {
                field_name: t("header.destination"),
                can_edit: true,
                active: true,
                order: 2
            },
            {
                field_name: t("itinerary.departure_date"),
                can_edit: true,
                active: true,
                order: 3
            },
            {
                field_name: t("menu.trip_list.user_bo"),
                can_edit: true,
                active: true,
                order: 4
            },
            {
                field_name: t("menu.trip_list.seller"),
                can_edit: true,
                active: true,
                order: 5
            },
            {
                field_name: t("menu.trip_list.file_name"),
                can_edit: true,
                active: true,
                order: 6
            },
            {
                field_name: t("menu.trip_list.pax"),
                can_edit: true,
                active: false,
                order: 7
            },
            {
                field_name: t("itinerary.return_date"),
                can_edit: true,
                active: false,
                order: 8
            },
            {
                field_name: t("menu.trip_list.creator_header"),
                can_edit: true,
                active: false,
                order: 9
            },
            {
                field_name: t("menu.created_date"),
                can_edit: true,
                active: false,
                order: 10
            },
            {
                field_name: t("menu.edition_day"),
                can_edit: true,
                active: false,
                order: 11
            },

            {
                field_name: t("menu.trip_list.date_of") + " " + t("menu.trip_list.confirmation"),
                can_edit: true,
                active: false,
                order: 12
            },

            {
                field_name: t("global.nights"),
                can_edit: true,
                active: false,
                order: 13
            },
            {
                field_name: t("menu.trip_list.completion_percentage"),
                can_edit: true,
                active: false,
                order: 14
            },
            {
                field_name: t("menu.trip_list.agency"),
                can_edit: true,
                active: false,
                order: 15
            },
            {
                field_name: t("menu.margin_title"),
                can_edit: true,
                active: false,
                order: 16
            },
            {
                field_name: t("menu.trip_list.total_paid"),
                can_edit: true,
                active: false,
                order: 17
            },
            {
                field_name: t("global.rest"),
                can_edit: true,
                active: false,
                order: 18
            },
            {
                field_name: t("global.total_price"),
                can_edit: true,
                active: false,
                order: 19
            }
        ];
    } else {
        init_columns = [
            {
                field_name: t("menu.file"),
                can_edit: false,
                active: true,
                order: 0
            },
            {
                field_name: t("menu.trip_list.agency"),
                can_edit: true,
                active: false,
                order: 1
            },
            {
                field_name: t("menu.trip_list.applicant"),
                can_edit: true,
                active: true,
                order: 2
            },
            {
                field_name: t("header.destination"),
                can_edit: true,
                active: true,
                order: 3
            },
            {
                field_name: t("menu.trip_list.pax"),
                can_edit: true,
                active: true,
                order: 4
            },
            {
                field_name: t("itinerary.departure_date"),
                can_edit: true,
                active: true,
                order: 5
            },
            {
                field_name: t("itinerary.return_date"),
                can_edit: true,
                active: false,
                order: 6
            },
            {
                field_name: t("menu.trip_list.creator_header"),
                can_edit: true,
                active: true,
                order: 7
            },
            {
                field_name: t("menu.created_date"),
                can_edit: true,
                active: false,
                order: 8
            },
            {
                field_name: t("menu.edition_day"),
                can_edit: true,
                active: false,
                order: 9
            },
            {
                field_name: t("menu.trip_list.user_to"),
                can_edit: true,
                active: true,
                order: 10
            },
            {
                field_name: t("menu.trip_list.user_ta"),
                can_edit: true,
                active: false,
                order: 11
            },
            {
                field_name: t("menu.trip_list.date_of") + " " + t("menu.trip_list.confirmation"),
                can_edit: true,
                active: false,
                order: 12
            },
            {
                field_name: t("menu.trip_list.file_name"),
                can_edit: true,
                active: false,
                order: 11
            },
            {
                field_name: t("global.nights"),
                can_edit: true,
                active: false,
                order: 13
            },
            {
                field_name: t("menu.trip_list.to_agency"),
                can_edit: true,
                active: false,
                order: 14
            },
            {
                field_name: t("menu.trip_list.completion_percentage"),
                can_edit: true,
                active: false,
                order: 15
            },
            {
                field_name: t("menu.margin_title"),
                can_edit: true,
                active: false,
                order: 16
            },
            {
                field_name: t("menu.trip_list.total_paid"),
                can_edit: true,
                active: false,
                order: 17
            },
            {
                field_name: t("global.rest"),
                can_edit: true,
                active: false,
                order: 18
            },
            {
                field_name: t("global.total_price"),
                can_edit: true,
                active: false,
                order: 19
            }
        ];
    }
    const user = useSelector(store => store.user.user);
    const trip_list_filter = useSelector(store => store.menu.trip_list_filter);
    const trip_list_filter_prev = useSelector(store => store.menu.trip_list_filter_prev);
    const active_page = useSelector(store => store.menu.active_page);
    const requested_active_page = useSelector(store => store.menu.requested_active_page);
    const trip_list_search = useSelector(store => store.menu.trip_list_search);
    const trip_list_ordering = useSelector(store => store.menu.trip_list_ordering);
    const trip_list_loading = useSelector(store => store.menu.trip_list_loading);
    const trip_list_search_prev = useSelector(store => store.menu.trip_list_search_prev);
    const trip_list_ordering_prev = useSelector(store => store.menu.trip_list_ordering_prev);
    const trip_list = useSelector(store => store.menu.trip_list);
    const trip_list_nb = useSelector(store => store.menu.trip_list_nb);
    const myFiles = useSelector(store => store.menu.myFiles);
    const to_show_version = useSelector(store => store.menu.to_show_version);
    const customFiltersSelected = useSelector(store => store.menu.customFiltersSelected);
    const home_modal = useSelector(store => store.home.modal);
    const userList = useSelector(store => store.menu.userList);
    const userAgenceList = useSelector(store => store.menu.userAgenceList);
    const language = useSelector(store => store.header.language);

    const [openDrawer, setOpenDrawer] = useState(false);
    const [openColumns, setOpenColumns] = useState(false);
    const [update, setUpdate] = useState(false);
    const [status_choices, setStatusChoices] = useState([]);
    const [order, setOrder] = useState(user.ordering_ascend ? 'asc' : 'desc');
    const [orderBy, setOrderBy] = useState(user.ordering !== null ? user.ordering : t("menu.created_date"));
    const [columns, setColumns] = useState(init_columns);
    const [searchInput, setSearchInput] = useState(trip_list_search !== null ? trip_list_search : "");
    const [duplicationOpen, setDuplicationOpen] = useState(false);
    const tripFiltersRef = useRef(null);
    const loadingRef = useRef(null);
    useEffect(() => {
        if (!["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines'].includes(quotation_code)) {
            GetUser(user.client, dispatch);
        } else {
            GetUserParam(user.client, dispatch, "is_bo_user", t, "tripList");
            GetUserParam(user.client, dispatch, "is_seller_user", t, "tripList");
        }
        if (trip_list === null) {
            getAgencies();
            getData();
        }
        getFilters();
        getColumn();
        let temp_status_choices = [];
        if (JSON.parse(localStorage.getItem("config")).quotation_code === "verdie") {
            temp_status_choices = ["ONGOING", "TOBOOK_VERDIE", "AGENCY_MOD", "ADDITEM", "ONREQUEST", "BOOKED", "CLOSED", "CANCELLED", "ARCHIVED"];
        } else if (JSON.parse(localStorage.getItem("config")).quotation_code === "visiteurs" || JSON.parse(localStorage.getItem("config")).quotation_code === "beachcomber") {
            temp_status_choices = ["ONGOING", "VALIDATED", "USER_VALID", "TOBOOK", "ADDITEM", "ONREQUEST", "BOOKED", "CLOSED", "CANCELLED", "INPROGRESS", "FINISHED"];
        } else if (JSON.parse(localStorage.getItem("config")).quotation_code === "marcovasco" || JSON.parse(localStorage.getItem("config")).quotation_code === "americavoyages" || JSON.parse(localStorage.getItem("config")).quotation_code === "tripadekua") {
            temp_status_choices = ["ONGOING", "VALIDATED", "ADDITEM", "ONREQUEST", "BOOKED", "CLOSED", "CANCELLED"];
        } else if (JSON.parse(localStorage.getItem("config")).quotation_code === "volonline") {
            temp_status_choices = ["ONGOING", "VALIDATED", "USER_VALID", "ADDITEM", "TOBOOK", "BOOKED", "CANCELLED"];
        }

        //if (displayed_version.status === "INPROGRESS" || displayed_version.status === "FINISHED"){
        //temp_status_choices.push(displayed_version.status);
        //};
        setStatusChoices(temp_status_choices);
    }, []);
    useEffect(() => {
        if (props.crmData?.user) getData();
    }, [props.crmData]);
    useEffect(() => {
        if (requested_active_page !== active_page) {
            getData();
        }
    }, [active_page]);
    useEffect(() => {
        if (trip_list_search !== null && trip_list_search_prev !== trip_list_search) {
            getData();
        }
    }, [trip_list_search]);
    useEffect(() => {
        if (trip_list_filter !== null && trip_list_filter_prev !== trip_list_filter) {
            getData();
        }
    }, [trip_list_filter]);
    //useEffect(() => {
    //if (trip_list_ordering !== null && trip_list_ordering_prev !== undefined && trip_list_ordering_prev !== trip_list_ordering) {
    //getData();
    //}
    //}, [trip_list_ordering]);
    useEffect(() => {
        if (trip_list_ordering !== null && trip_list_ordering_prev !== trip_list_ordering) {
            getData();
        }
    }, [trip_list_ordering]);
    useEffect(() => {
        if (update) {
            getData();
            setUpdate(false);
        }
    }, [update]);
    useEffect(() => {
        getColumn();
    }, [user]);
    useEffect(() => {
        let tmp = (order === "desc" ? "-" : "");
        if (orderBy === t("menu.file")) {
            tmp += "trip_reference";
        }
        if (orderBy === t("menu.trip_list.agency")) {
            tmp += "target_client__name";
        }
        if (orderBy === t("menu.trip_list.applicant")) {
            tmp += "current_version__contact_traveler__last_name";
        }
        if (orderBy === t("header.destination")) {
            tmp += "current_version__destination__current_version__localization__name";
        }
        if (orderBy === t("menu.trip_list.pax")) {
            tmp += "nb_pax";
        }
        if (orderBy === t("menu.created_date")) {
            tmp += "current_version__created_date";
        }
        if (orderBy === t("menu.trip_list.creator_header")) {
            tmp += "creator__username";
        }
        if (orderBy === t("menu.trip_list.date_of") + " " + t("menu.trip_list.confirmation")) {
            tmp += "current_version__confirmation_date";
        }
        if (orderBy === t("menu.trip_list.file_name")) {
            tmp += "name";
        }
        if (orderBy === t("global.nights")) {
            tmp += "nb_nights";
        }
        if (orderBy === t("menu.edition_day")) {
            tmp += "current_version__modified_date";
        }
        if (orderBy === t("menu.trip_list.user_to")) {
            tmp += "user_to__username";
        }
        if (orderBy === t("menu.trip_list.user_ta")) {
            tmp += "user_ta__username";
        }
        if (orderBy === t("itinerary.departure_date")) {
            tmp += "current_version__start_date";
        }
        if (orderBy === t("itinerary.return_date")) {
            tmp += "current_version__end_date";
        }
        if (orderBy === t("menu.trip_list.completion_percentage")) {
            tmp += "current_version__completion_percentage";
        }
        if (orderBy === (!["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines'].includes(quotation_code) ? t("menu.trip_list.user_to") : t("menu.trip_list.user_bo"))) {
            tmp += "user_to__username";
        }
        if (orderBy === (!["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines'].includes(quotation_code) ? t("menu.trip_list.user_ta") : t("menu.trip_list.seller"))) {
            tmp += "user_ta__username";
        }
        dispatch({
            type: "MENU_SET_TRIP_LIST_ORDERING",
            payload: {
                trip_list_ordering: tmp,
                trip_list_ordering_prev: trip_list_ordering
            }
        });
    }, [order, orderBy]);
    const getColumn = () => {
        if (user !== null && user !== undefined && user.trip_profile.length > 0) {
            let trip_profile = [...user.trip_profile];
            let tmp_columns = [];
            trip_profile.map((tmp_column) => {
                let new_column = { ...tmp_column };
                new_column.field_name = column_type[tmp_column.field_name];
                tmp_columns.push(new_column);
            });
            setColumns(tmp_columns);
        } else {
            setColumns(init_columns);
        }
    };
    const getData = () => {
        GetTrip(active_page, trip_list_ordering, trip_list_search, trip_list_filter, dispatch, user.client, props.crmData?.user);
    };
    const getFilters = () => {
        GetCustomFilters(dispatch, user);
    };
    const handleOpenDrawer = () => {
        setOpenDrawer(!openDrawer);
    };
    const handleOpenColumns = () => {
        setOpenColumns(!openColumns);
    };
    let sort_column = columns.sort((a, b) => {
        return a.order - b.order;
    });
    const setActivePage = (elem, page) => {
        loadingRef.current.scrollIntoView({ block: 'center', block: "start", inline: "start" });
        dispatch({
            type: "MENU_CHANGE_ACTIVE_PAGE",
            payload: {
                active_page: page
            }
        });
    };
    const getAgencies = () => {
        if (pass_check) {
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${user.client}/children/?type=1,2&limit=10000&is_active=true`
            }).then(function (response) {
                let data = response.data.results.sort((a, b) => {
                    let fa = a.name.toLowerCase();
                    let fb = b.name.toLowerCase();
                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                });
                data.forEach(element => {
                    element.label = `${element.name}`;
                });
                dispatch({
                    type: "MENU_SET_AGENCIES",
                    payload: {
                        agencies: data
                    }
                });
            }).catch(function (error) {
                //TODO: show snackbar error
                console.log('error:', error);
            });
        }
    };
    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        console.log('sort_column:', sort_column);
        setColumns(sort_column);
        let request = {
            ordering: property,
            ordering_ascend: !isAsc
        };
        if (pass_check) {
            axios({
                method: "PATCH",
                headers: headers,
                url: `${API_HREF}client/${user.client}/user/${user.id}/`,
                data: JSON.stringify(request)
            }).then(function (response) {
                let data = Object.assign({}, response.data);
                dispatch({
                    type: "USER_SET_USER",
                    payload: {
                        user: data
                    }
                });
                enqueueSnackbar(t("notify.changed_success"), {
                    variant: "success"
                });
            }).catch(function (error) {
                //TODO: show snackbar error
                console.log('error:', error);
                enqueueSnackbar(t("notify.changed_failed"), {
                    variant: "error"
                });
            });
        }
    };
    const handleSearchInput = (e) => {
        setSearchInput(e.target.value);
    };
    const handleSearchValidate = () => {
        //let textInput = searchInput;
        if (searchInput === null || searchInput === "" || searchInput === " ") {
            dispatch({
                type: "MENU_SET_TRIP_LIST_SEARCH",
                payload: {
                    trip_list_search: ""
                }
            });
        } else {
            dispatch({
                type: "MENU_SET_TRIP_LIST_SEARCH",
                payload: {
                    trip_list_search: searchInput
                }
            });
        }
    };
    const reset = (e) => {
        //if (searchInput !== "") {
        setSearchInput("");
        dispatch({
            type: "MENU_SET_TRIP_LIST_SEARCH",
            payload: {
                trip_list_search: ""
            }
        });
        //} else {
        //setUpdate(true);
        //}
        handleResetFilter();
    };
    const handleResetFilter = () => {
        dispatch({
            type: !["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines'].includes(quotation_code) ? "MENU_RESET_TRIP_FILTER" : "MENU_RESET_TRIP_FILTER_CERCLE"
        });
        setUpdate(true);
    };
    const HandleMyFilesButton = () => {
        // if (['cercledesvoyages'].includes(quotation_code)) {
        tripFiltersRef.current.handleMyFiles();
        // }
    };
    return (
        <Container className={classes.container}>
            <Grid container spacing={ 2 }>
                <Grid item xs={12} className={ clsx({ [classes.marginBottom70]: downDesktop }) }>
                    <Toolbar disableGutters={true}>
                        <Grid className="trip-list-toolbar-container" container direction={"row"} justify={"space-between"} alignItems={"center"} spacing={downDesktop ? 2 : 0}>
                            <Grid item container sm={3} xs={12}>
                                {
                                    trip_list !== null && (
                                        <Grid container item direction={"row"} alignItems={"center"}>
                                            <Grid item>
                                                <span className={classes.fileTitle}>{trip_list_nb + " " + t("global.files")}</span>
                                            </Grid>
                                            <Grid item>
                                                <IconButton style={{ position: "relative" }} onClick={reset}>
                                                    <RefreshOutlinedIcon/>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </Grid>
                            {
                                props.crmData?.user &&
                                <Grid item xs={12} container>
                                    <Grid item xs={6}>
                                        <h6>{t('crm.total_ca')}</h6>
                                        {
                                            !isNaN(props.crmData?.total_ca) &&
                                            <p>{Intl.NumberFormat(language, { style: "currency", currency: props.crmData?.currency ? props.crmData?.currency : 'EUR' }).format(props.crmData?.total_ca)}</p>
                                        }
                                    </Grid>
                                    <Grid item xs={6}>
                                        <h6>{t('crm.total_margin')}</h6>
                                        {
                                            !isNaN(props.crmData?.total_margin) &&
                                            <p>{Intl.NumberFormat(language, { style: "currency", currency: props.crmData?.currency ? props.crmData?.currency : 'EUR' }).format(props.crmData?.total_margin)}</p>
                                        }
                                    </Grid>
                                    <Grid item xs={6}>
                                        <h6>{t('crm.total_paid')}</h6>
                                        {
                                            !isNaN(props.crmData?.total_paid) &&
                                            <p>{Intl.NumberFormat(language, { style: "currency", currency: props.crmData?.currency ? props.crmData?.currency : 'EUR' }).format(props.crmData?.total_paid)}</p>
                                        }
                                    </Grid>
                                    <Grid item xs={6}>
                                        <h6>{t('crm.total_rest')}</h6>
                                        {
                                            !isNaN(props.crmData?.total_rest) &&
                                            <p>{Intl.NumberFormat(language, { style: "currency", currency: props.crmData?.currency ? props.crmData?.currency : 'EUR' }).format(props.crmData?.total_rest)}</p>
                                        }
                                    </Grid>
                                </Grid>
                            }
                            <Grid item sm={6} xs={12}>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <Tooltip title={t("menu.trip_list.search_bar")}>
                                            <TextField
                                                onChange={handleSearchInput}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleSearchValidate();
                                                    }
                                                }}
                                                variant={"outlined"}
                                                value={searchInput}
                                                placeholder={t("menu.trip_list.search_bar")}
                                                fullWidth
                                                className={classes.textFieldBorder}
                                                InputProps={{
                                                    classes: { input: classes.searchBar },
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="default"
                                            className={classes.button}
                                            fullWidth={downDesktop}
                                            onClick={handleSearchValidate}
                                            disableElevation
                                            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeft: 0 }}
                                        >
                                            {t("global.search")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                            {
                                !props.crmData?.user &&
                                <Grid item sm={3} xs={12}>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        className={classes.button}
                                        fullWidth={downDesktop}
                                        startIcon={<AddCircleOutlinedIcon style={{color: "white"}}/>}
                                        onClick={() => {
                                            dispatch({type: "ITINERARY_TYPE_SET_ITINERARY_TYPE_DATA", payload: {itinerary_type_data: []}});
                                            dispatch({type: "HOME_TOGGLE_MODAL", payload: {modal: !home_modal, step: 1}});
                                            props.router.push(`/home/${window.url_name}`); }
                                        }
                                    >
                                        {t("menu.trip_list.new_file")}
                                    </Button>
                                </Grid>
                            }
                            <Grid item xs={12} style={{marginTop: 10}}>
                                <Tooltip title={t("menu.trip_list.columns_to_render")}>
                                    <IconButton className={ classes.blueColor } aria-label={t("menu.trip_list.columns_to_render")} style={{ position: "relative", marginLeft: -14 }} onClick={handleOpenColumns} size={"small"}>
                                        <ViewColumn/>
                                    </IconButton>
                                </Tooltip>
                                <span className={ classes.blueColor }>{t('shared.columns')}</span>
                                <Tooltip title={t("menu.trip_list.show_filters")}>
                                    <IconButton className={ classes.blueColor } aria-label={t("menu.trip_list.show_filters")} onClick={handleOpenDrawer} size={"small"}>
                                        <FilterListIcon/>
                                    </IconButton>
                                </Tooltip>
                                <span className={ classes.blueColor }>{t('shared.filters')}</span>
                                {
                                    (!["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines'].includes(quotation_code) || (userAgenceList.length !== 0 && userList.length !== 0 && (user.groups.length === 0 || user.groups.some(group => !group.is_admin)))) && (
                                        <Button
                                            className={ clsx(classes.blueColor, { [classes.actif]: myFiles }) }
                                            startIcon={<Folder />}
                                            style={{ marginLeft: 5 }}
                                            //variant={"contained"}
                                            onClick={HandleMyFilesButton}
                                        >
                                            {t("global.my_files")}
                                        </Button>
                                    )
                                }
                                {
                                    customFiltersSelected !== null && (
                                        <Button startIcon={<CloseIcon />} onClick={handleResetFilter} style={{ marginLeft: 10 }}>
                                            {customFiltersSelected.label}
                                        </Button>
                                    )
                                }
                                <DialogColumn handleOpenColumns={handleOpenColumns} openColumns={openColumns} setColumns={setColumns} columns={columns} column_type={column_type} order={order} orderBy={orderBy} user={user}/>
                            </Grid>
                        </Grid>
                    </Toolbar>
                    <TableContainer className={ classes.stickyOverflow } ref={loadingRef}>
                        <Table id={"trip-list"}>
                            <TableHead>
                                <TableRow className={classes.paddingCell}>
                                    { 
                                        props.crmData?.user &&
                                        <TableCell align={"left"}>{t("crm.bills")}</TableCell> 
                                    }
                                    <TableCell align={"right"}>
                                        {t("menu.trip_list.state")}
                                    </TableCell>
                                    {
                                        sort_column.map((column, column_index) => {
                                            if (column.active) {
                                                return (
                                                    <TableCell key={column_index} sortDirection={orderBy === column.field_name ? order : false} style={{whiteSpace: 'nowrap'}}
                                                        align={[t("menu.margin_title"), t("menu.trip_list.total_paid"), t("global.rest"), t("global.total_price")].includes(column.field_name) ? "right" : "left"}>
                                                        <TableSortLabel
                                                            active={orderBy === column.field_name}
                                                            direction={orderBy === column.field_name ? order : 'asc'}
                                                            onClick={createSortHandler(column.field_name)}
                                                        >
                                                            {column.field_name}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                );
                                            }
                                        })
                                    }
                                    { 
                                        !props.crmData?.user &&
                                        <TableCell/> 
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    trip_list_loading && (
                                        <TableRow style={{ height: 53 }}>
                                            <TableCell colSpan={ 20 }>
                                                <LinearProgress/>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                                {trip_list !== null && trip_list.map((row, row_index) => (
                                    <TripListRow key={row_index} row={row} row_index={row_index} to_show_version={to_show_version} columns={columns} status_choices={status_choices} user={user} setUpdate={setUpdate} setDuplicationOpen={setDuplicationOpen} duplicationOpen={duplicationOpen} crmData={props.crmData} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Drawer anchor={"right"} open={openDrawer} onClose={handleOpenDrawer} variant={ downDesktop ? "temporary" : "persistent" } className={downDesktop ? classes.mobileDrawer : classes.drawer}>
                        <TripListFilters ref={tripFiltersRef} handleOpenDrawer={handleOpenDrawer} trip_list_filter={trip_list_filter}/>
                    </Drawer>
                    {
                        trip_list !== null && (
                            <Grid item xs={ 12 } container justify={ "center" } style={{ marginTop: 10 }}>
                                <Pagination
                                    className={ classes.pagination }
                                    size={ downDesktop ? "small" : "medium"}
                                    page={active_page}
                                    count={ Math.ceil(trip_list_nb / 30) }
                                    variant={ "outlined" }
                                    hideNextButton={ (trip_list === null || trip_list.length === 0 || active_page === Math.ceil(trip_list_nb / 30)) }
                                    hidePrevButton={ active_page === 1 } disabled={ trip_list === null || trip_list.length === 0 }
                                    onChange={ setActivePage }
                                    renderItem={(item) => {
                                        return (
                                            <PaginationItem className={ `${item.selected ? classes.paginationItemActive : ""}` } {...item}/>
                                        );
                                    }}
                                />
                            </Grid>

                        )
                    }
                </Grid>
            </Grid>
            {/*<DuplicateModal is_from={"TripList"} /> */}
            <MaterialDuplicateDialog isFrom={"tripList"} duplicationOpen={duplicationOpen} setDuplicationOpen={setDuplicationOpen} router={props.router}/>
        </Container>
    );
};
export default React.memo(TripList);
