import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Chip,
    Divider,
    ListItem,
    ListItemText,
    Menu, MenuItem, MenuProps,
    Select,
    SelectChangeEvent,
    Slider,
    Typography
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import {
    flatten,
    flattenDeep,
    isArray,
    uniq
} from "lodash";
import { StepsDatesManager } from "./utils/stepsDatesManager";
import {
    setNightsCountFilter,
    setProductsFilter,
    toggleBlocksTypeFilter
} from "./redux/reducer";
import { BlockType } from "./objects/blockType";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    open: boolean,
} & Pick<MenuProps, 'anchorEl' | 'onClose'>

export function ItineraryBlocksFilters(props: Props): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const startDate = useSelector((state: AppState) => state.trip.start_date);
    const endDate = useSelector((state: AppState) => state.trip.end_date);
    const circuits = useSelector((state: AppState) => state.itinerarySlice.blocks.circuits);
    const typicalTrips = useSelector((state: AppState) => state.itinerarySlice.blocks.typicalTrips);
    const filters = useSelector((state: AppState) => state.itinerarySlice.blocks.filters);
    const tab = useSelector((state: AppState) => state.itinerarySlice.destinationsTab);
    const destinations = useSelector((state: AppState) => state.itinerarySlice.destinations);

    const datesManager = new StepsDatesManager(
        startDate ?? new Date().toISOString(),
        endDate ?? new Date().toISOString()
    );

    let counts: number[] = flattenDeep(
        flatten(Object.values(circuits.data)).map((item) => {
            return item.trip?.[0]?.data?.map((version) => {
                return datesManager.countTotalNights(version?.itineraries ?? []);
            }) ?? [];
        }).concat(
            flatten(Object.values(typicalTrips.data)).map((item) => {
                const steps = item.data[0]?.steps ?? [];
                return datesManager.countTotalNights(steps);
            })
        )
    );
    counts = uniq(counts).sort((a, b) => a - b);

    const onChangeNightsCountsFilter = (event: Event, value: number | number[]) => {
        if (isArray(value)) {
            dispatch(setNightsCountFilter(value as [number, number]));
        }
    };

    const onChangeProductsFilter = (event: SelectChangeEvent<"with" | "without" | "all">) => {
        dispatch(setProductsFilter(event.target.value as 'with' | 'without' | 'all'));
    };

    const onToggleTypeFilters = (type: BlockType) => {
        dispatch(toggleBlocksTypeFilter({ type }));
    };

    return (
        <Menu
            open={props.open}
            anchorEl={props.anchorEl}
            slotProps={{
                paper: {
                    sx: {
                        minWidth: 300
                    }
                }
            }}
            sx={{ maxWidth: 500 }}
            onClose={props.onClose}
        >
            <ListItem>
                <ListItemText
                    primary={
                        <>
                            <Typography
                                sx={{ fontWeight: 'bold', marginLeft: 2.5 }}
                                gutterBottom
                            >
                                {t('itinerary.duration')}
                            </Typography>
                            <Divider sx={{ marginBottom: 1.5 }} />
                        </>
                    }
                    secondary={
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 1.5,
                                paddingLeft: 2.5
                            }}
                        >
                            <Slider
                                marks={
                                    counts.map((item) => {
                                        return {
                                            value: item,
                                            label: item
                                        };
                                    })
                                }
                                min={Math.min(...counts)}
                                max={Math.max(...counts)}
                                value={filters.nightsCount ?? [Math.min(...counts), Math.max(...counts)]}
                                onChange={onChangeNightsCountsFilter}
                            />
                        </Box>
                    }
                />
            </ListItem>
            {
                tab === 1 &&
                <ListItem>
                    <ListItemText
                        primary={
                            <>
                                <Typography
                                    sx={{ fontWeight: 'bold', marginLeft: 2.5 }}
                                    gutterBottom
                                >
                                    {t('itinerary.type')}
                                </Typography>
                                <Divider sx={{ marginBottom: 1.5 }} />
                            </>
                        }
                        secondary={
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: 1.5,
                                    paddingLeft: 2.5
                                }}
                            >
                                <Chip
                                    size="small"
                                    icon={
                                        <CheckCircle
                                            color="inherit"
                                            sx={{
                                                color: filters.types.circuit ?
                                                    '#4BC84B' :
                                                    undefined
                                            }}
                                        />
                                    }
                                    label={t('itineraryType.circuits')}
                                    onClick={() => onToggleTypeFilters('circuit')}
                                />
                                <Chip
                                    size="small"
                                    icon={
                                        <CheckCircle
                                            color="inherit"
                                            sx={{
                                                color: filters.types.package ?
                                                    '#4BC84B' :
                                                    undefined
                                            }}
                                        />
                                    }
                                    label={t('itineraryType.packages')}
                                    onClick={() => onToggleTypeFilters('package')}
                                />
                                <Chip
                                    size="small"
                                    icon={
                                        <CheckCircle
                                            color="inherit"
                                            sx={{
                                                color: filters.types["typical-trip"] ?
                                                    '#4BC84B' :
                                                    undefined
                                            }}
                                        />
                                    }
                                    label={t('itineraryType.itinerary_type')}
                                    onClick={() => onToggleTypeFilters('typical-trip')}
                                />
                            </Box>
                        }
                    />
                </ListItem>
            }
            {
                tab === 1 &&
                <ListItem>
                    <ListItemText
                        primary={
                            <>
                                <Typography
                                    sx={{ fontWeight: 'bold', marginLeft: 2.5 }}
                                    gutterBottom
                                >
                                    {t('itinerary.products')}
                                </Typography>
                                <Divider sx={{ marginBottom: 1.5 }} />
                            </>
                        }
                        secondary={
                            <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
                                <Select
                                    value={filters.products}
                                    onChange={onChangeProductsFilter}
                                    fullWidth
                                >
                                    <MenuItem value="all">
                                        {t('itinerary.all-blocks')}
                                    </MenuItem>
                                    <MenuItem value="with">
                                        {t('itinerary.blocks-with-products')}
                                    </MenuItem>
                                    <MenuItem value="without">
                                        {t('itinerary.blocks-without-products')}
                                    </MenuItem>
                                </Select>
                            </Box>
                        }
                    />
                </ListItem>
            }
        </Menu>
    );
}
