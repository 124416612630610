import React, { Fragment, useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

import { Typography } from "@mui/material";
import { DateRangeDelimiter, DateRangePicker, LocalizationProvider } from "@material-ui/pickers";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import ListIcon from '@material-ui/icons/List';
import green from "@material-ui/core/colors/green";

import PoiCardGroups from "./PoiCardGroups";
import CustomTimePicker from "./CustomTimePicker";
import ConnectedPoiAvailability from "./ConnectedPoiAvailability";
import CustomPoiAvailability from "./CustomPoiAvailability";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { SetCustomTimeError } from "../../../Actions/Poi";
import { useShowError } from "../../Utils/showError";
import GetCookie from "../../Common/Functions/GetCookie";
import GetIsoCode from "../Functions/GetIsoCode";
import { CartConstructionReplaceProductContext } from "../../CartMaterial/utils/cartConstructionReplaceProductContext";

import axios from "axios";
import moment from "moment";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import localeText from "../../Functions/localeText";
import i18n from "../../../i18n";
import GetPictureBySize from "../Functions/GetPictureBySize";
import Info from '@material-ui/icons/Info';

const useStyles = makeStyles(theme => ({
    bold: {
        fontWeight: "bold"
    },
    inline: {
        display: "inline-block"
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    invertedOrangeButton: {
        backgroundColor: 'white',
        border: '1px solid #E6592F',
        color: '#E6592F'
    },
    orangeBorderButton: {
        color: "#E6592F",
        borderColor: "#E6592F",
        backgroundColor: "#FFFFFF"
    },
    paddingBottom: {
        paddingBottom: theme.spacing(2)
    },
    marginRight: {
        marginRight: theme.spacing(1)
    },
    spacer: {
        padding: "8px 0px"
    },
    bigSpacer: {
        padding: "16px 0px"
    },
    timeBorder: {
        border: "0.5px solid rgba(0, 0, 0, 0.25)",
        borderRadius: 8,
        boxSizing: "border-box",
        padding: 8
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    alignCenter: {
        textAlign: "center"
    },
    paddingBottom: {
        paddingBottom: theme.spacing(2)
    },
    providerWrapper: {
        position: "relative"
    },
    floatRight: {
        float: "right"
    },
    marginRight: {
        marginRight: theme.spacing(1)
    },
    negMarginTop: {
        marginTop: -theme.spacing(1)
    },
    lineBreak: {
        whiteSpace: 'pre'
    }
}));

const PoiCardPrices = ({ start_date, end_date, activity, current_travelers, productSelected, setProductSelected, assignedGroups, setAssignedGroups, addToCart, addingToCart, openStandalone, current_groups, setCurrentGroups, manualVariant, setManualVariant }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const language = useSelector(state => state.header.tmp_language);
    const currency = useSelector(state => state.trip.currency);
    const currency_list = useSelector(state => state.base.currency_list);
    //const current_groups = useSelector(store => store.poi.current_groups);
    const destination = useSelector(state => state.poi.destination);
    const locales = useSelector(state => state.user.locales);
    const manual_providers = useSelector(state => state.trip.manual_providers);
    const itinerary_list = useSelector(state => state.itinerary.itinerary_list);
    const user = useSelector(state => state.user.user);
    const replaceProductContext = useContext(CartConstructionReplaceProductContext);
    //const quotation_code = 'visiteurs';
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const current_locale = locales.find((el) => {
        return el.language_code === i18n.language;
    });
    const [arrivalDate, setArrivalDate] = useState(start_date);
    const [departureDate, setDepartureDate] = useState(end_date);
    const [availabilities, setAvailabilities] = useState([]);
    const [availabilitiesSearching, setAvailabilitiesSearching] = useState(false);
    const [seeAllAvailabilities, setSeeAllAvailabilities] = useState(false);
    const [displayCustomTime, setDisplayCustomTime] = useState(false);
    const [customTime, setCustomTime] = useState("");
    const [minCustomTime, setMinCustomTime] = useState(null);
    const [maxCustomTime, setMaxCustomTime] = useState(null);
    const [variantChosen, setVariantChosen] = useState("");
    const [view, setView] = useState("card");
    const [displayCalendarDate, setDisplayCalendarDate] = useState(false);
    const [totalPrice, setTotalPrice] = useState(null);
    const [selectProvider, setSelectProvider] = useState(null);
    const [providerAvailable, setProviderAvailable] = useState([]);
    const showError = useShowError();

    if (replaceProductContext.enableReplace) {
        replaceProductContext.onRegisterNextEvent(addToCart);
    }

    moment.locale(language);
    useEffect(() => {
        if (manualVariant?.multiple_days) {
            setView("calendar");
        }
    }, []);
    useEffect(() => {
        setAvailabilities([]);
        setProductSelected([]);
        setAssignedGroups([]);
        setDisplayCustomTime(false);
        setDisplayCalendarDate(false);
    }, [current_groups]);

    useEffect(() => {
        if (destination !== null && !replaceProductContext.enableReplace) {
            setArrivalDate(destination.start_date.split('T')[0]);
            setDepartureDate(destination.end_date.split('T')[0]);
        }
    }, [destination]);

    useEffect(() => {
        if (itinerary_list.length !== 0 && destination === null) {
            let current_itinerary = itinerary_list.find((el) => {
                return el.id === destination.id;
            });
            console.log('current_itinerary:', current_itinerary);
            const bounds = JSON.parse(current_itinerary.destination.data.bounds.replace(/'/g, '"'));
            dispatch({
                type: "MATERIAL_SET_ACTIVE_DESTINATION",
                payload: {
                    active: current_itinerary.id,
                    bounds: {
                        north: bounds.north,
                        east: bounds.east,
                        south: bounds.south,
                        west: bounds.west
                    },
                    zoom: current_itinerary.destination.data.zoom_level,
                    start_date: current_itinerary.start_date.split('T')[0],
                    end_date: current_itinerary.end_date.split('T')[0],
                    destination: current_itinerary,
                    reset: true
                }
            });
        }
    }, [itinerary_list]);
    useEffect(() => {
        if (availabilities.length !== 0 && assignedGroups.length !== current_groups.length && availabilities[assignedGroups.length].schedules !== undefined && availabilities[assignedGroups.length].schedules.leength !== 0) {
            //calculate min and max time
            let min_time = null;
            let max_time = null;
            availabilities[assignedGroups.length].schedules.forEach((schedule) => {
                if (schedule.hours) {
                    schedule.hours.forEach((hours) => {
                        hours.map((hour) => {
                            if (min_time === null || hour < min_time) {
                                min_time = hour;
                            }
                            if (max_time === null || hour > max_time) {
                                max_time = hour;
                            }
                        });
                    });
                }
            });
            setMaxCustomTime(max_time);
            setMinCustomTime(min_time);
        }
    }, [availabilities]);
    useEffect(() => {
        if (activity !== undefined && activity !== null && activity.is_custom) {
            let providers_available = [];
            if (activity.provider !== null) {
                providers_available.push(activity.provider);
            }
            if (activity.providers.length > 0) {
                providers_available = [...providers_available, ...activity.providers];
            }
            providers_available = providers_available.filter((value, index, self) =>
                value.provider.is_activated && index === self.findIndex((t) => (
                    t.id === value.id
                ))
            );
            providers_available = providers_available.filter((provider, index) => {
                let has_activated_contract = false;
                activity.contracts.map((contract) => {
                    if (contract.provider.id === provider.id && contract.activated) {
                        has_activated_contract = true;
                    }
                });
                return has_activated_contract;
                //let contract = activity.contracts.find((contract) => {
                //return contract.provider.id === provider.id;
                //});
                //return contract !== undefined && contract.activated;
            });
            //.sort((a, b) => {
            //if (a.price !== undefined && b.price !== undefined) {
            //return parseFloat(a.price.selling_price) - parseFloat(b.price.selling_price);
            //} else if (a.price === undefined) {
            //return b;
            //} else if (b.price === undefined) {
            //return a;
            //}
            //});
            setProviderAvailable(providers_available);
        }
    }, [activity]);
    //useEffect(() => {
    //const totalPrice = productSelected.reduce((acc, product) => {
    //const sellingPrice = parseFloat(product.total_price[0].selling_price);
    //const sellingCurrency = product.total_price[0].selling_currency;
    //return {
    //selling_price: acc.selling_price + sellingPrice,
    //selling_currency: sellingCurrency
    //};
    //}, { selling_price: 0, selling_currency: 0 });
    //setTotalPrice(totalPrice);
    //}, [productSelected]);

    const changeDates = (dates) => {
        setArrivalDate(dates[0].format("YYYY-MM-DD"));
        setDepartureDate(dates[1].format("YYYY-MM-DD"));
    };

    const getAvailabilities = (provider_id) => {
        setAvailabilities([]);
        if (provider_id !== undefined && provider_id !== null) {
            setSelectProvider(provider_id);
            setAvailabilitiesSearching(provider_id);
        } else {
            setAvailabilitiesSearching(true);
        }
        let { pass_check, headers } = CheckBeforeRequest();
        //reset chosen variant
        setVariantChosen("");
        if (pass_check) {
            let temp_availabilities = [];
            current_groups.map((group) => {
                axios.get(`${API_HREF}client/${window.id_owner}/custom-products/product_prices/`, {
                    params: {
                        start_destination: destination.destination.id,
                        custom_provider: activity.is_custom ? "true" : "false",
                        product_identifiers: activity.is_custom ? encodeURIComponent(activity.id) : encodeURIComponent(activity.product_code),
                        arrival_date: arrivalDate,
                        departure_date: departureDate,
                        comps: group.id,
                        trip: GetCookie("trip_id"),
                        product_quantity: 1,
                        token: GetCookie("trip_token"),
                        language: language,

                        //provider_id: activity.is_custom ? (activity.provider === null ? (provider !== null && provider.provider !== undefined && provider.provider !== null ? provider.provider.id : undefined) : activity.provider.provider.id) : (activity.provider !== undefined && activity.provider !== null ? (activity.provider.id !== undefined && activity.provider.id !== null ? activity.provider.id : undefined) : undefined),
                        provider_id: activity.is_custom ? (provider_id !== undefined && provider_id !== null ? provider_id : undefined) : (activity.provider !== undefined && activity.provider !== null ? (activity.provider.id !== undefined && activity.provider.id !== null ? activity.provider.id : undefined) : undefined)
                    },
                    headers: headers
                }).then(response => {
                    if (response.data.scheduled_prices.length === 0) {
                        enqueueSnackbar(t("poi.no_price"), { variant: "warning", autoHideDuration: 4000, disableWindowBlurListener: true });
                    } else {
                        const temp_response = { ...response.data };
                        if (temp_response.choices !== undefined) {
                            temp_response.choices.sort((a, b) => a.name.localeCompare(b.name));
                            temp_response.choices.forEach((variant, variantIndex) => {
                                const new_variant_prices = regroupPricesbyDate(variant.scheduled_prices, setDisplayCustomTime, true);
                                temp_response.choices[variantIndex].scheduled_prices = new_variant_prices;
                            });
                        }
                        const new_schedules_prices = regroupPricesbyDate(temp_response.scheduled_prices, setDisplayCustomTime, false);
                        temp_response.scheduled_prices = new_schedules_prices;
                        temp_availabilities.push(temp_response);
                        console.log('temp_availabilities:', temp_availabilities);
                        if (temp_availabilities.length === current_groups.length) {
                            setAvailabilities(temp_availabilities);
                        }
                    }
                }).catch((error) => {
                    showError(error);
                }).finally(() => {
                    setAvailabilitiesSearching(false);
                });
            });
        }
    };

    const regroupPricesbyDate = (oldPrices, setDisplayCustomTime, isVariant) => {
        const newSchedulesPrices = [];
        oldPrices.forEach((onePrice) => {
            const copyPrice = { ...onePrice };
            copyPrice.areBounds = false;
            if (copyPrice.hour.length === 2 && copyPrice.hour[0] === copyPrice.hour[1]) {
                copyPrice.areBounds = true;
                setDisplayCustomTime(true);
            }
            if (copyPrice.hour.length === 0 || (copyPrice.hour.length === 1 && copyPrice.hour[0] === "") || copyPrice.hour.length === 2 && copyPrice.hour[0] === copyPrice.hour[1]) {
                copyPrice.areBounds = true;
                setDisplayCustomTime(true);
            }
            // if (copyPrice.hour.length === 2 && copyPrice.hour[0] !== copyPrice.hour[1]) {
            //     copyPrice.areBounds = true;
            //     setDisplayCustomTime(true);
            // } else {
            //     if (copyPrice.hour.length === 0 || (copyPrice.hour.length === 1 && copyPrice.hour[0] === "") || copyPrice.hour.length === 2 && copyPrice.hour[0] === copyPrice.hour[1]) {
            //         setDisplayCustomTime(true);
            //     }
            //     copyPrice.areBounds = false;
            // }
            let dateToBeAdded = true;
            newSchedulesPrices.forEach((newPrice) => {
                if (!isVariant && copyPrice.date === newPrice.date) {
                    dateToBeAdded = false;
                    newPrice.hour.push(copyPrice.hour[0]);
                    newPrice.identifier.push(copyPrice.identifier);
                }
            });

            if (dateToBeAdded) {
                copyPrice.identifier = [copyPrice.identifier];
                newSchedulesPrices.push(copyPrice);
            }
        });
        newSchedulesPrices.forEach((newPrice) => {
            newPrice.hourUnordered = newPrice.hour.slice();
            if (newPrice.areBounds === false) {
                newPrice.hour.sort((a, b) => a.localeCompare(b));
            }
        });
        newSchedulesPrices.sort((a, b) => new Date(a.date) - new Date(b.date));
        return newSchedulesPrices;
    };

    const handleView = () => {
        let temp_view = "";
        switch (view) {
            case "card":
                temp_view = "calendar";
                break;
            case "calendar":
                temp_view = "card";
                break;
        }
        setView(temp_view);
    };

    const selectActivity = (chosen_date_prices, time_variable) => {
        let group_not_assigned = [];
        let not_assigned_index = [];
        let chosen_time = time_variable !== null ? time_variable : customTime;
        if ((chosen_date_prices.hour.length !== 1 || chosen_date_prices.hour[0] === "") && chosen_time === "") {
            enqueueSnackbar(t("poi.error_choose_time"), { variant: "error" });
            dispatch(SetCustomTimeError(true));
        } else {
            dispatch(SetCustomTimeError(false));
            current_groups.map((group, group_index) => {
                if (!assignedGroups.includes(group.id)) {
                    group_not_assigned.push(group.id);
                    not_assigned_index.push(group_index);
                }
            });
            let temp_products = productSelected.slice();
            let temp_assigned_groups = assignedGroups.slice();

            //display custom time if day_prices is empty or have two hours (bounds)
            let add_product_request = {
                comps: [group_not_assigned[0]],
                custom_provider: activity.is_custom,
                product_identifiers: activity.is_custom ? [activity.id] : [activity.product_code],
                start_destination: destination.destination.id,
                group_index: not_assigned_index[0],
                detail_price: chosen_date_prices.details,
                total_price: chosen_date_prices.total_price
            };
            let chosen_date = chosen_date_prices.date.length === 10 ? chosen_date_prices.date : chosen_date_prices.date.split(' - ')[0];
            add_product_request.arrival_date = (chosen_date_prices.hour.length === 1 && chosen_date_prices.hour[0] !== "") ? (chosen_date + " " + chosen_date_prices.hour[0].replace("h", ":")) : (chosen_time !== undefined && chosen_time !== null ? chosen_date + " " + chosen_time.replace("h", ":") : chosen_date);
            if (chosen_date_prices.date.length > 10) {
                add_product_request.long_date = chosen_date_prices.date;
            }
            if (typeof chosen_date_prices.identifier !== 'string') {
                add_product_request.identifier = chosen_date_prices.identifier.length === 1 ? chosen_date_prices.identifier[0] : chosen_date_prices.identifier[chosen_date_prices.hourUnordered.findIndex(element => element === chosen_time)];
            } else {
                add_product_request.identifier = chosen_date_prices.identifier;
            }
            if (!activity.poi_type) {
                if (activity.is_custom) {
                    if (selectProvider !== null) {
                        add_product_request.provider_id = selectProvider;
                    }
                } else {
                    add_product_request.provider_id = activity.provider.id;
                }
            }
            //only for custom products
            if (chosen_date_prices.schedule_info !== undefined && chosen_date_prices.schedule_info !== null && chosen_date_prices.schedule_info !== "") {
                add_product_request.custom_information = chosen_date_prices.schedule_info;
            }

            //only for activities with variants
            if (variantChosen !== "") {
                add_product_request.variant_name = variantChosen.name;
            }

            temp_products.push(add_product_request);
            temp_assigned_groups.push(group_not_assigned[0]);
            setProductSelected(temp_products);
            setAssignedGroups(temp_assigned_groups);
            setSeeAllAvailabilities(false);
        }
    };
    const handleVariantChange = (event) => {
        setManualVariant(event.target.value);
    };
    return (
        <CardContent>
            {
                productSelected.length !== 0 && totalPrice !== null && (
                    <Typography className={ `${classes.bold} ${classes.paddingBottom}` }>
                        { t("poi.total") + " : "}
                        { new Intl.NumberFormat(language, { style: 'currency', currency: GetIsoCode(currency_list, currency, totalPrice.selling_currency) }).format(parseFloat(totalPrice.selling_price)) }
                    </Typography>
                )
            }
            {
                current_groups !== null && current_groups.length !== 0 && (
                    <PoiCardGroups current_travelers={ current_travelers } current_groups={current_groups} setCurrentGroups={setCurrentGroups}/>
                )
            }
            {
                !replaceProductContext.enableReplace &&
                <>
                    <br />
                    <LocalizationProvider dateLibInstance={ moment } dateAdapter={ MomentAdapter } locale={ language }>
                        <DateRangePicker
                            startText={ t("poi.arrival") }
                            endText={ t("poi.departure") }
                            value={[arrivalDate, departureDate]}
                            onChange={dates => changeDates(dates)}
                            minDate={start_date}
                            maxDate={end_date}
                            renderInput={(startProps, endProps) => {
                                startProps.helperText = undefined;
                                startProps.fullWidth = true;
                                endProps.helperText = undefined;
                                endProps.fullWidth = true;
                                return (
                                    <Fragment>
                                        <TextField {...startProps} />
                                        <DateRangeDelimiter>{ t("accommodation.to") }</DateRangeDelimiter>
                                        <TextField {...endProps} />
                                    </Fragment>
                                );
                            }}
                        />
                    </LocalizationProvider>
                </>
            }
            {
                replaceProductContext.enableReplace &&
                <Typography
                    fontWeight={100}
                    fontSize="1rem"
                    sx={{
                        marginTop: 1.5,
                        textAlign: 'center',
                        whiteSpace: 'nowrap'
                    }}
                >
                    {
                        t(
                            'cart-material.cart-construction-products-table-date',
                            {
                                from: window.moment.utc(arrivalDate).format('L'),
                                to: window.moment.utc(departureDate).format('L')
                            }
                        )
                    }
                </Typography>
            }
            {
                (!activity.is_custom || providerAvailable.length <= 1) && assignedGroups.length !== current_groups.length && (
                    <Grid>
                        <br />
                        <Button variant={ "contained" } className={ classes.orangeButton } fullWidth={ true } onClick={ () => getAvailabilities(providerAvailable.length === 1 ? providerAvailable[0].provider.id : null) } disabled={availabilitiesSearching !== false}>
                            { t("poi.check_availability") }
                            { availabilitiesSearching && <CircularProgress size={ 24 } className={ classes.buttonProgress }/> }
                        </Button>
                        <br />
                    </Grid>
                )
            }
            {
                user.client_full.type === 2 && quotation_code === 'visiteurs' && activity.is_custom && assignedGroups.length !== current_groups.length && providerAvailable.length > 1 &&
                <Grid>
                    {
                        providerAvailable.map((provider, index) => {
                            return (
                                <div key={ index } className={classes.paddingBottom}>
                                    <br/>
                                    <Typography className={ `${classes.inline} ${classes.marginRight}` }>{ t('accommodation.visiteurs_partners') }</Typography>
                                    <div className={ `${classes.providerWrapper} ${classes.inline} ${classes.floatRight} ${classes.negMarginTop}` }>
                                        <Button variant={ "contained" } size={ "small" } className={ classes.orangeButton } disabled={ availabilitiesSearching !== false } onClick={() => {
                                            getAvailabilities(provider.provider.id); 
                                        } }>{ t("accommodation.choose") }</Button>
                                        { availabilitiesSearching === provider.provider.id && <CircularProgress size={24} className={ classes.buttonProgress } /> }
                                    </div>
                                </div>
                            );
                        })
                    }
                </Grid>
            }
            {
                (user.client_full.type !== 2 || quotation_code !== "visiteurs") && activity.is_custom && assignedGroups.length !== current_groups.length && providerAvailable.length > 1 &&
                <Grid>
                    {
                        providerAvailable.map((provider, index) => {
                            if (index === 0) {
                                return (
                                    <div key={ index } className={ classes.alignCenter }>
                                        <img src={ GetPictureBySize(provider.provider.logo, "MEDIUM") } width={ "50%" } alt={ "provider logo" }/>
                                        <Typography variant={ "h6" } className={ `${provider.price !== undefined ? "" : classes.paddingBottom}` }>{ provider.provider.name !== null && provider.provider.name}</Typography>
                                        {
                                            provider.price !== undefined &&
                                        <Typography variant={ "h6" } className={ `${classes.paddingBottom}` }>{provider.catalog_price ? t('poi.price_on_demand') : new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(provider.price.selling_price)}</Typography>
                                        }
                                        <div className={ classes.providerWrapper }>
                                            <Button variant={ "contained" } className={ classes.orangeButton } fullWidth={ true } disabled={ availabilitiesSearching !== false } onClick={() => {
                                                getAvailabilities(provider.provider.id); 
                                            } }>{ t("accommodation.choose") }</Button>
                                            { availabilitiesSearching === provider.provider.id && <CircularProgress size={24} className={ classes.buttonProgress } /> }
                                        </div>
                                    </div>
                                );
                            }
                            return (
                                <div key={ index }>
                                    <br/>
                                    <Grid container direction={"row"} alignItems={"center"} justify={"space-between"}>
                                        <Grid item>
                                            <img className={ classes.inline } src={ GetPictureBySize(provider.provider.logo, "MEDIUM") } width={ 75 } alt={ "provider logo" }/>
                                        </Grid>
                                        <Grid item>
                                            <div className={ `${classes.providerWrapper} ${classes.inline} ${classes.floatRight}` }>
                                                <Button variant={ "contained" } size={ "small" } className={ classes.orangeButton } disabled={ availabilitiesSearching !== false } onClick={() => {
                                                    getAvailabilities(provider.provider.id); 
                                                } }>{ t("accommodation.choose") }</Button>
                                                { availabilitiesSearching === provider.provider.id && <CircularProgress size={24} className={ classes.buttonProgress } /> }
                                            </div>
                                            <Typography className={ `${classes.inline} ${classes.marginRight} ${classes.floatRight}` }>{ provider.provider.name !== null && provider.provider.name }</Typography>
                                        </Grid>
                                        {
                                            provider.price !== undefined &&
                                        <Grid item xs={12}>
                                            <Typography className={ `${classes.floatRight}` } >{provider.catalog_price ? t('poi.price_on_demand') : new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(provider.price.selling_price)}</Typography>
                                        </Grid>
                                        }
                                    </Grid>
                                </div>
                            );
                        })
                    }
                </Grid>
            }
            {
                assignedGroups.length !== current_groups.length &&
                <Grid>
                    <br />
                    <Button variant={ "contained" } className={ classes.invertedOrangeButton } fullWidth={ true } onClick={() => openStandalone()} >
                        { t("poi.add_standalone_activity") }
                    </Button>
                    <br />
                </Grid>
            }
            {
                activity.is_custom && activity.variants !== undefined && activity.variants.length > 1 && activity.variants.find((variant) => variant.is_active === undefined || variant.is_active) !== undefined && assignedGroups.length !== current_groups.length &&
                //activity.is_custom && activity.variants !== undefined && activity.variants.length !== 0 && activity.variants.find((variant) => variant.is_active !== undefined && variant.is_active) !== undefined && assignedGroups.length !== current_groups.length &&
                <Grid>
                    <br />
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="variants-outlined-label">Variantes</InputLabel>
                        <Select
                            labelId="variants-outlined-label-outlined-label"
                            id="variants-outlined-label-outlined"
                            value={manualVariant}
                            onChange={handleVariantChange}
                            label="Variantes"
                        >
                            {
                                activity.variants.map((variant, index) => {
                                //if (variant.is_active !== undefined && variant.is_active) {
                                    if (variant.is_active === undefined || variant.is_active) {
                                        return (
                                            <MenuItem key={index} value={variant}>{localeText(current_locale.id, variant.localization, variant.name, 'title')}</MenuItem>
                                        );
                                    }
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
            }
            {
                //change view
                availabilities.length !== 0 && assignedGroups.length !== current_groups.length && (
                    <Grid container alignItems="center" justify="flex-end" className={classes.bigSpacer}>
                        <Grid item>
                            <Typography variant="body2" className={classes.bold}>
                                { t("poi.pick_time") + " " + t("poi.for_group") + " " + (assignedGroups.length + 1) }
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip title={view === "card" ? t("poi.calendar_view") : t("poi.card_view")}>
                                <IconButton size="small" onClick={() => handleView()}>
                                    {
                                        view === "card" && (<CalendarTodayOutlinedIcon />)
                                    }
                                    {
                                        view === "calendar" && (<ListIcon />)
                                    }
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                )
            }
            {
                //let the user choose a time for activity
                displayCustomTime && availabilities.length !== 0 && assignedGroups.length !== current_groups.length && (
                    <Grid className={classes.timeBorder}>
                        <Typography className={`${classes.bold} ${classes.spacer}`} variant="body2"> { t("poi.select_hour") + " :" } </Typography>
                        {
                            minCustomTime !== null && maxCustomTime !== null && minCustomTime !== maxCustomTime && (
                                <Typography className={`${classes.spacer}`} variant="body2"> { t("poi.open_hours") + " : " + minCustomTime + "-" + maxCustomTime } </Typography>
                            )
                        }
                        <CustomTimePicker value={customTime} setValue={setCustomTime} valueChoices={null} step={15} minValue={minCustomTime} maxValue={maxCustomTime}/>
                        <Typography className={classes.spacer} style={{ fontSize: 10 }}> { t("poi.to_set_info") } </Typography>
                    </Grid>
                )
            }
            {
                !activity.is_custom && (
                    <ConnectedPoiAvailability
                        arrivalDate={arrivalDate}
                        departureDate={departureDate}
                        availabilities={availabilities}
                        assignedGroups={assignedGroups}
                        view={view}
                        displayCalendarDate = {displayCalendarDate}
                        setDisplayCalendarDate={setDisplayCalendarDate}
                        seeAllAvailabilities={seeAllAvailabilities}
                        setSeeAllAvailabilities={setSeeAllAvailabilities}
                        variantChosen={variantChosen}
                        setVariantChosen={setVariantChosen}
                        current_groups={current_groups}
                        selectActivity={selectActivity}
                        poi_type={activity.poi_type}
                    />
                )
            }
            {
                activity.is_custom && (
                    <CustomPoiAvailability
                        arrivalDate={arrivalDate}
                        departureDate={departureDate}
                        availabilities={availabilities}
                        assignedGroups={assignedGroups}
                        view={view}
                        displayCalendarDate = {displayCalendarDate}
                        setDisplayCalendarDate={setDisplayCalendarDate}
                        seeAllAvailabilities={seeAllAvailabilities}
                        setSeeAllAvailabilities={setSeeAllAvailabilities}
                        setMinCustomTime={setMinCustomTime}
                        setMaxCustomTime={setMaxCustomTime}
                        current_groups={current_groups}
                        selectActivity={selectActivity}
                        manualVariant={manualVariant}
                        poi_type={activity.poi_type}
                    />
                )
            }
            {
                //add to cart button
                availabilities.length !== 0 &&
                assignedGroups.length !== 0 &&
                !replaceProductContext.enableReplace && (
                    <Grid>
                        <br />
                        <Button variant="contained" className={ classes.orangeButton } fullWidth={ true } onClick={() => addToCart()} disabled={addingToCart}>
                            { t("poi.validate_to_cart") }
                            { addingToCart && <CircularProgress size={ 24 } className={ classes.buttonProgress }/> }
                        </Button>
                    </Grid>
                )
            }
        </CardContent>
    );
};

export default React.memo(PoiCardPrices);
