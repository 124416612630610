import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    List,
    Stack,
    styled,
    Tab,
    Tabs,
    Tooltip,
    Typography
} from "@mui/material";
import { SortOutlined } from "@mui/icons-material";
import PlaceIcon from '@mui/icons-material/Place';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { ItineraryDestinationItem } from "./itineraryDestinationItem";
import { ItineraryDestinationSearch } from "./itineraryDestinationSearch";
import { ItineraryBlocksTreeView } from "./itineraryBlocksTreeView";
import { ItineraryBlocksFilters } from "./itineraryBlocksFilters";
import { ItineraryBlocksSorts } from "./itineraryBlocksSorts";
import { filterDestinationsBasedOnZoom } from "./utils/filterDestinationsBasedOnZoom";
import { getLightDestinationName } from "./utils/getLightDestinationName";
import { getDestination } from "./utils/getDestination";
import { findDestinationChildren } from "./utils/findDestinationChildren";
import { useItineraryStepAdd } from "./network/itineraryStepAdd";
import {
    setDestinations,
    setDestinationsTab,
    setParentDestination,
    setParentDestinationTree
} from "./redux/reducer";
import { LightDestination } from "./objects/lightDestination";
import { AppState } from "../../Reducers/Reducers";

export function ItineraryDestinationsList(): JSX.Element {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const map = useSelector((state: AppState) => state.itinerarySlice.map);
    const isUserTO = useSelector((state: AppState) => state.user.user?.client_full?.type !== 2);
    const parentDestination = useSelector((state: AppState) => state.itinerarySlice.parentDestination);
    const tab = useSelector((state: AppState) => state.itinerarySlice.destinationsTab);
    const [openFilters, setOpenFilters] = useState(false);
    const [openSorts, setOpenSorts] = useState(false);
    const [goingBackOnParent, setGoingBackOnParent] = useState(false);
    const filtersButtonRef = useRef(null);
    const sortsButtonRef = useRef(null);
    const addStep = useItineraryStepAdd({});
    const parentName = parentDestination?.data?.localization?.find((item) => {
        return item.locale === locale;
    })?.name ?? parentDestination?.data?.international_name ?? '';

    const onAddStep = (destination: LightDestination) => {
        addStep(destination);
    };

    const onGoBackOnParent = async () => {
        if (parentDestination?.parent?.id && map) {
            setGoingBackOnParent(true);
            const grandParent = await getDestination(parentDestination.parent.id);
            if (grandParent?.data) {
                const children = await findDestinationChildren(grandParent.id, isUserTO);
                map.set('noNotify', true);
                map.panTo({
                    lat: parseFloat(grandParent.data.latitude),
                    lng: parseFloat(grandParent.data.longitude)
                });
                if (grandParent.data.zoom_level) {
                    map.setZoom(grandParent.data.zoom_level);
                }
                dispatch(
                    setDestinations({
                        state: 'success',
                        data: filterDestinationsBasedOnZoom(children, map.getZoom() ?? 8)
                    })
                );
            }
            setGoingBackOnParent(false);
        }
    };

    useEffect(() => {
        setOpenFilters(false);
        setOpenSorts(false);
    }, [tab]);

    return (
        <>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ padding: 2, paddingBottom: 0 }}>
                    <Grid container>
                        <Grid item flex={1}>
                            <GoBackButton
                                onClick={onGoBackOnParent}
                                disabled={goingBackOnParent}
                            >
                                {
                                    goingBackOnParent &&
                                    <CircularProgress size="12px" />
                                }
                                {
                                    !goingBackOnParent &&
                                    <ArrowBackIosNewOutlinedIcon fontSize="inherit" />
                                }
                            </GoBackButton>
                        </Grid>
                        <Grid item sx={{ width: '50%' }} flex={1}>
                            <Tooltip title={parentName}>
                                <Typography
                                    variant="subtitle2"
                                    align="center"
                                    sx={{
                                        opacity: 0.54,
                                        whiteSpace: 'nowrap',
                                        width: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}
                                >
                                    {parentName}
                                </Typography>
                            </Tooltip>
                        </Grid>
                        <Grid item flex={1} />
                    </Grid>
                    <Box sx={{ marginTop: 1.5 }}>
                        <ItineraryDestinationSearch
                            tab={tab === 0 ? 'destinations' : 'blocks'}
                            onChoose={onAddStep}
                        />
                    </Box>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1.5}
                        sx={{ marginTop: 0.5 }}
                    >
                        <SortButton
                            ref={filtersButtonRef}
                            variant="outlined"
                            size="small"
                            onClick={() => setOpenFilters(true)}
                        >
                            {t('list.filters')}
                        </SortButton>
                        <SortIconButton
                            ref={sortsButtonRef}
                            onClick={() => setOpenSorts(true)}
                        >
                            <SortOutlined fontSize="inherit" />
                        </SortIconButton>
                    </Stack>
                </Box>
                <ModeTabs
                    variant="fullWidth"
                    value={tab}
                    onChange={(_, value) => dispatch(setDestinationsTab(value))}
                >
                    <ModeTab
                        icon={<PlaceIcon />}
                        label={t('itinerary.destinations')}
                    />
                    <ModeTab
                        icon={
                            <AccountTreeIcon />
                        }
                        label={t('itinerary.blocs')}
                    />
                </ModeTabs>
                <Box sx={{ flex: 1, backgroundColor: '#fff', zIndex: 2 }}>
                    <List
                        sx={{
                            padding: 0,
                            paddingLeft: 1,
                            display: tab !== 0 ? 'none' : undefined
                        }}
                    >
                        <DestinationsListWrapper />
                    </List>
                    {
                        tab === 1 &&
                        <ItineraryBlocksTreeView />
                    }
                </Box>
            </Box>
            <ItineraryBlocksFilters
                open={openFilters}
                anchorEl={filtersButtonRef.current}
                onClose={() => setOpenFilters(false)}
            />
            <ItineraryBlocksSorts
                open={openSorts}
                anchorEl={sortsButtonRef.current}
                onClose={() => setOpenSorts(false)}
            />
        </>
    );
}

function DestinationsListWrapper(): React.ReactNode {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const nightsCountFilter = useSelector((state: AppState) => state.itinerarySlice.blocks.filters.nightsCount);
    const sortBy = useSelector((state: AppState) => state.itinerarySlice.blocks.sortBy);
    const destinations = useSelector((state: AppState) => state.itinerarySlice.destinations);
    const map = useSelector((state: AppState) => state.itinerarySlice.map);

    useEffect(() => {
        (async () => {
            const items = filterDestinationsBasedOnZoom(
                destinations.data,
                map?.getZoom() ?? 8
            );
            if (items[0]?.parent_id) {
                const parent = await getDestination(items[0].parent_id);
                dispatch(
                    setParentDestination(parent)
                );

                const parentDestinations: typeof parent[] = []
                let grandParent = parent;
                while (grandParent && (grandParent.data?.type ?? 1) > 0) {
                    parentDestinations.push(grandParent);
                    grandParent = grandParent.parent?.id ?
                        await getDestination(grandParent.parent?.id) :
                        null;
                }
                dispatch(
                    setParentDestinationTree(
                        parentDestinations.filter((item) => !!item)
                    )
                );
            }
        })();
    }, [map, destinations]);

    return [...destinations.data].filter((destination) => {
        const duration = (destination.suggested_hours ?? 0) / 24;
        return !nightsCountFilter ||
            (
                nightsCountFilter[0] <= duration &&
                duration <= nightsCountFilter[1]
            );
    }).sort((a, b) => {
        if (sortBy?.field === 'nights-count') {
            const aDuration = (a.suggested_hours ?? 0) / 24;
            const bDuration = (b.suggested_hours ?? 0) / 24;
            return sortBy.direction === 'asc' ?
                aDuration - bDuration :
                bDuration - aDuration;
        }
        return getLightDestinationName(locale, a).localeCompare(getLightDestinationName(locale, b));
    }).map((destination) => (
        <ItineraryDestinationItem
            key={destination.id}
            destination={destination}
            suggestedDuration={(destination.suggested_hours ?? 0) / 24}
        />
    ));
}

const GoBackButton = styled(IconButton)(() => ({
    width: 24,
    height: 24,
    fontSize: 12,
    border: '1px solid #ddd',
    borderRadius: '50%',
    background: 'none',
    color: '#ddd',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const SortIconButton = styled(IconButton)(() => ({
    width: 24,
    height: 24,
    fontSize: 20,
    borderRadius: '50%',
    background: 'none',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const SortButton = styled(Button)(() => ({
    height: 30,
    textTransform: 'none',
    borderRadius: 15
}));

const ModeTabs = styled(Tabs)((props) => ({
    boxShadow: '0px 4px 5px 0px rgb(0 0 0 / 20%)',
    marginBottom: props.theme.spacing(2),
    minHeight: 72
}));

const ModeTab = styled(Tab)(() => ({
    fontSize: 12,
    textTransform: 'none'
}));
