import GetMinimalPrices from '../../FlightMaterial/Functions/GetMinimalPrices';

import { InitStopOverActive,  CreateFlightHours} from '../../../Actions/FlightSearch'
import { GetProvider, SetOpenDatePickers} from '../../../Actions/Flight'

export function FlightRedirection(data: any, flight_circuit: any, startAirport: any, groups: any, itinerary: any, cabin_choice: any, providers: any, user: any, router: any, dispatch: any): void {
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    let init_group = {
        is_km: true,
        travelers: [],
        journey: [
            {
                start_date: null,
                origin: null,
                destination: null,
                class_type: 0
            },
            {
                start_date: null,
                origin: null,
                destination: null,
                class_type: 0
            }
        ],
        company: [],
        direct_flight: false,
        one_stop: false,
        private_price: false,
        public_price: false,
        flexible_date: false,
        included_luggage: false,
        journey_type: 1, //1: round trip, 2: multi destination, 3: one way, 4: manual
        provider: null,
        error: []
    }
    const cabin = {
        ECONOMY: 0,
        PREMIUM_ECONOMY: 1,
        BUSINESS: 2,
        FIRST: 3
    }
    let flight_groups = [];
    let filter_step = itinerary.filter(iti => iti.step_type === 'STEP');
    let diff_day = moment.utc(filter_step[filter_step.length - 1].end_date).diff(moment.utc(itinerary[0].start_date), "d");
    let flight_providers = [];
    
    groups.map((group) => {
        let current_group = {...init_group};
        current_group.travelers = [...group.travelers_list];
        current_group.company = [...flight_circuit[0].allowed_airline];
        let provider = [];
        providers.map((tmp_provider) => {
            if (tmp_provider.enabled_content_types.includes(6)) {
                if (["eva_amadeus", "eva_misterfly", "amadeus_rest", "1G", "tahitinui", "amadeus_soap", "sabre_rest", "custom_flight", "travel_fusion"].includes(tmp_provider.provider.code)) {
                    flight_providers.push({
                        id: tmp_provider.provider.id,
                        name: tmp_provider.provider.name,
                        code: tmp_provider.provider.code,
                        created_date: tmp_provider.provider.created_date
                    });
                }
            }
        });
        if (flight_circuit[0].gds_source) {
            if (flight_circuit[0].providers.length !== 0) {
                provider = flight_circuit[0].providers.map(tmp_provider => {return tmp_provider.id});
            } else if (flight_providers.length !== 0) {
                provider = flight_providers.map(tmp_provider => tmp_provider.id);
            }
        }
        if (flight_circuit[0].allow_manual_flight) {
            let gir_provider = providers.find((tmp) => {
                return tmp.provider.code === "custom_flight";
            });
            if (gir_provider !== undefined) {
                provider.push(gir_provider.provider.id)
            }
        }
        let airport_light_start = {
            name: startAirport.name !== null ? startAirport.name : startAirport.international_name,
            city: startAirport.iata_city !== undefined ? (startAirport.iata_city.name !== undefined ? startAirport.iata_city.name : startAirport.iata_city.international_name) : startAirport.city,
            airport_code: startAirport.airport_code
        }
        let first_airport = filter_step[0].destination.data.iata_airports.toSorted((a:any, b:any) => a.weight - b.weight);
        let last_airport = filter_step[filter_step.length - 1].destination.data.iata_airports.toSorted((a:any, b:any) => a.weight - b.weight);
        let airport_light_end_first_segment = {
            name: first_airport[0].name !== null ? first_airport[0].name : first_airport[0].international_name,
            city: first_airport[0].iata_city.name !== undefined ? first_airport[0].iata_city.name : first_airport[0].iata_city.international_name,
            airport_code: first_airport[0].airport_code
        }
        let airport_light_end_last_segment = {
            name: last_airport[0].name !== undefined && last_airport[0].name !== null ? last_airport[0].name : last_airport[0].international_name,
            city: last_airport[0].iata_city.name !== undefined && last_airport[0].iata_city.name !== null ? last_airport[0].iata_city.name : last_airport[0].iata_city.international_name,
            airport_code: last_airport[0].airport_code
        }
        current_group.journey[0].origin = {...airport_light_start};
        current_group.journey[0].destination = {...airport_light_end_first_segment};
        current_group.journey[1].origin = {...airport_light_end_last_segment};
        current_group.journey[1].destination = {...airport_light_start};
        current_group.provider = provider;
        current_group.journey[0].start_date = data.start_date;
        current_group.journey[1].start_date = moment(data.start_date).add(diff_day, 'd').format('YYYY-MM-DD') + 'T00:00:00Z';
        current_group.direct_flight = !flight_circuit[0].allow_stopovers;
        if (cabin_choice !== undefined && cabin_choice !== null && cabin_choice !== '') {
            current_group.journey[0].class_type = cabin[cabin_choice];
            current_group.journey[1].class_type = cabin[cabin_choice];
        } 
        current_group.public_price = flight_circuit[0].public_standard_offer;
        current_group.private_price = flight_circuit[0].standard_TO_offer;
        current_group.one_stop = flight_circuit[0].allow_stopovers && flight_circuit[0].max_stops === 1;
        current_group.included_luggage = flight_circuit[0].number_of_bags !== null && flight_circuit[0].number_of_bags.length !== 0 && (flight_circuit[0].number_of_bags.includes(1) || flight_circuit[0].number_of_bags.includes(2));
        flight_groups.push(current_group);
    });
    console.log('flight_groups:', flight_groups);
    let temp_open_date_pickers = [];
    flight_groups.map((group) => {
        let temp_group_pickers = [];
        for (let i = 0; i < group.journey.length; i++) {
            temp_group_pickers.push(false);
        }
        temp_open_date_pickers.push(temp_group_pickers);
    });
    dispatch(SetOpenDatePickers(temp_open_date_pickers));
    dispatch(GetProvider(flight_providers, 0, quotation_code));
    dispatch({type: 'FLIGHT_SET_GROUP_INDEX', payload: {index_group: 0}});
    dispatch({type: 'FLIGHT_SET_GROUP_COPY_INDEX', payload: {index_group: 0}});
    // dispatch({type: 'FLIGHT_GET_PROVIDERS', payload: {provider_list: flight_circuit[0].providers}});
    dispatch(InitStopOverActive(flight_groups[0].journey.length));
    dispatch({type: 'FLIGHT_SET_FLIGHT_GROUPS', payload: {flight_groups: flight_groups}});
    dispatch(CreateFlightHours(2));
    router.push(`/${window.url_name}/apps/flight-search/true`);
}