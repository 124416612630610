import { useSnackbar } from "notistack";
import axios from "axios";
import clsx from "clsx";
import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { isNumber } from "lodash";

import { useGetFlightSellingPrice } from "../CartMaterial/utils/getFlightSellingPrice";
import GetPricesId from "../Cart/Functions/Margin/GetPricesId.js";
import CheckBeforeRequest from "../Common/CheckBeforeRequest.js";
import GetCookie from "../Common/Functions/GetCookie";
import UpdateAdvancePayment from "../Common/Functions/UpdateAdvancePayment.js";
import SetCookie from "../Common/Functions/SetCookie.js";
import { ToShowVersion } from "../../Actions/Menu.js";
import { SetFlightCart } from "../../Actions/Flight.js";
import { SetCarsCart } from "../../Actions/CarsSearch.js";
import { SetCart, SetSortCart } from "../../Actions/Accommodation.js";
import { SetManualCart } from "../../Actions/Cart.js";
import { SetTransfersCart } from "../../Actions/Transfers.js";
import { ToggleAndSetLanguage } from "../../Actions/Header.js";
import { LockItineraryActions } from "../../Actions/Base.js";
import GetTrip from "../Base/Functions/GetTrip.js";
import { useCartTotalPrices } from "../CartMaterial/utils/cartTotalPrices.ts";

const useStyles = makeStyles(theme => ({
    formControlRoot: {
        borderRadius: "20px",
        padding: 0,
        position: "relative",
        textTransform: "capitalize",
        display: "block",
        border: 'none',
        boxShadow: 'none'
        //boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)'
  	},
    inputStepper: {
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: 12
        },
        "& .mui-jss-MuiOutlinedInput-root": {
            position: "relative",
            borderRadius: 20
        }
    },
    paperStepper: {
        position: "absolute !important",
        top: "100% !important",
        zIndex: "1 !important",
        background: "rgb(255, 255, 255) !important",
        borderRadius: "32px !important",
        boxShadow: "rgb(0 0 0 / 20%) 0px 6px 20px !important",
        marginTop: "12px !important",
        maxHeight: "calc(100vh - 220px) !important",
        overflow: "hidden auto !important",
        padding: "16px 16px !important"
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    arrowDown: {
        margin: '6px 5px',
        position: 'absolute',
        right: 0,
        top: 0
    },
    buttonWhite: {
	    backgroundColor: 'white'
    },
    responsiveSize: {
        fontSize: 10
    }
}));

const TripBudget = ({ setIsOpen, isOpen, router }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const theme = useTheme();
    let current_location = router.getCurrentLocation();
    const smallScreen = useMediaQuery(theme.breakpoints.between(960, 1258));

    const data_trip = useSelector((store) => store.trip.data_trip);
    const start_date = useSelector((store) => store.trip.start_date);
    const trip_info = useSelector((store) => store.trip.all_data);
    const user = useSelector((store) => store.user.user);
    const currency_list = useSelector((store) => store.base.currency_list);
    const pois = useSelector(store => store.poi.cart);
    const flights = useSelector(store => store.flight.cart);
    const cars = useSelector(store => store.cars_search.cart);
    const transfers = useSelector(store => store.transfers.cart);
    const accommodations = useSelector(store => store.accommodation.sort_cart);
    const manual_products = useSelector(store => store.cart.manual_item_list);
    const assistance_cart = useSelector(state => state.cart.assistance_cart);
    const update_date = useSelector(state => state.cart.update_date);
    const trip_budget = useSelector((store) => store.trip.budget);
    const trip_currency = useSelector((store) => store.trip.currency);
    const total_trip_cost = useSelector((store) => store.trip.total_cost);
    const lock_itinerary_action = useSelector((store) => store.base.lock_itinerary_action);
    const hasStackedItems = useSelector(state => state.cart.hasStackedItems);
    const global_margin_value = useSelector(store => store.cart.global_margin_value);
    const loaded = useSelector(store => store.trip.loaded);

    const [totalCost, setTotalCost] = useState([]);
    const [remainingBudget, setRemainingBudget] = useState(0);
    const [tripCost, setTripCost] = useState(null);
    const [value, setInputValue] = useState("");
    const [error, setError] = useState(false);
    const [getFlightSellingPrice] = useGetFlightSellingPrice();
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const prices = useCartTotalPrices();

    useEffect(() => {
        if (!lock_itinerary_action && loaded) {
            getData();
        }
    }, [trip_budget, pois, assistance_cart, transfers, manual_products, flights, cars, accommodations, data_trip, lock_itinerary_action, loaded, hasStackedItems]);
    useEffect(() => {
        if (!lock_itinerary_action) {
            if (current_location.pathname.includes('/cart') && tripCost !== null && ((data_trip.expected_deposit === null || tripCost.toFixed(2) !== data_trip.total_cost) || update_date)) {
                UpdateAdvancePayment(tripCost, quotation_code, update_date, start_date, dispatch, data_trip.status_contract, data_trip.status_modification, data_trip.has_manual_expected_deposit, manual_products, user);
            }
        }
    }, [tripCost, update_date, start_date]);
    const getData = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        console.log('prices:', prices);
        let total_cost = prices.totalCost;
        let trip_cost = prices.totalCartCost;
        let remaining_budget = trip_budget === null || trip_budget === 0 ? 0 : trip_budget - trip_cost;
        total_cost.map(currency_cost => currency_cost.cost = currency_cost.cost.toLocaleString(i18n.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        dispatch({ type: 'CART_GLOBAL_MARGIN_VALUE', payload: { margin: prices.margin.value } });
        setTripCost(trip_cost);
        setTotalCost(total_cost);
        setRemainingBudget(remaining_budget);
        if (['cercledesvoyages', 'continentsinsolites', 'tropicalementvotre', 'terreslointaines'].includes(quotation_code)) {
            if (trip_info !== null && (trip_info.agreed_margin === null || trip_info.agreed_price === null || trip_info.agreed_price === '0.00' || (data_trip.signed_as_amendment !== undefined && data_trip.signed_as_amendment)) && data_trip.status_contract === 'CONFIRMED' && data_trip.status_modification === 'FIXED_PV' && trip_cost !== 0 && prices.margin.value !== 0) {
                // axios({
                //     method: "PATCH",
                //     url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/`,
                //     headers: headers,
                //     data: {
                //         agreed_price: parseFloat(trip_cost.toFixed(2)),
                //         agreed_margin: prices.margin.value
                //     }
                // }).then((response) => {
                //     dispatch({
                //         type: "MENU_SET_TRIP_INFO",
                //         payload: {
                //             trip_info: response.data
                //         }
                //     });
                //     dispatch({
                //         type: "TRIP_SET_ALL_DATA",
                //         payload: {
                //             data: response.data
                //         }
                //     });
                // }).catch((error) => {
                //     console.log('error patch agreed price and margin:', error);
                // });
                if (data_trip.signed_as_amendment) {
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${data_trip.id}/`,
                        headers: headers,
                        data: {
                            signed_as_amendment: false
                        }
                    }).then((response) => {
                        dispatch({
                            type: "TRIP_SET_DATA_TRIP",
                            payload: {
                                data_trip: response.data
                            }
                        });
                        dispatch({
                            type: "MENU_SET_TRIP_INFO_VERSION",
                            payload: {
                                version: response.data
                            }
                        });
                    }).catch((error) => {
                        console.log('error patch signed_as_amendment:', error);
                    });
                }
            }
        }
        if (['cercledesvoyages', 'continentsinsolites', 'americavoyages', 'terreslointaines'].includes(quotation_code)) {
            dispatch({
                type: "TRIP_SET_TOTAL_COST",
                payload: parseFloat(trip_cost)
            });
        }
    };
    const cancel = () => {
        setInputValue("");
        handleOpen();
    };
    const handleOpen = () => {
        if (isOpen === null || isOpen !== "budget") {
            setIsOpen("budget");
        } else {
            setIsOpen(null);
        }
    };
    const changeBudget = () => {
        if (value !== "" && (parseFloat(value) - tripCost) >= 0) {
            setError(false);
            updateBudget();
        }
        if ((parseFloat(value) - tripCost) < 0) {
            enqueueSnackbar(t('header.budget_too_low'), {
                variant: "error"
            });
            setError(true);
        }
    };
    const updateBudget = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            let request = {
                budget: value
            };
            axios({
                method: "PATCH",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/`,
                data: JSON.stringify(request)
            }).then(function (response) {
                console.log('data:', response);
                dispatch({
                    type: "TRIP_SET_BUDGET",
                    payload: {
                        budget: response.data.budget
                    }
                });
                handleOpen();
            }).catch(function (error) {
                //TODO: show snackbar error
            });
        }
    };
    return (
        <Fragment>
            <Button className={clsx(classes.formControlRoot, classes.buttonWhite, { [classes.responsiveSize]: smallScreen })} variant={"outlined"} fullWidth disableRipple={true} onClick={handleOpen}>
                {
                    user.client_full.type !== 2 && quotation_code === 'visiteurs' ? (
                        <Tooltip title={ t("header.visiteurs-budget") }>
                            <div>
                                { totalCost.map((current_currency, index_current_currency) => <span key={index_current_currency} style={{ fontWeight: "bold" }}>{ index_current_currency > 0 ? ' + ' : '' }{i18n.language === "en" ? current_currency.currency.symbol + " " + current_currency.cost : current_currency.cost + " " + current_currency.currency.symbol }</span>) }
                            </div>
                        </Tooltip>
                    ) : (
                        <Fragment>{ totalCost.map((current_currency, index_current_currency) => <span key={index_current_currency} style={{ fontWeight: "bold" }}>{ index_current_currency > 0 ? ' + ' : '' }{i18n.language === "en" ? current_currency.currency.symbol + " " + current_currency.cost : current_currency.cost + " " + current_currency.currency.symbol }</span>) }</Fragment>
                    )
                }
                { totalCost.length === 0 && (<div style={{ fontWeight: "bold" }}>0</div>)}
                {/*<ArrowDropDownIcon className={ classes.arrowDown }/> */}
                <div style={{ marginTop: "-6px", fontSize: smallScreen ? 10 : 12 }}>
                    ({ t("header.remaining") } : <span className={ remainingBudget > 0 ? "" : "ft-header-red" }> { new Intl.NumberFormat(i18n.language, { style: 'currency', currency: trip_currency.iso_code }).format(Math.ceil(remainingBudget))}</span>)
                </div>
            </Button>
            {
                isOpen === "budget" && data_trip !== null && (!data_trip.trip.itinerary_type && !data_trip.trip.package_type && !data_trip.trip.circuit_type) && (
                    <ClickAwayListener touchEvent={ false } onClickAway={handleOpen}>
                        <Paper
                            className={classes.paperStepper}
                            elevation={5}
                            children={
                                <Fragment>
                                    <DialogTitle>{t("header.new_budget")}</DialogTitle>
                                    <DialogContent>
                                        <TextField
                                            error={error}
                                            fullWidth
                                            variant={"outlined"}
                                            placeholder={t("header.current_budget") + trip_budget + " " + trip_currency.symbol}
                                            autoFocus={isOpen === "budget"}
                                            className={classes.inputStepper}
                                            onChange={(e) => {
                                                setInputValue(e.target.value);
                                            }}
                                        />
                                    </DialogContent>
                                    <DialogActions disableSpacing={ true }>
                                        <Button onClick={cancel}>{t("accommodation.cancel")}</Button>
                                        <Button
                                            variant={"contained"}
                                            className={ classes.orangeButton }
                                            onClick={changeBudget}
                                        >
                                            {t("global.validate")}
                                        </Button>
                                    </DialogActions>
                                </Fragment>
                            }
                        />
                    </ClickAwayListener>
                )
            }
        </Fragment>
    );
};
export default React.memo(TripBudget);
