//Dependencies
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//Core
import { Box, Button, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { CartConstructionProductsTableItemStatus } from "./CartConstructionProductsTableItemStatus";
//Common
import { SetDataTrip } from '../../Actions/Trip';
import { usePackagedProducts } from './utils/packagedProducts';
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";
import CheckResponse from "../Flight/FlightSelected/Functions/CheckResponse";

const useStyles = makeStyles({
    hr: {
        margin: '10px 0 0 0'
    },
    booked: {
        border: '2px solid #4caf50',
        color: '#4caf50',
        padding: 4,
        borderRadius: 8,
        marginLeft: 6
    },
    pending: {
        border: '2px solid #ff9800',
        color: '#ff9800',
        padding: 4,
        borderRadius: 8,
        marginLeft: 6
    },
    cancelled: {
        border: '2px solid black',
        color: 'black',
        padding: 4,
        borderRadius: 8,
        marginLeft: 6
    },
    error: {
        border: '2px solid #f44336',
        color: '#f44336',
        padding: 4,
        borderRadius: 8,
        marginLeft: 6
    },
    buttonContainer: {
        margin: '0 15px 15px 0',
        paddingTop: 0
    },
    cancelButtonWrapper: {
        position: 'relative'
    },
    cancelAllRunning: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -8,
        marginLeft: -16
    }
});

const CartAccommodationBookingDetail = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const user = useSelector(state => state.user.user);
    const booking_detail = useSelector(state => state.cart.booking_detail);
    const end_date = useSelector(state => state.trip.end_date);
    const data_trip = useSelector(state => state.trip.data_trip);
    const all_data = useSelector(state => state.trip.all_data);

    const [checked, setChecked] = useState([]);
    const [cancellable, setCancellable] = useState(0);
    const [spinCancelAll, setSpinCancelAll] = useState(false);
    const [confirmationDate, setConfirmationDate] = useState(null);
    const packagedProducts = usePackagedProducts();

    const quotation_code = JSON.parse(localStorage.getItem('config')).quotation_code;

    const onClose = () => {
        dispatch({ type: 'CART_TOGGLE_BOOKING_DETAIL', payload: null });
    };
    const onChecked = id => () => {
        let tmp_checked = checked.slice();
        if (tmp_checked.includes(id)) {
            tmp_checked.splice(tmp_checked.indexOf(id), 1);
        } else {
            tmp_checked.push(id);
        }
        setChecked(tmp_checked);
    };
    const onCancelAll = () => {
        let items = [];
        if (booking_detail.rooms !== undefined) {
            booking_detail.rooms.map(room => items.push(room.id));
        } else {
            items.push(booking_detail.id);
        }

        if (booking_detail?.isConnectedPackage) {
            items = packagedProducts[`${booking_detail.stack_number ?? ''}-${booking_detail.id ?? ''}`]?.accommodations.map((item) => {
                return item.accommodation.id;
            }).concat(
                packagedProducts[`${booking_detail.stack_number ?? ''}-${booking_detail.id ?? ''}`]?.assistances.map((item) => {
                    return item.assistance.id;
                })
            ).concat(
                packagedProducts[`${booking_detail.stack_number ?? ''}-${booking_detail.id ?? ''}`]?.cars.map((item) => {
                    return item.car.id;
                })
            ).concat(
                packagedProducts[`${booking_detail.stack_number ?? ''}-${booking_detail.id ?? ''}`]?.flights.map((item) => {
                    return item.flight.id;
                })
            ).concat(
                packagedProducts[`${booking_detail.stack_number ?? ''}-${booking_detail.id ?? ''}`]?.manualProducts.map((item) => {
                    return item.id;
                })
            ).concat(
                packagedProducts[`${booking_detail.stack_number ?? ''}-${booking_detail.id ?? ''}`]?.pois.map((item) => {
                    return item.poi.id;
                })
            ).concat(
                packagedProducts[`${booking_detail.stack_number ?? ''}-${booking_detail.id ?? ''}`]?.transfers.map((item) => {
                    return item.transfer.id;
                })
            );
        }

        setSpinCancelAll(true);
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'DELETE',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/booking/destroy/?product_types=${booking_detail.product_type}`,
            data: { items }
        }).then(response => {
            console.log('response.data : ', response.data);
            enqueueSnackbar(items.length > 1 ? t('cart-material.bookings-deleted') : t('cart-material.booking-deleted'), { variant: 'success' });
            dispatch({ type: 'CART_TOGGLE_BOOKING_DETAIL', payload: null });
            if (booking_detail?.isConnectedPackage) {
                axios({
                    method: "GET",
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/?token=${GetCookie("trip_token")}`,
                    headers: headers
                }).then(function (response) {
                    dispatch(SetDataTrip(response.data));
                });
            } else {
                switch (booking_detail.product_type) {
                    case 0:
                        dispatch({ type: 'ACCOMMODATION_UPDATE_CART', payload: response.data });
                        break;
                    case 6:
                        let new_flight = [];
                        new_flight.push(response.data[0][0]);
                        let arr = CheckResponse(new_flight, end_date);
                        dispatch({ type: 'FLIGHT_EDIT_CART_BY_ID', payload: arr[0] });
                        break;
                    case 2:
                        dispatch({ type: 'CARS_UPDATE_CART', payload: response.data });
                        break;
                    case 4:
                        dispatch({ type: 'TRANSFERS_UPDATE_CART', payload: response.data });
                        break;
                    case 14:
                        dispatch({ type: 'POI_UPDATE_CART', payload: response.data });
                        break;
                    case 19:
                    case 8:
                        dispatch({ type: 'CART_UPDATE_CART', payload: response.data });
                        break;
                    default:
                        break;
                }
            }
            setSpinCancelAll(false);
        }).catch(error => {
            console.log(error);
            setSpinCancelAll(false);
            enqueueSnackbar(items.length > 1 ? t('cart-material.bookings-not-deleted') : t('cart-material.booking-not-deleted'), { variant: 'danger' });
        }).finally(() => {
            setChecked([]);
        });
    };
    const onCancelSelected = () => {
        const { headers } = CheckBeforeRequest();
        setSpinCancelAll(true);
        axios({
            method: 'DELETE',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/booking/destroy/?product_types=${booking_detail.product_type}`,
            data: {
                items: checked
            }
        }).then(response => {
            console.log('response.data : ', response.data);
            enqueueSnackbar(checked.length > 1 ? t('cart-material.bookings-deleted') : t('cart-material.booking-deleted'), { variant: 'success' });
            dispatch({ type: 'CART_TOGGLE_BOOKING_DETAIL', payload: null });
            switch (booking_detail.product_type) {
                case 0:
                    dispatch({ type: 'ACCOMMODATION_UPDATE_CART', payload: response.data });
                    break;
                case 6:
                    let new_flight = [];
                    new_flight.push(response.data[0][0]);
                    let arr = CheckResponse(new_flight, end_date);
                    dispatch({ type: 'FLIGHT_EDIT_CART_BY_ID', payload: arr[0] });
                    break;
                case 2:
                    dispatch({ type: 'CARS_UPDATE_CART', payload: response.data });
                    break;
                case 4:
                    dispatch({ type: 'TRANSFERS_UPDATE_CART', payload: response.data });
                    break;
                case 14:
                    dispatch({ type: 'POI_UPDATE_CART', payload: response.data });
                    break;
                case 19:
                case 8:
                    dispatch({ type: 'CART_UPDATE_CART', payload: response.data });
                    break;
                default:
                    break;
            }
            setSpinCancelAll(false);
        }).catch(error => {
            console.log(error);
            setSpinCancelAll(false);
            enqueueSnackbar(checked.length > 1 ? t('cart-material.bookings-not-deleted') : t('cart-material.booking-not-deleted'), { variant: 'danger' });
        }).finally(() => {
            setChecked([]);
        });
    };
    useEffect(() => {
        if (booking_detail !== null) {
            let tmp_cancellable = 0;
            if (booking_detail.rooms !== undefined) {
                booking_detail.rooms.map(room => {
                    if (room.booking_status !== null && ((room.booking_status.status_booking === 'CONFIRMED' && room.booking_process_state !== 'CANCEL') || ((room.booking_status.status_booking === 'WAITING' || room.booking_status.status_booking === 'PENDING') || (room.booking_process_state === 'BOOK' && room.booking_status.status_booking !== 'CONFIRMED')))) {
                        tmp_cancellable++;
                    }
                });
            } else if (booking_detail.booking_status !== null && ((booking_detail.booking_status.status_booking === 'CONFIRMED' && booking_detail.booking_process_state !== 'CANCEL') || ((booking_detail.booking_status.status_booking === 'WAITING' || booking_detail.booking_status.status_booking === 'PENDING') || (booking_detail.booking_process_state === 'BOOK' && booking_detail.booking_status.status_booking !== 'CONFIRMED')))) {
                tmp_cancellable++;
            }
            setCancellable(tmp_cancellable);
        }
    }, [booking_detail]);

    useEffect(() => {
        if (all_data !== null) {
            let index_version = all_data.data.findIndex(data => data.id === parseInt(GetCookie('trip_id_version')));
            if (index_version !== -1 && index_version - 1 !== -1) {
                setConfirmationDate(all_data.data[index_version - 1].status_contract_confirmed_date);
            }
        }
    }, [all_data]);

    if (booking_detail !== null) {
        return (
            <Dialog open={ true } fullWidth maxWidth={ 'md' } onClose={ onClose } scroll="body">
                <DialogTitle onClose={ onClose }>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography fontWeight="bold">
                            { t('cart-material.booking-detail') }
                        </Typography>
                        <IconButton onClick={ onClose }>
                            <Close/>
                        </IconButton>
                    </Stack>
                    <hr className={ classes.hr }/>
                </DialogTitle>
                {
                    booking_detail.rooms !== undefined ? (
                        <Fragment>
                            <DialogContent sx={{ minHeight: '40vh' }}>
                                <Box
                                    component="ul"
                                    sx={{
                                        padding: 3,
                                        paddingTop: 1,
                                        paddingBottom: 0,
                                        margin: 0,
                                        listStyle: 'none',
                                        li: {
                                            marginBottom: 1
                                        }
                                    }}
                                >
                                    {
                                        booking_detail.rooms?.map((room, index) => (
                                            <Box
                                                key={room.id}
                                                component="li"
                                                sx={{
                                                    display: 'flex',
                                                    gap: 1,
                                                    alignItems: 'center'
                                                }}
                                            >
                                                {
                                                    room.booking_status !== null && room.booking_status.status_booking !== 'CANCELLED' && room.booking_status.status_booking !== 'ERROR' &&
                                                    <Checkbox edge={ 'start' } checked={ checked.includes(room.id) } tabIndex={ -1 } disableRipple onChange={ onChecked(room.id) }/>
                                                }
                                                <Typography>
                                                    {
                                                        t(
                                                            'cart-material.cart-construction-room-ref',
                                                            {
                                                                no: index + 1,
                                                                ref: room.booking_status?.item_reference
                                                            }
                                                        )
                                                    }
                                                </Typography>
                                                <CartConstructionProductsTableItemStatus
                                                    type="accommodation"
                                                    item={booking_detail}
                                                    bookingProcessState={room.booking_process_state}
                                                    bookingStatus={room.booking_status}
                                                />
                                            </Box>
                                        ))
                                    }
                                </Box>
                            </DialogContent>
                            <DialogActions className={ classes.buttonContainer }>
                                {
                                    (
                                        user.client_full.type !== 2 ||
                                        quotation_code === 'beachcomber'
                                    ) && cancellable > 0 &&
                                (!['cercledesvoyages', 'continentsinsolites', 'terreslointaines'].includes(quotation_code) || (!(data_trip.status_modification === 'AMENDMENT') || (confirmationDate !== null && moment(booking_detail.booking_status.booking_confirmation_date).isAfter(confirmationDate)))) &&
                                //((quotation_code !== "cercledesvoyages" && quotation_code !== "continentsinsolites") || ((quotation_code === "cercledesvoyages" || quotation_code === "continentsinsolites") && !(data_trip.status_contract === "CONFIRMED" && data_trip.status_modification === "AMENDMENT"))) &&
                                <Fragment>
                                    <div className={ classes.cancelButtonWrapper }>
                                        <Button variant="outlined" onClick={ onCancelAll } disabled={ spinCancelAll }>{ t('cart-material.cancel-all') }</Button>
                                        { spinCancelAll && <CircularProgress size={ 24 } className={ classes.cancelAllRunning }/> }
                                    </div>
                                    {
                                        booking_detail.product_type !== 24 &&
                                        <div className={ classes.cancelButtonWrapper }>
                                            <Button variant="outlined" onClick={ onCancelSelected } disabled={ spinCancelAll }>{ t('cart-material.cancel-selected') }</Button>
                                            { spinCancelAll && <CircularProgress size={ 24 } className={ classes.cancelAllRunning }/> }
                                        </div>
                                    }
                                </Fragment>
                                }
                            </DialogActions>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <DialogContent sx={{ minHeight: '40vh' }}>
                                { booking_detail.booking_status !== null && booking_detail.booking_status.status_booking === 'CONFIRMED' && booking_detail.booking_process_state !== 'CANCEL' && <Typography className={ classes.booked }>{ t('cart-material.booking-reference') } : { booking_detail.booking_status.item_reference }</Typography> }
                                { booking_detail.booking_status !== null && (booking_detail.booking_status.status_booking === 'WAITING' || booking_detail.booking_status.status_booking === 'PENDING' || (booking_detail.booking_process_state === 'BOOK') && booking_detail.booking_status.status_booking !== 'CONFIRMED') && <Typography className={ classes.pending }>{ t('cart-material.pending') }</Typography> }
                                { booking_detail.booking_status !== null && booking_detail.booking_status.status_booking === 'CANCELLED' && <Typography className={ classes.cancelled }>{ t('cart-material.cancelled') }</Typography> }
                                { booking_detail.booking_status !== null && booking_detail.booking_status.status_booking === 'ERROR' && <Typography className={ classes.error }>{ t('cart-material.error') }</Typography> }
                            </DialogContent>
                            <DialogActions className={ classes.buttonContainer }>
                                {
                                    (
                                        user.client_full.type !== 2 ||
                                        quotation_code === 'beachcomber'
                                    ) && cancellable > 0 &&
                                (!['cercledesvoyages', 'continentsinsolites', 'terreslointaines'].includes(quotation_code) || (!(data_trip.status_modification === 'AMENDMENT') || (confirmationDate !== null && moment(booking_detail.booking_status.booking_confirmation_date).isAfter(confirmationDate)))) &&
                                    //((quotation_code !== "cercledesvoyages" && quotation_code !== "continentsinsolites") || ((quotation_code === "cercledesvoyages" || quotation_code === "continentsinsolites") && !(data_trip.status_contract === "CONFIRMED" && data_trip.status_modification === "AMENDMENT"))) &&
                                    <div className={ classes.cancelButtonWrapper }>
                                        <Button variant="outlined" onClick={ onCancelAll } disabled={ spinCancelAll }>{ t('cart-material.cancel') }</Button>
                                        { spinCancelAll && <CircularProgress size={ 24 } className={ classes.cancelAllRunning }/> }
                                    </div>
                                }
                            </DialogActions>
                        </Fragment>
                    )
                }
            </Dialog>
        );
    } 
    return null;
};

export default CartAccommodationBookingDetail;
