import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import grey from "@material-ui/core/colors/grey";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import TranslateIcon from '@material-ui/icons/Translate';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import ChildCareIcon from "@material-ui/icons/ChildCare";
import AccessibleIcon from '@material-ui/icons/Accessible';
import LowPriorityOutlinedIcon from '@material-ui/icons/LowPriorityOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import LoyaltyOutlinedIcon from '@material-ui/icons/LoyaltyOutlined';
import CheckIcon from '@material-ui/icons/Check';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import WarningIcon from '@material-ui/icons/Warning';

import {
    Stack
} from '@mui/material';

import moment from "moment";
import localeText from "../../Functions/localeText";
import LocaleTextArray from "../../Utils/localeText/localeTextArray";
import i18n from "../../../i18n";

const useStyles = makeStyles((theme) => ({
    spacer: {
        padding: "8px 0px"
    },
    bold: {
        fontWeight: "bold"
    },
    grey: {
        color: grey[800]
    },
    italic: {
        fontStyle: "italic"
    },
    icon: {
        paddingTop: 4
    }
}));

const AboutPoi = ({ activity, manualVariant }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const user = useSelector(state => state.user.user);
    const start_date = useSelector(state => state.poi.start_date);
    const locales = useSelector(state => state.user.locales);
    const manual_providers = useSelector(state => state.trip.manual_providers);

    const current_locale = locales.find((el) => {
        return el.language_code === i18n.language;
    });
    const [currentSchedule, setCurrentSchedule] = useState(null);
    const [openTime, setOpenTime] = useState(null);

    const quotation_code = JSON.parse(localStorage.getItem('config')).quotation_code;

    let provider = null;
    if (activity !== null) {
        if (activity.provider !== undefined && activity.provider !== null) {
            provider = activity.provider;
        } else if (activity.providers !== undefined && activity.providers.length > 0) {
            if (typeof activity.providers[0] === 'number') {
                provider = manual_providers.find((provider) => {
                    return provider.id === activity.providers[0];
                });
            } else {
                provider = activity.providers[0];
            }
        }
    }

    useEffect(() => {
        if (activity !== null && activity.schedules !== undefined && activity.schedules !== null) {
            let tmp_schedule = [];
            activity.schedules.map((schedule) => {
                if (schedule.variant === manualVariant.id) {
                    tmp_schedule.push(schedule);
                }
            });
            setCurrentSchedule(tmp_schedule);
        }
    }, [activity, manualVariant]);

    const renderSchedule = (schedule) => {
        let temp_open_time = "";

        if (schedule.type === "TO_SET") {
            temp_open_time = `${t("poi.all_year")} ${t("poi.from")} ${t("poi.week_days.0")} ${t("poi.to")} ${t("poi.week_days.6")}, ${t("poi.entire_day")}`;
        } else {
            if (schedule.months.length === 12) {
                temp_open_time = t("poi.all_year");
            } else {
                let months = schedule.months.reduce((accumulator, month) => {
                    let lastSubArray = accumulator[accumulator.length - 1];
                    if (!lastSubArray || lastSubArray[lastSubArray.length - 1] !== month - 1) {
                        accumulator.push([]);
                    }
                    accumulator[accumulator.length - 1].push(month);
                    return accumulator;
                }, []);

                
                for (let i = 0; i < months.length; i++) {
                    if (months[i].length > 1) {
                        temp_open_time += `${(i > 0 ? (months[i][0] === 3 || months[i][0] === 7 || months[i][0] === 9 ? " " + t("poi.and_month_from_2") : " " + t("poi.and_month_from_1") + " ") : (months[i][0] === 3 || months[i][0] === 7 || months[i][0] === 9 ? t("poi.from_month_2") : t("poi.from_month_1") + " "))}${t("poi.months." + months[i][0])} ${t("poi.to_month")} ${t("poi.months." + months[i][months[i].length - 1])}`;
                    } else {
                        temp_open_time += `${(i > 0 ? " " + t("poi.and_2") + " " : "")}${t("poi.months." + months[i][0])}`;
                    }
                }
            }

            temp_open_time += ", ";

            const days = schedule.days.reduce((accumulator, day) => {
                const lastSubArray = accumulator[accumulator.length - 1];
                if (!lastSubArray || lastSubArray[lastSubArray.length - 1] !== day - 1) {
                    accumulator.push([]);
                }
                accumulator[accumulator.length - 1].push(day);
                return accumulator;
            }, []);

            for (let i = 0; i < days.length; i++) {
                if (days[i].length > 1) {
                    temp_open_time += `${(i > 0 ? " " + t("poi.and_from") : t("poi.from"))} ${t("poi.week_days." + days[i][0])} ${t("poi.to")} ${t("poi.week_days." + days[i][days[i].length - 1])}`;
                } else {
                    temp_open_time += `${(i > 0 ? " " + t("poi.and") + " " : "")}${t("poi.week_days." + days[i][0])}`;
                }
            }

            for (let i = 0; i < schedule.hours.length; i++) {
                if (schedule.hours[i].length === 1) {
                    temp_open_time += `${i === 0 ? " " + t("poi.to_month") : (i === schedule.hours.length - 1 ? " " + t("poi.and_2") : ",")} ${schedule.hours[i][0].slice(0, -3).replace(":", "h")}`;
                } else {
                    temp_open_time += `${i === 0 ? " " + t("poi.from_hour") : (i === schedule.hours.length - 1 ? " " + t("poi.and_month_from_1") : ",")} ${schedule.hours[i][0].slice(0, -3).replace(":", "h")} ${t("poi.to_month")} ${schedule.hours[i][1].slice(0, -3).replace(":", "h")}`;
                }
            }
        }
        return temp_open_time;
    }
    return (
        <Grid>
            <br/>
            <Grid container alignItems="center" justify="space-between" direction={mobile ? "column" : "row"}>
                <Grid item>
                    <Typography variant="h6"> {t("poi.about") + " : "} </Typography>
                </Grid>
                <Grid item>
                    {
                        (user.client_full.type !== 2 || JSON.parse(localStorage.getItem("config")).quotation_code === 'verdie') && activity !== null && !activity.is_custom && provider.logo !== null && (
                            <img src={ provider.logo.url } alt={ "provider logo" } style={{ maxWidth: 120, maxHeight: 65 }} />
                        )
                    }
                    {
                        (user.client_full.type !== 2 || JSON.parse(localStorage.getItem("config")).quotation_code === 'verdie') && activity !== null && !activity.is_custom && provider.logo === null && (
                            <Typography className={ classes.grey }>{ provider.name }</Typography>
                        )
                    }
                </Grid>
            </Grid>
            <Grid item>
                {
                    activity.variants !== undefined && manualVariant !== null && !manualVariant.multiple_days &&
                    <Fragment>
                        {
                            manualVariant.duration >= 1440 && (
                                <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                                    <Grid item>
                                        <ScheduleOutlinedIcon className={classes.icon} />
                                    </Grid>
                                    <Grid item>
                                        <Typography className={ classes.grey }> { (t("poi.duration")) + " :" } { Math.floor(manualVariant.duration / 1440) } { Math.floor(manualVariant.duration / 1440) > 1 ? t("poi.days") : t("poi.day")} </Typography>
                                    </Grid>
                                </Grid>
                            )
                        }
                        {
                            manualVariant.duration >= 60 && manualVariant.duration < 1440 && (
                                <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                                    <Grid item>
                                        <ScheduleOutlinedIcon className={classes.icon} />
                                    </Grid>
                                    <Grid item>
                                        <Typography className={ classes.grey }> { (t("poi.duration")) + " :" } { Math.floor(manualVariant.duration / 60) } { Math.floor(manualVariant.duration / 60) > 1 ? t("poi.hours") : t("poi.hour")} {Math.floor(manualVariant.duration % 60) !== 0 ? Math.floor(manualVariant.duration % 60) : ''} </Typography>
                                    </Grid>
                                </Grid>
                            )
                        }
                        {
                            manualVariant.duration < 60 && (
                                <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                                    <Grid item>
                                        <ScheduleOutlinedIcon className={classes.icon} />
                                    </Grid>
                                    <Grid item>
                                        <Typography className={ classes.grey }> { (t("poi.duration")) + " :" } { manualVariant.duration } {t("poi.minutes")} </Typography>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Fragment>
                }
                {
                    activity.variants !== undefined && manualVariant !== null && manualVariant.multiple_days &&
                    <Grid container direction={'column'} alignItems={"flex-start"} justify={"flex-start"} spacing={mobile ? 0 : 2} className={classes.spacer}>
                        <Grid item>
                            <Typography className={ classes.grey }>
                                {`${t('poi.multiple_days')} ${manualVariant.consecutive_day ? t('poi.consecutive_days') : t('poi.non_consecutive_days')}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={ 4 }>
                                <Grid item>
                                    <Typography className={ classes.grey }>
                                        {`${t('poi.day_number')} : ${Math.floor(manualVariant.duration / 1440)} ${Math.floor(manualVariant.duration / 1440) > 1 ? t("poi.days") : t("poi.day")}`}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={ classes.grey }>
                                        {`${t('poi.daily_duration')} : `}
                                        {
                                            manualVariant.daily_duration >= 60 && manualVariant.daily_duration < 1440 &&
                                            <Fragment>{ Math.floor(manualVariant.daily_duration / 60) } { Math.floor(manualVariant.daily_duration / 60) > 1 ? t("poi.hours") : t("poi.hour")}</Fragment>
                                        }
                                        {
                                            manualVariant.daily_duration < 60 &&
                                            <Fragment>{ manualVariant.daily_duration } {t("poi.minutes")}</Fragment>
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {
                    activity.variants === undefined &&
                    <Fragment>
                        {
                            activity.duration >= 1440 && (
                                <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                                    <Grid item>
                                        <ScheduleOutlinedIcon className={classes.icon} />
                                    </Grid>
                                    <Grid item>
                                        <Typography className={ classes.grey }> { (t("poi.duration")) + " :" } { Math.floor(activity.duration / 1440) } { Math.floor(activity.duration / 1440) > 1 ? t("poi.days") : t("poi.day")} </Typography>
                                    </Grid>
                                </Grid>
                            )
                        }
                        {
                            activity.duration >= 60 && activity.duration < 1440 && (
                                <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                                    <Grid item>
                                        <ScheduleOutlinedIcon className={classes.icon} />
                                    </Grid>
                                    <Grid item>
                                        <Typography className={ classes.grey }> { (t("poi.duration")) + " :" } { Math.floor(activity.duration / 60) } { Math.floor(activity.duration / 60) > 1 ? t("poi.hours") : t("poi.hour")} {Math.floor(activity.duration % 60) !== 0 ? Math.floor(activity.duration % 60) : ''}</Typography>
                                    </Grid>
                                </Grid>
                            )
                        }
                        {
                            activity.duration < 60 && (
                                <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                                    <Grid item>
                                        <ScheduleOutlinedIcon className={classes.icon} />
                                    </Grid>
                                    <Grid item>
                                        <Typography className={ classes.grey }> { (t("poi.duration")) + " :" } { activity.duration } {t("poi.minutes")} </Typography>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Fragment>
                }
                {
                    activity.max_passengers !== undefined && activity.max_passengers !== null && activity.max_passengers !== 0 && (
                        <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                            <Grid item>
                                <PeopleOutlineOutlinedIcon className={classes.icon} />
                            </Grid>
                            <Grid item>
                                <Typography className={ classes.grey }>
                                    { activity.max_passengers + " " + t("poi.max_pax") }
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                }
                {
                    activity.max_pax !== undefined && activity.max_pax !== null && activity.max_pax !== 0 && (
                        <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                            <Grid item>
                                <PeopleOutlineOutlinedIcon className={classes.icon} />
                            </Grid>
                            <Grid item>
                                <Typography className={ classes.grey }>
                                    { activity.max_pax + " " + t("poi.max_pax") }
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                }
                {
                    activity.need_pax_weight && (
                        <Grid>
                            <br />
                            <Typography className={classes.bold} variant="body1"> { t("poi.requirements") + " :" } </Typography>
                            <br/>
                            <Typography variant="body2" className={classes.italic}> { t("poi.need_weight") } </Typography>
                        </Grid>
                    )
                }
                {
                    activity.audio_guide && (
                        <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                            <Grid item>
                                <GraphicEqIcon className={classes.icon} />
                            </Grid>
                            <Grid item>
                                <Typography className={ classes.grey }> {t("poi.audio_guide")} </Typography>
                            </Grid>
                        </Grid>
                    )
                }
                {
                    activity.guide && (
                        <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                            <Grid item>
                                <EmojiPeopleIcon className={classes.icon} />
                            </Grid>
                            <Grid item>
                                <Typography className={ classes.grey }> { t("poi.guide_included") } </Typography>
                            </Grid>
                        </Grid>
                    )
                }
                {
                    (activity.guide || activity.audio_guide) && activity.guide_lang !== null && (
                        <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                            <Grid item>
                                <TranslateIcon className={classes.icon} />
                            </Grid>
                            <Grid item>
                                <Typography className={ classes.grey }> { t("poi.guide_lang") + " : " + activity.guide_lang } </Typography>
                            </Grid>
                        </Grid>
                    )
                }
                {
                    activity.special_attribute === "GUIDE" && (
                        <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                            <Grid item>
                                <SupervisedUserCircleOutlinedIcon className={classes.icon} />
                            </Grid>
                            <Grid item>
                                <Typography className={ classes.grey }> { t("poi.guide") } </Typography>
                            </Grid>
                        </Grid>
                    )
                }
                {
                    activity.special_attribute === "TRANSPORT_PASS" && (
                        <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                            <Grid item>
                                <LoyaltyOutlinedIcon className={classes.icon} />
                            </Grid>
                            <Grid item>
                                <Typography className={ classes.grey }> { t("poi.transport_pass") } </Typography>
                            </Grid>
                        </Grid>
                    )
                }
                {
                    activity.for_children && (
                        <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                            <Grid item>
                                <ChildCareIcon className={classes.icon} />
                            </Grid>
                            <Grid item>
                                <Typography className={ classes.grey }> {t("poi.for_children")} </Typography>
                            </Grid>
                        </Grid>
                    )
                }
                {
                    activity.for_disabled && (
                        <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                            <Grid item>
                                <AccessibleIcon className={classes.icon} />
                            </Grid>
                            <Grid item>
                                <Typography className={ classes.grey }> {t("poi.for_disabled")} </Typography>
                            </Grid>
                        </Grid>
                    )
                }
                {
                    activity.cut_line && (
                        <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                            <Grid item>
                                <LowPriorityOutlinedIcon className={classes.icon} />
                            </Grid>
                            <Grid item>
                                <Typography className={ classes.grey }> {t("poi.cut_line")} </Typography>
                            </Grid>
                        </Grid>
                    )
                }
                {
                    activity.multiple_days !== null && activity.multiple_days && (
                        <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"} className={classes.spacer}>
                            <Grid item>
                                <DateRangeOutlinedIcon className={classes.icon} />
                            </Grid>
                            <Grid item>
                                <Typography className={ classes.grey }> {t("poi.multiple_days")} </Typography>
                            </Grid>
                        </Grid>
                    )
                }
                <br />
                <Divider />
                <br />
                {
                    ((localeText(current_locale.id, activity.localization, activity.short_description, 'short_description') !== '' || (manualVariant !== '' && localeText(current_locale.id, manualVariant.localization, manualVariant.short_description, 'short_description') !== '')) || (localeText(current_locale.id, activity.localization, activity.long_description, 'long_description') !== '' || (manualVariant !== '' && localeText(current_locale.id, manualVariant.localization, manualVariant.long_description, 'long_description') !== ''))) &&
                    <Grid>
                        <Typography variant="h6"> { t("poi.description") + " : " } </Typography>
                        <br />
                        {
                            ["cercledesvoyages", "tropicalementvotre", 'terreslointaines'].includes(quotation_code) ? (
                                <Fragment>
                                    <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: localeText(current_locale.id, activity.localization, activity.short_description, 'short_description') }}/>
                                    <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: localeText(current_locale.id, activity.localization, activity.long_description, 'long_description') }}/>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: localeText(current_locale.id, activity.localization, activity.long_description, 'long_description') }}/>
                                    {
                                        (activity.long_description === null && (localeText(current_locale.id, activity.localization, activity.long_description, 'long_description') === '')) &&
                                        <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: localeText(current_locale.id, activity.localization, activity.short_description, 'short_description') }}/>
                                    }
                                </Fragment>
                            )
                        }
                        {
                            localeText(current_locale.id, activity.localization, activity.good_to_know, 'good_to_know') !== '' && (
                                <Grid>
                                    <br />
                                    <Divider />
                                    <br />
                                    <Typography variant="h6"> { t("poi.good_to_know") + " : " } </Typography>
                                    <br />
                                    <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: localeText(current_locale.id, activity.localization, activity.good_to_know, 'good_to_know') }}/>
                                    {/*<Typography variant="body1" align="justify">{ localeText(current_locale.id, activity.localization, activity.good_to_know, 'good_to_know') }</Typography> */}
                                </Grid>
                            )
                        }
                        {
                            manualVariant !== '' && (localeText(current_locale.id, manualVariant.localization, manualVariant.short_description, 'short_description') !== '' || localeText(current_locale.id, manualVariant.localization, manualVariant.long_description, 'long_description') !== '' || localeText(current_locale.id, manualVariant.localization, manualVariant.good_to_know, 'good_to_know') !== '') &&
                            <Fragment>
                                <br />
                                {
                                    (activity.short_description !== null || localeText(current_locale.id, activity.localization, activity.short_description, 'short_description') !== '') && (activity.long_description !== null || localeText(current_locale.id, activity.localization, activity.long_description, 'long_description') !== '') && (activity.good_to_know !== null || localeText(current_locale.id, activity.localization, activity.good_to_know, 'good_to_know') !== '') &&
                                    <Divider />
                                }
                                <br />
                                <Typography variant="h6"> { localeText(current_locale.id, manualVariant.localization, manualVariant.name, 'title') + " : " } </Typography>
                                <br />
                                {
                                    ["cercledesvoyages", "tropicalementvotre", 'terreslointaines'].includes(quotation_code) ? (
                                        <Fragment>
                                            <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: localeText(current_locale.id, manualVariant.localization, manualVariant.short_description, 'short_description') }}/>
                                            <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: localeText(current_locale.id, manualVariant.localization, manualVariant.long_description, 'long_description') }}/>
                                            <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: localeText(current_locale.id, manualVariant.localization, manualVariant.good_to_know, 'good_to_know') }}/>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            {
                                                (manualVariant.long_description !== null || (manualVariant.is_custom && localeText(current_locale.id, manualVariant.localization, manualVariant.long_description, 'long_description') !== '')) && <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: manualVariant.is_custom ? (manualVariant.long_description !== null ? manualVariant.long_description : localeText(current_locale.id, manualVariant.localization, manualVariant.long_description, 'long_description')) : '' }}/>
                                            }
                                            {
                                                (activity.long_description === null && (activity.is_custom && localeText(current_locale.id, activity.localization, activity.long_description, 'long_description') === '')) && (activity.short_description !== null || (activity.is_custom && localeText(current_locale.id, activity.localization, activity.short_description, 'short_description') !== '')) && <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: manualVariant.is_custom ? (manualVariant.short_description !== null ? manualVariant.short_description : localeText(current_locale.id, manualVariant.localization, manualVariant.short_description, 'short_description')) : '' }}/>
                                            }
                                            {
                                                (activity.long_description === null && (activity.is_custom && localeText(current_locale.id, activity.localization, activity.long_description, 'long_description') === '')) && (activity.short_description === null && (activity.is_custom && localeText(current_locale.id, activity.localization, activity.short_description, 'short_description') !== '')) && <Typography variant="body1" align="justify"> { t("poi.no_description") } </Typography>
                                            }
                                        </Fragment>
                                    )
                                }
                            </Fragment>
                        }
                    </Grid>
                }
                {
                    currentSchedule !== null && currentSchedule.length !== 0 && (
                        <Grid>
                            <br />
                            <Divider />
                            <br />
                            <Typography variant="h6"> { t("poi.schedule.title") + " : " } </Typography>
                            <br />
                            {
                                currentSchedule.map((schedule) => {
                                    return (
                                        <Stack key={schedule.id}>
                                            {renderSchedule(schedule)}
                                        </Stack>
                                    )
                                })
                            }
                        </Grid>
                    )
                }
                {
                    ((activity.selected_holidays !== undefined && activity.selected_holidays.length !== 0) || (activity.closed_days !== undefined && activity.closed_days.length !== 0)) && (
                        <Grid>
                            <br />
                            <Divider />
                            <br />
                            <Typography variant="h6"> { t("poi.schedule.closed_title") + " : " } </Typography>
                            <br/>
                            <Grid container alignItems="baseline" justify="space-between">
                                {
                                    activity.selected_holidays !== undefined && activity.selected_holidays.length !== 0 && (
                                        <Grid item>
                                            <Typography className={classes.bold} variant="body1"> { t("poi.schedule.bank_holiday") + " : "} </Typography>
                                            <br/>
                                            {
                                                activity.selected_holidays.map((holiday) => {
                                                    return (
                                                        <Typography variant="body1" key={holiday.id}>
                                                            {"- "} { holiday.name !== null ? holiday.name : holiday.international_name }
                                                        </Typography>
                                                    );
                                                })
                                            }
                                        </Grid>
                                    )
                                }
                                {
                                    activity.closed_days !== undefined && activity.closed_days.length !== 0 && (
                                        <Grid item>
                                            <Typography className={classes.bold} variant="body1"> { t("poi.schedule.other_closed_days") + " : " } </Typography>
                                            <br />
                                            {
                                                activity.closed_days.map((closed_day) => {
                                                    return (
                                                        <Typography variant="body1" key={closed_day.id}>
                                                            {"- "} { moment.utc(closed_day.day).format(closed_day.repeating ? "DD/MM" : "DD/MM/YYYY") }
                                                        </Typography>
                                                    );
                                                })
                                            }

                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                    )
                }
                {
                    activity.included !== null && activity.included.length !== 0 && (
                        <Grid>
                            <br />
                            <Divider />
                            <br />
                            <Typography variant="h6"> { t("poi.included") + " : " } </Typography>
                            <br />
                            <LocaleTextArray localization={ activity.localization } default_text={ activity.included } text_key={ 'included' }/>
                            {/*{*/}
                            {/*activity.included.map((inclusion, inclusion_index) => {*/}
                            {/*return(*/}
                            {/*<Grid container alignItems="center" justify="flex-start" spacing={2} key={inclusion_index}>*/}
                            {/*<Grid item>*/}
                            {/*<CheckIcon className={classes.icon} />*/}
                            {/*</Grid>*/}
                            {/*<Grid item>*/}
                            {/*<Typography variant="body1"> {inclusion} </Typography>*/}
                            {/*</Grid>*/}
                            {/*</Grid>*/}
                            {/*)*/}
                            {/*})*/}
                            {/*}*/}
                        </Grid>
                    )
                }
                {
                    activity.not_included !== undefined && activity.not_included !== null && activity.length !== 0 && (
                        <Grid>
                            <br />
                            <Divider />
                            <br />
                            <Typography variant="h6"> { t("poi.not_included") + " : " } </Typography>
                            <br />
                            <LocaleTextArray localization={ activity.localization } default_text={ activity.not_included } text_key={ 'not_included' }/>
                            {/*{*/}
                            {/*activity.not_included.map((non_inclusion, non_inclusion_index) => {*/}
                            {/*return(*/}
                            {/*<Grid container alignItems="center" justify="flex-start" spacing={2} key={non_inclusion_index}>*/}
                            {/*<Grid item>*/}
                            {/*<ClearIcon className={classes.icon} />*/}
                            {/*</Grid>*/}
                            {/*<Grid item>*/}
                            {/*<Typography variant="body1"> {non_inclusion} </Typography>*/}
                            {/*</Grid>*/}
                            {/*</Grid>*/}
                            {/*)*/}
                            {/*})*/}
                            {/*}*/}
                        </Grid>
                    )
                }
                {
                    activity.not_recommended !== undefined && activity.not_recommended !== null && (
                        <Grid>
                            <br />
                            <Divider />
                            <br />
                            <Typography variant="h6"> { t("poi.not_recommended") + " : " } </Typography>
                            <br />
                            {
                                ["cercledesvoyages", "tropicalementvotre", 'terreslointaines'].includes(quotation_code) ? (
                                    <Fragment>
                                        {
                                            (activity.short_description !== null || (activity.is_custom && localeText(current_locale.id, activity.localization, activity.short_description, 'short_description') !== '')) ? <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: (activity.is_custom && activity.short_description !== null) ? activity.short_description : localeText(current_locale.id, activity.localization, activity.short_description, 'short_description') }}/> : ''
                                        }
                                        {
                                            (activity.long_description !== null || (activity.is_custom && localeText(current_locale.id, activity.localization, activity.long_description, 'long_description') !== '')) ? <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: (activity.is_custom && activity.long_description !== null) ? activity.long_description : localeText(current_locale.id, activity.localization, activity.long_description, 'long_description') }}/> : ''
                                        }
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        {
                                            activity.long_description !== null && <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: activity.is_custom ? activity.long_description : localeText(current_locale.id, activity.localization, activity.long_description, 'long_description') }}/>
                                        }
                                        {
                                            activity.long_description === null && activity.short_description !== null && <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: activity.is_custom ? activity.short_description : localeText(current_locale.id, activity.localization, activity.short_description, 'short_description') }}/>
                                        }
                                        {
                                            activity.long_description === null && activity.short_description === null && <Typography variant="body1" align="justify"> { t("poi.no_description") } </Typography>
                                        }
                                    </Fragment>
                                )
                            }
                            {
                                manualVariant !== '' && (localeText(current_locale.id, manualVariant.localization, manualVariant.short_description, 'short_description') !== '' || localeText(current_locale.id, manualVariant.localization, manualVariant.long_description, 'long_description') !== '') &&
                            <Fragment>
                                <br />
                                {
                                    (activity.short_description !== null || localeText(current_locale.id, activity.localization, activity.short_description, 'short_description') !== '') && (activity.long_description !== null || localeText(current_locale.id, activity.localization, activity.long_description, 'long_description') !== '') &&
                                    <Divider />
                                }
                                <br />
                                <Typography variant="h6"> { localeText(current_locale.id, manualVariant.localization, manualVariant.name, 'title') + " : " } </Typography>
                                <br />
                                {
                                    ["cercledesvoyages", "tropicalementvotre", 'terreslointaines'].includes(quotation_code) ? (
                                        <Fragment>
                                            {
                                                (manualVariant.short_description !== null || (manualVariant.is_custom && localeText(current_locale.id, manualVariant.localization, manualVariant.short_description, 'short_description') !== '')) ? <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: (manualVariant.is_custom && manualVariant.short_description !== null) ? manualVariant.short_description : localeText(current_locale.id, manualVariant.localization, manualVariant.short_description, 'short_description') }}/> : ''
                                            }
                                            {
                                                (manualVariant.long_description !== null || (manualVariant.is_custom && localeText(current_locale.id, manualVariant.localization, manualVariant.long_description, 'long_description') !== '')) ? <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: (manualVariant.is_custom && manualVariant.long_description !== null) ? manualVariant.long_description : localeText(current_locale.id, manualVariant.localization, manualVariant.long_description, 'long_description') }}/> : ''
                                            }
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            {
                                                manualVariant.long_description !== null && <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: manualVariant.is_custom ? manualVariant.long_description : localeText(current_locale.id, manualVariant.localization, manualVariant.long_description, 'long_description') }}/>
                                            }
                                            {
                                                manualVariant.long_description === null && manualVariant.short_description !== null && <Typography variant="body1" align="justify" dangerouslySetInnerHTML={{ __html: manualVariant.is_custom ? manualVariant.short_description : localeText(current_locale.id, manualVariant.localization, manualVariant.short_description, 'short_description') }}/>
                                            }
                                            {
                                                manualVariant.long_description === null && manualVariant.short_description === null && <Typography variant="body1" align="justify"> { t("poi.no_description") } </Typography>
                                            }
                                        </Fragment>
                                    )
                                }
                            </Fragment>
                            }
                        </Grid>
                    )
                }
            </Grid>
        </Grid>
    );
};

export default React.memo(AboutPoi);
