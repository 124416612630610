//---- Dependencies ----//
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import { Alert, CircularProgress, Skeleton } from '@mui/material';
import { CalendarMonth } from '@mui/icons-material';
import { isNumber } from 'lodash';
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Grid from "@material-ui/core/Grid";
import Checkbox from '@material-ui/core/Checkbox';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from "@material-ui/core/Typography";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import Lock from "@material-ui/icons/LockOutlined";
import LockOpen from "@material-ui/icons/LockOpenOutlined";
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close';
import CampaignIcon from '@mui/icons-material/CampaignOutlined';
import KingBedIcon from '@mui/icons-material/KingBedOutlined';

import TransferWithinAStationOutlinedIcon from '@mui/icons-material/TransferWithinAStationOutlined';

import InitItiMap from './InitItiMap';
import clsx from "clsx";
import localeText from '../../Functions/localeText';
import { useItineraryLaunchBlockAdd } from '../../Itinerary/network/itineraryLaunchBlockAdd';
import { getBlockPackageData } from '../../Itinerary/utils/getBlockPackageData';
import { setBlocksCircuitsCache } from '../../Itinerary/redux/reducer';
import { PricePeriod } from '../../Itinerary/objects/pricePeriod';
import { AppState } from '../../../Reducers/Reducers';

const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(() => ({
    card: {
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        "maxHeight": 'calc(100vh - 95px)',
        "overflowY": 'auto'
    },
    cardPadding: {
        padding: "0 10px 10px 10px"
    },
    alignRight: {
        textAlign: "right"
    },
    alignLeft: {
        textAlign: "left"
    },
    alignCenter: {
        textAlign: "center"
    },
    pink: {
        color: "#E0368D"
    },
    bold: {
        fontWeight: "bold"
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    borderFlight: {
        border: "2px solid",
        padding: "4px 15px 4px 15px",
        borderRadius: "8px",
        fontWeight: 700,
        width: "100%",
        fontSize: "14px",
        textAlign: "center"
    },
    listContainer: {
        "&& ul": {
            "listStyleType": "'-'",
            "paddingInlineStart": "16px",
            "marginBottom": 4,
            "& li": {
                paddingLeft: 5
            }
        }
    },
    coverImg: {
        display: "block",
        width: "100%",
        objectFit: "cover",
        height: "100%"
    },
    coverImgMap: {
        width: "100%",
        height: 200
    },
    font12: {
        fontSize: 12
    },
    italic: {
        fontStyle: "italic"
    },
    justify: {
        textAlign: "justify"
    },
    customButton: {
        "background": `${main_color}`,
        //padding: "15px 40px",
        "textAlign": "center",
        "color": "#fff",
        "fontSize": 17,
        "padding": "4px 15px 4px 15px",
        "textTransform": "none",
        "&:hover": {
            background: `${main_color}CA`
        }
    },
    customButtonV: {
        "background": "#BF202D",
        //padding: "15px 40px",
        "textAlign": "center",
        "color": "#fff",
        "fontSize": 17,
        "padding": "4px 15px 4px 15px",
        "textTransform": "none",
        "&:hover": {
            background: "#BF202D20"
        }
    },
    truncText: {
        display: "-webkit-box",
        WebkitLineClamp: 4,
        WebkitBoxOrient: "vertical",
        overflow: "hidden"
    },
    floatRight: {
        float: "right"
    },
    controlLabel: {
        //"& .mui-jss-MuiFormControlLabel-label": {
        //// fontSize: 10
        //textTransform: 'capitalize'
        //},
        marginBottom: 0,
        marginRight: 0
    },
    staticPriceContainer: {
        position: 'fixed',
        bottom: 0,
        backgroundColor: 'white',
        marginLeft: '-33px',
        padding: 16,
        borderTop: '1px solid lightgray'
    },
    orangeButton: {
        backgroundColor: `${main_color}`,
        color: "white"
    },
    marginBottom40: {
        marginBottom: 40
    },
    versionAvailable: {
        width: "100%"
    },
    inputEllipsis: {
        "&& .mui-jss-MuiInputBase-formControl": {
            width: "99%",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap"
        }
    },
    lowerCase: {
        textTransform: "capitalize"
    }
}));

type RightPanelProps = {
    checkScreen: any,
    options: any,
    selectedOptions: any,
    setSelectedOptions: any,
    assurances: any,
    pricesRef: any,
    setSalePitch: any,
    dateIsAfter: any,
    versionAvailable: any,
    versionToShow: any,
    setVersionToShow: any,
    variantToShow: any,
    setVariantToShow: any,
    variantAvailable: any,
    setIsItiLoading: any,
    topContainerRef: any,
    itinerary_data: any,
    forItinerary: any,
    selectedVariant: any,
    products: any,
    isItiLoading: boolean,
    onClose?: () => void
};

const RightPanel = (props: RightPanelProps) => {
    const { checkScreen, options, selectedOptions, setSelectedOptions, assurances, pricesRef, setSalePitch, dateIsAfter, versionAvailable, versionToShow, setVersionToShow, variantToShow, setVariantToShow, variantAvailable, setIsItiLoading, topContainerRef, itinerary_data, forItinerary, selectedVariant, products, isItiLoading, onClose } = props;
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const smallScreen = useMediaQuery(theme.breakpoints.down(321));

    const itinerary_type_details_data = useSelector(store => store.itinerary_type.itinerary_type_details_data) ??
        itinerary_data;
    const language = useSelector(store => store.header.tmp_language);
    const user = useSelector(store => store.user.user);
    const current_locale = useSelector(store => store.user.current_locale);
    const lowest_price = useSelector(store => store.itinerary_type.lowest_price);
    const type = useSelector(store => store.itinerary_type.type);
    const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const circuitCache = useSelector((state: AppState) => state.itinerarySlice.blocks.circuits.cache[itinerary_type_details_data?.id]?.[versionToShow]);
    const circuitItinerary = useSelector((state: AppState) => state.itinerary_type.itinerary);
    const variant = variantToShow ?? variantAvailable?.[0];
    const circuitProducts = useSelector((state: AppState) => {
        return state.itinerarySlice.blocks.circuits.cache[itinerary_type_details_data?.id]?.[versionToShow]?.products;
    });
    const circuitProductIds = useMemo((): number[] => {
        let products = {
            accommodations: circuitProducts?.filter((item) => {
                return item.product_type === 0;
            }) ?? [],
            cars: circuitProducts?.filter((item) => {
                return item.product_type === 2;
            }) ?? [],
            assistances: circuitProducts?.filter((item) => {
                return item.product_type === 19;
            }) ?? [],
            cruises: circuitProducts?.filter((item) => {
                return item.product_type === 9;
            }) ?? [],
            ferries: circuitProducts?.filter((item) => {
                return item.product_type === 13;
            }) ?? [],
            flights: circuitProducts?.filter((item) => {
                return item.product_type === 6;
            }) ?? [],
            insurances: circuitProducts?.filter((item) => {
                return item.product_type === 8;
            }) ?? [],
            pois: circuitProducts?.filter((item) => {
                return [1, 12].includes(item.product_type);
            }) ?? [],
            trains: circuitProducts?.filter((item) => {
                return item.product_type === 5;
            }) ?? [],
            transfers: circuitProducts?.filter((item) => {
                return item.product_type === 4;
            }) ?? []
        };
        products = {
            accommodations: products.accommodations.filter((item) => {
                return !isNumber(item.variant) || item.variant === variant;
            }) ?? [],
            cars: products.cars.filter((item) => {
                return !isNumber(item.variant) || item.variant === variant;
            }) ?? [],
            pois: products.pois.filter((item) => {
                return !isNumber(item.variant) || item.variant === variant;
            }) ?? [],
            transfers: products.transfers.filter((item) => {
                return !isNumber(item.variant) || item.variant === variant;
            }) ?? [],
            flights: [],
            cruises: [],
            ferries: [],
            trains: [],
            assistances: [],
            insurances: []
        };
        return products.accommodations.map((item) => {
            return item.id;
        }).concat(
            products.cars.map((item) => {
                return item.id;
            })
        ).concat(
            products.pois.map((item) => {
                return item.id;
            })
        ).concat(
            products.transfers.map((item) => {
                return item.id;
            })
        );
    }, [variant, circuitProducts]);

    const [open, setOpen] = useState(false);
    const [desc, setDesc] = useState("");
    const [optionProducts, setOptionProducts] = useState<any[]>([]);
    const [optionsPrice, setOptionsPrice] = useState<number>(0);
    const [itinerary_markers, setItineraryMarkers] = useState<any[]>([]);
    const [circuitStartDate, setCircuitStartDate] = useState('PERIOD');
    const [loading, setLoading] = useState(false);
    const [periods, setPeriods] = useState<PricePeriod[]>([]);
    const fixedDates = useMemo(() => {
        return periods.filter((item) => {
            return window.moment.utc(item.start_date).startOf('day').isSame(
                window.moment.utc(item.end_date).startOf('day'),
                'days'
            );
        });
    }, [periods]);
    const launchBlockAdd = useItineraryLaunchBlockAdd();

    let renderScrollButton = (itinerary_type_details_data.extra !== undefined && itinerary_type_details_data.extra !== null && itinerary_type_details_data.extra.length > 0) || (itinerary_type_details_data.included_in_price !== undefined && itinerary_type_details_data.included_in_price !== null && itinerary_type_details_data.included_in_price.length > 0) || (itinerary_type_details_data.advantage !== undefined && itinerary_type_details_data.advantage !== null && itinerary_type_details_data.advantage.length > 0);
    let currency_symbol: string = "-";
    let currency_iso: string | null = '';
    let flight_included: boolean = false;
    let transfer_included: boolean = false;
    let price = null;
    if (itinerary_type_details_data !== null) {
        flight_included = itinerary_type_details_data.flight_included;
        transfer_included = itinerary_type_details_data.transfer_included;
        if (itinerary_type_details_data?.trip?.currency !== undefined && itinerary_type_details_data?.trip?.currency !== null) {
            currency_symbol = itinerary_type_details_data.trip.currency.symbol;
            currency_iso = itinerary_type_details_data.trip.currency.iso_code;
        }
        if (itinerary_type_details_data?.currency !== undefined && itinerary_type_details_data?.currency !== null) {
            currency_symbol = itinerary_type_details_data.currency.symbol;
            currency_iso = itinerary_type_details_data.currency.iso_code;
        }
        if (currency_iso !== null && currency_iso !== '') {
            if (itinerary_type_details_data.lowest_price !== undefined && itinerary_type_details_data.lowest_price !== null && lowest_price === null) {
                if (typeof itinerary_type_details_data.lowest_price !== "object") {
                    price = new Intl.NumberFormat(i18n.language, { style: 'currency', currency: currency_iso, minimumFractionDigits: 0 }).format(Math.round(itinerary_type_details_data.lowest_price + optionsPrice));
                } else {
                    price = itinerary_type_details_data.lowest_price[versionToShow] !== undefined ? new Intl.NumberFormat(i18n.language, { style: 'currency', currency: currency_iso, minimumFractionDigits: 0 }).format(Math.round(itinerary_type_details_data.lowest_price[versionToShow] + optionsPrice)) : null;
                }
            } else {
                price = new Intl.NumberFormat(i18n.language, { style: 'currency', currency: currency_iso, minimumFractionDigits: 0 }).format(Math.round(parseFloat((!isNaN(lowest_price) ? lowest_price : 0) + optionsPrice)));
            }
        }
    }
    const onChoosePackage = async () => {
        if (forItinerary) {
            if (variant) {
                setLoading(true);
                if (
                    circuitCache &&
                    await launchBlockAdd({
                        type: 'circuit',
                        circuitId: itinerary_type_details_data?.id,
                        circuitVersion: versionToShow,
                        circuitVariant: variant,
                        circuitDate: circuitStartDate,
                        circuit: circuitCache,
                        circuitSource: itinerary_type_details_data?.source ?? '',
                        tripId: itinerary_type_details_data?.trip?.id,
                        tripVersion: versionToShow,
                        steps: circuitItinerary?.map((step) => {
                            return step.id;
                        }) ?? [],
                        index: itinerary.length - 2 < 0 ?
                            0 :
                            itinerary.length - 2,
                        products: circuitProductIds
                    }) &&
                    onClose
                ) {
                    onClose();
                }
                setLoading(false);
            }
        } else {
            checkScreen();
        }
    };

    useEffect(() => {
        if (itinerary_type_details_data?.trip?.id && versionToShow && variantToShow) {
            (async () => {
                const data = await getBlockPackageData({
                    circuitId: itinerary_type_details_data.id,
                    tripId: itinerary_type_details_data.trip.id,
                    version: versionToShow,
                    contractIds: itinerary_type_details_data.contracts?.map((item: any) => {
                        return item.id
                    }) ?? [],
                    source: itinerary_type_details_data.source
                });
                setPeriods(
                    data.periods?.filter((item) => {
                        return item.trip_version === versionToShow &&
                            item.variant === variantToShow &&
                            window.moment.utc(item.start_date).startOf('day').isSameOrAfter(
                                window.moment.utc().startOf('day'),
                                'day'
                            );
                    }) ?? []
                );
                dispatch(
                    setBlocksCircuitsCache({
                        circuitId: itinerary_type_details_data.id,
                        source: itinerary_type_details_data.source,
                        versionId: versionToShow,
                        data
                    })
                );
            })();
        }
    }, [
        itinerary_type_details_data?.id,
        versionToShow,
        variantToShow
    ]);

    useEffect(() => {
        let current_option_products = products.filter((product: any) => selectedVariant.includes(product.id) && product.is_optional);
        let current_option_products_price = current_option_products.reduce((acc: any, product: any) => {
            console.log('product:', product);
            let current_price = product.product_type !== 2 ? product.prices[getPrice(product.prices)].selling_price / (product.group_passenger !== null ? product.group_passenger.travelers.length : 1) : product.prices[getPrice(product.prices)].selling_price;
            if (product.product_type === 6) {
                current_price += parseFloat(product.taxes);
            }
            return acc + parseFloat(current_price);
        }, 0);
        setOptionsPrice(current_option_products_price);
        setOptionProducts(current_option_products);
    }, [selectedVariant]);
    useEffect(() => {
        if (itinerary_type_details_data.map_picture === null) {
            InitItiMap(itinerary_type_details_data, circuitItinerary, itinerary_markers, setItineraryMarkers);
        }
    }, []);
    const handleChange = (id: number) => () => {
        let options_arr = selectedOptions.slice();
        if (options_arr.includes(id)) {
            options_arr = options_arr.filter((el) => {
                return el !== id;
            });
        } else {
            options_arr.push(id);
        }
        setSelectedOptions(options_arr);
    };

    const getPrice = (prices: any) => {
        for (let i = 0; i < prices.length; i++) {
            if (user !== null && user.client_full.type !== 2) {
                if (prices[i].master_price === true) {
                    return i;
                }
            } else if (prices[i].master_price === false && prices[i].master_price === false && prices[i].master_price === false && prices[i].master_price === false) {
                return i;
            }
        }
        return 0;
    };
    const scrollToTable = () => {
        pricesRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
        });
    };
    const handleClose = (info: any) => {
        if (!open) {
            setDesc(info.custom_product.short_description);
        } else {
            setDesc("");
        }
        setOpen(!open);
    };
    const handleChangeVersion = (e: any) => {
        topContainerRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
        });
        setVersionToShow(e.target.value);
        setIsItiLoading(true);
    };
    const handleChangeVariant = (e: any) => {
        topContainerRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
        });
        setVariantToShow(e.target.value);
    };
    return (
        <CardContent className={clsx(classes.card, { [classes.marginBottom40]: fullScreen })}>
            <Grid container direction={"column"}>
                {
                    versionAvailable !== undefined && versionAvailable.length > 1 && (
                        <Grid item className={classes.versionAvailable}>
                            <Typography>
                                <CampaignIcon style={{ verticalAlign: 'sub', marginRight: 4, color: `${main_color}` }} />
                                <span className={classes.genericText}>{t<string>("itineraryType.version_available", { type: type })}</span>
                            </Typography>
                            {
                                isItiLoading ? (
                                    <Skeleton variant="text" width={'100%'} height={50} />
                                ) : (
                                    <FormControl style={{ width: '100%' }}>
                                        <Select
                                            value={versionToShow}
                                            onChange={handleChangeVersion}
                                            className={clsx(classes.inputEllipsis)}
                                            variant={'outlined'}
                                            displayEmpty
                                        >
                                            {
                                                versionAvailable.map((version: any, version_index: number) => {
                                                    return (
                                                        <MenuItem key={version_index} value={version.id}>{version.name}</MenuItem>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                )
                            }
                        </Grid>
                    )
                }
                {
                    variantAvailable !== undefined && variantAvailable.length > 1 && (
                        <Grid item style={{ marginTop: 10, width: '100%' }}>
                            <Fragment>
                                <Typography>
                                    <KingBedIcon style={{ verticalAlign: 'sub', marginRight: 4, color: `${main_color}` }} />
                                    <span className={classes.genericText}>{t<string>("itineraryType.variant_available")}</span>
                                </Typography>
                                {
                                    isItiLoading ? (
                                        <Skeleton variant="text" width={'100%'} height={50} />
                                    ) : (
                                        <FormControl style={{ width: '100%' }}>
                                            <Select
                                                value={variantToShow}
                                                onChange={handleChangeVariant}
                                                className={clsx(classes.inputEllipsis)}
                                                variant={'outlined'}
                                                displayEmpty
                                            >
                                                {
                                                    variantAvailable.map((nb: number, variant_index: number) => {
                                                        let current_variant = itinerary_type_details_data.variant_name.find((variant: any) => variant.id === nb && variant.version === versionToShow);
                                                        return (
                                                            <MenuItem key={variant_index} value={nb}>{current_variant !== undefined && current_variant.name !== undefined && current_variant.name[current_locale] !== undefined ? current_variant.name[current_locale] : t<string>("itineraryType.variant") + " " + (nb)}</MenuItem>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    )
                                }
                            </Fragment>
                        </Grid>
                    )
                }
                {
                    forItinerary &&
                    fixedDates.length > 0 &&
                    <>
                        <Grid item style={{ marginTop: 10, width: '100%' }}>
                            <Fragment>
                                <Typography>
                                    <CalendarMonth style={{ verticalAlign: 'sub', marginRight: 4, color: `${main_color}` }} />
                                    <span className={classes.genericText}>{t('global.date')}</span>
                                </Typography>
                                {
                                    isItiLoading ? (
                                        <Skeleton variant="text" width={'100%'} height={50} />
                                    ) :
                                        <FormControl style={{ width: '100%' }}>
                                            <Select
                                                variant="outlined"
                                                value={circuitStartDate}
                                                onChange={(event) => setCircuitStartDate(event.target.value as string)}
                                            >
                                                {
                                                    fixedDates.map((date) => (
                                                        <MenuItem key={date.id} value={date.start_date}>
                                                            {
                                                                window.moment.utc(
                                                                    date.start_date
                                                                ).format('L')
                                                            }
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                }
                            </Fragment>
                        </Grid>
                    </>
                }
                <Grid item container direction={"row"} justify={"space-between"} alignItems={"center"} style={{ marginTop: 20 }}>
                    <Grid item xs={12} className={classes.alignLeft}>
                        {
                            itinerary_type_details_data !== undefined && itinerary_type_details_data !== null && localeText(current_locale, itinerary_type_details_data.localization, itinerary_type_details_data.sale_pitch, 'sale_pitch') !== "" && (
                                <InfoOutlinedIcon style={{ cursor: "pointer", color: `${main_color}`, verticalAlign: "middle" }} onClick={() => {
                                    setSalePitch(true);
                                }} />
                            )
                        }
                    </Grid>
                    {
                        optionProducts.length > 0 && (
                            <Grid item xs={12} style={{ marginTop: 10 }}>
                                {/* <p style={{ fontSize: 15, fontWeight: "bold", marginBottom: 2 }}>{t<string>("itineraryType.to_reserve")}</p> */}
                                <Grid container direction={"column"}>
                                    {
                                        optionProducts.map((option: any, option_index: number) => {
                                            console.log('optionProducts:', optionProducts);
                                            let name = "/";
                                            let desc = '';
                                            if (option.product_type === 0) {
                                                let is_custom = false;
                                                if (option.hotel !== undefined && option.hotel !== null && option.hotel[0].potential_custom_providers !== undefined && option.hotel[0].potential_custom_providers !== null) {
                                                    for (let i = 0; i < option.hotel[0].potential_custom_providers.length; i++) {
                                                        if (option.hotel[0].potential_custom_providers[i].provider_id === option.provider) {
                                                            is_custom = true;
                                                        }
                                                    }
                                                }
                                                if (is_custom) {
                                                    desc = `${option.hotel[0].name} - ${t("global.room")} ${option.description} : ${option.category}`;
                                                } else if (!option.is_manual) {
                                                    desc = `${option.hotel[0].name} - ${option.description}`;
                                                } else {
                                                    name = `${option.name}`
                                                }
                                            } else if (option.product_type === 2) {
                                                let tmp_name = (option.name.toLowerCase()).split(" or similar");
                                                name = tmp_name[0].charAt(0).toUpperCase() + tmp_name[0].slice(1) + " " + t("cars.or_similiar");
                                            } else if (option.product_type === 4) {
                                                name = `${option.custom_product !== undefined ? option.custom_product.title : option.name}`;
                                            } else if (option.name !== undefined && option.name !== null) {
                                                name = option.name;
                                            } else if (option.description !== undefined && option.description !== null) {
                                                name = option.description;
                                            } else {
                                                if (option.is_custom === true) {
                                                    name = option.custom_product.title;
                                                }
                                                if (option.product_type === 6) {
                                                    if (option.segments !== undefined && option.segments !== null) {
                                                        name = option.segments[0].marketing_airline.commercial_name + " : " + (option.segments[0].origin !== null && option.segments[0].destination !== null ? option.segments[0].origin.airport_code + " - " + option.segments[0].destination.airport_code : option.segments[0].origin_station.station_code + " - " + option.segments[0].destination_station.station_code);
                                                    }
                                                    if (option.outbounds !== undefined && option.outbounds !== null) {
                                                        name = option.outbounds[0]?.legs[0]?.marketing_airline?.commercial_name + " : " + (option.outbounds[0]?.legs[0]?.origin !== null && option.outbounds[0]?.legs[0]?.destination !== null ? option.outbounds[0]?.legs[0]?.origin?.airport_code + " - " + option.outbounds[0]?.legs[0]?.destination?.airport_code : option.outbounds[0]?.legs[0]?.origin_station?.station_code + " - " + option.outbounds[0]?.legs[0]?.destination_station?.station_code);
                                                    }
                                                }
                                            }
                                            if (option.is_custom) {
                                                if (option.product_type === 4) {
                                                    desc = `${option.origin_name} -> ${option.destination_name}`;
                                                } else if (option.product_type === 2) {
                                                    desc = `${option.station_name_pickup} -> ${option.station_name_return}`;
                                                } else if (option.product_type === 11) {
                                                    let destination = option.start_destination.data.name !== undefined ? option.start_destination.data.name.split(",") : option.start_destination.data.international_name.split(",");
                                                    desc = `${destination[0]}`;
                                                }
                                            } else if (option.product_type === 2) {
                                                desc = `${option.station_name_pickup} -> ${option.station_name_return}`;
                                            } else if (option.product_type === 4) {
                                                desc = `${option.station_name_pickup} -> ${option.station_name_return}`;
                                            }
                                            name = `- ${name} ${desc}`;
                                            return (
                                                <Grid item key={option_index}>
                                                    <Grid container direction={"column"} spacing={0}>
                                                        {
                                                            (option_index === 0 || (optionProducts[option_index - 1] !== undefined && option.product_type !== optionProducts[option_index - 1].product_type)) && (
                                                                <Grid item style={{ marginTop: 10 }}>
                                                                    {
                                                                        [0, 7].includes(option.product_type) && (
                                                                            <p style={{ fontSize: 15, fontWeight: "bold", marginBottom: 2 }}>{`${t<string>("cart.accommodations")} : `}</p>
                                                                        )
                                                                    }
                                                                    {
                                                                        option.product_type === 4 && (
                                                                            <p style={{ fontSize: 15, fontWeight: "bold", marginBottom: 2 }}>{`${t<string>("apps_navigation.transfer")} : `}</p>
                                                                        )
                                                                    }
                                                                    {
                                                                        option.product_type === 2 && (
                                                                            <p style={{ fontSize: 15, fontWeight: "bold", marginBottom: 2 }}>{`${t<string>("cars.main_title")} : `}</p>
                                                                        )
                                                                    }
                                                                    {
                                                                        [11, 12, 1].includes(option.product_type) && (
                                                                            <p style={{ fontSize: 15, fontWeight: "bold", marginBottom: 2 }}>{`${t<string>("providers.activities")} : `}</p>
                                                                        )
                                                                    }
                                                                    {
                                                                        option.product_type === 6 && (
                                                                            <p style={{ fontSize: 15, fontWeight: "bold", marginBottom: 2 }}>{`${t<string>("apps_navigation.flight")} : `}</p>
                                                                        )
                                                                    }
                                                                </Grid>
                                                            )
                                                        }
                                                        <Grid item className={`${classes.font12} ${classes.justify} ${classes.truncText}`} style={{ marginLeft: 30 }}>
                                                            {
                                                                // (option.product_type === 2 || option.product_type === 4) && (
                                                                // <Fragment>
                                                                // <span>{`${t("cars.categories." + ((option.product_type === 2 ?  option.vehicle_category : option.custom_product.vehicle_category).toLowerCase()).replace(" ", "_"))} :`}</span>
                                                                // <br/>
                                                                // </Fragment>
                                                                // )
                                                            }
                                                            <span>{name}</span>
                                                            {
                                                                [11, 12, 1].includes(option.product_type) && option.custom_product !== undefined && option.custom_product !== null && option.custom_product.short_description !== null && option.custom_product.short_description !== "" && (
                                                                    <Button className={`${classes.genericText} ${classes.lowerCase}`} onClick={() => {
                                                                        handleClose(option);
                                                                    }} variant={"outlined"} style={{ float: "right", marginBottom: 5 }}>{t<string>("poi.see_more")}</Button>
                                                                )
                                                            }
                                                        </Grid>
                                                        {
                                                            option.prices[getPrice(option.prices)].selling_price !== undefined && (
                                                                <Grid item style={{ textAlign: "right" }}>
                                                                    <span className={`${classes.alignRight} ${classes.floatRight}`}>
                                                                        {Intl.NumberFormat(i18n.language, { style: "currency", currency: currency_iso, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(option.product_type !== 2 ? Math.ceil((option.prices[getPrice(option.prices)].selling_price / (option.group_passenger !== null ? option.group_passenger.travelers.length : 1))) : Math.ceil(option.prices[getPrice(option.prices)].selling_price) + (option.product_type === 6 ? parseFloat(option.taxes) : 0))} {option.product_type !== 2 ? '/ pers' : ''}
                                                                    </span>
                                                                </Grid>
                                                            )
                                                        }
                                                    </Grid>
                                                    {/*<div className={`${classes.font12} ${classes.justify} ${classes.truncText}`}>{desc}</div> */}
                                                </Grid>
                                            );
                                        })
                                    }
                                </Grid>
                            </Grid>
                        )
                    }
                    {
                        price !== undefined && price !== null && type !== 'iti type' && (
                            <Fragment>
                                {
                                    isItiLoading ? (
                                        <Skeleton variant="text" width={'100%'} height={50} />
                                    ) : (
                                        <Fragment>
                                            <Grid item className={`${classes.bold}`}>
                                                {`${t<string>("itineraryType.price_vat")} ${t<string>('flight_search.price_from')}`}
                                            </Grid>
                                            <Grid item className={`${classes.bold}`} style={{ fontSize: 20 }}>
                                                {price}
                                            </Grid>
                                        </Fragment>
                                    )
                                }
                            </Fragment>
                        )
                    }
                </Grid>
                {
                    quotation_code === "verdie" && (
                        <Grid item className={classes.alignRight} >
                            <div style={{ fontSize: 12 }}>{t<string>("itineraryType.verdie_label")}</div>
                        </Grid>
                    )
                }
                {
                    (dateIsAfter || renderScrollButton) && (
                        <Grid item className={classes.alignRight} >
                            <div style={{ fontSize: 12, textDecoration: "underline", cursor: "pointer" }} onClick={scrollToTable}>{t<string>("itineraryType.see_prices")}</div>
                        </Grid>
                    )
                }
                {
                    itinerary_type_details_data.category !== undefined && itinerary_type_details_data.category !== null && (
                        <Grid item className={classes.alignRight}>
                            <span className={`${classes.bold}`}>{itinerary_type_details_data.category.name}</span>
                        </Grid>
                    )
                }
                <Grid item container direction={"row"} alignItems={"center"} spacing={2}>
                    <Grid item>
                        <FlightTakeoffIcon style={{ verticalAlign: "bottom", marginRight: 7, color: `${main_color}` }} />
                        <span className={classes.bold}>{(flight_included === true) ? t<string>("itineraryType.flight_included") : t<string>("itineraryType.flight_not_included")} </span>
                    </Grid>
                    <Grid item>
                        <TransferWithinAStationOutlinedIcon style={{ verticalAlign: "bottom", marginRight: 7, color: `${main_color}` }} />
                        <span className={classes.bold}>{(transfer_included === true) ? t<string>("itineraryType.transfers_included") : t<string>("itineraryType.transfers_not_included")} </span>
                    </Grid>
                    <Grid item>
                        {
                            (!itinerary_type_details_data.lock_agency && !itinerary_type_details_data.product_locked) ? (
                                <Fragment>
                                    <LockOpen style={{ verticalAlign: "sub", color: `${main_color}` }} />
                                    <span className={classes.bold}>{t<string>('itineraryType.unlocked-trip')}</span>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Lock style={{ verticalAlign: "sub", color: `${main_color}` }} />
                                    <span className={classes.bold}>{t<string>('itineraryType.locked-trip')}</span>
                                </Fragment>
                            )
                        }
                    </Grid>
                </Grid>
                {/* <Grid item container direction={"row"} justify={"space-between"} alignItems={"center"}>
                    <Grid item className={classes.bold}>{itinerary_type_details_data.duration_day !== null ? (itinerary_type_details_data.duration_day > 1 ? itinerary_type_details_data.duration_day + " " + t("global.days") : itinerary_type_details_data.duration_day + " " + t("global.day")) : " " } / { itinerary_type_details_data.duration_night !== null ? (itinerary_type_details_data.duration_night > 1 ? itinerary_type_details_data.duration_night + " " + t("itinerary.nights") : itinerary_type_details_data.duration_night + " " + t("itinerary.nights")) : " " }</Grid>
                </Grid> */}
                {
                    itinerary_type_details_data.board !== undefined && itinerary_type_details_data.board !== null && itinerary_type_details_data.board !== null && (
                        <Grid item className={classes.alignRight}>
                            <span>
                                {itinerary_type_details_data.board.name}
                            </span>
                        </Grid>
                    )
                }
                {
                    assurances.length > 0 && (
                        <Grid item style={{ marginTop: 10 }}>
                            <p style={{ fontWeight: "bold", marginBottom: 2 }}>{t<string>("itineraryType.optional_offers")}</p>
                            <Grid container direction={"column"} spacing={0}>
                                {
                                    assurances.map((assurance: any, assurance_index: number) => {
                                        let is_checked = selectedOptions.includes(assurance.id);
                                        return (
                                            <Grid item key={assurance_index}>
                                                <Grid container direction={"column"}>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            className={classes.controlLabel}
                                                            control={
                                                                <Checkbox
                                                                    checked={is_checked}
                                                                    onChange={handleChange(assurance.id)}
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                />
                                                            }
                                                            label={assurance.name}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item className={`${classes.alignRight}`}>
                                                    <span className={`${classes.alignRight} ${classes.pink} ${classes.floatRight}`}>
                                                        {language !== null ? (Intl.NumberFormat(language, { style: "currency", currency: currency_iso, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(Math.ceil((assurance.group_passenger !== null ? assurance.prices[getPrice(assurance.prices)].selling_price / assurance.group_passenger.travelers.length : assurance.prices[getPrice(assurance.prices)].selling_price)))) : Math.ceil((assurance.group_passenger !== null ? assurance.prices[getPrice(assurance.prices)].selling_price / assurance.group_passenger.travelers.length : assurance.prices[getPrice(assurance.prices)].selling_price)) + " " + currency_symbol} / pers
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        );
                                    })
                                }
                            </Grid>
                        </Grid>
                    )
                }
                {
                    !isItiLoading &&
                    forItinerary &&
                    periods.length === 0 &&
                    <Alert severity="info" sx={{ marginTop: 2 }}>
                        {t('cart-material.package-has-no-periods')}
                    </Alert>
                }
                {
                    fullScreen && (
                        <Fragment>
                            {
                                isItiLoading ? (
                                    <Skeleton variant="text" width={'100%'} height={50} />
                                ) : (
                                    <Grid container className={classes.staticPriceContainer} direction={"column"} justify={"center"}>
                                        <Grid item>
                                            <Button
                                                variant={"contained"}
                                                startIcon={
                                                    loading &&
                                                    <CircularProgress size="1em" />
                                                }
                                                className={`${['visiteurs'].includes(quotation_code) ? classes.customButtonV : classes.customButton}`}
                                                fullWidth={true}
                                                onClick={onChoosePackage}
                                                disabled={loading}
                                            >
                                                {
                                                    forItinerary ?
                                                        t('itinerary.add-to-itinerary') :
                                                        t("itineraryType.choose_package")
                                                }
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Fragment>
                    )
                }
                {
                    !fullScreen && (
                        <Fragment>
                            {
                                isItiLoading ? (
                                    <Skeleton variant="text" width={'100%'} height={50} />
                                ) : (
                                    <Grid item className={classes.alignRight} style={{ marginTop: 10 }}>
                                        <Button
                                            variant={"contained"}
                                            startIcon={
                                                loading &&
                                                <CircularProgress size="1em" />
                                            }
                                            fullWidth
                                            color={"primary"}
                                            className={`${['visiteurs'].includes(quotation_code) ? classes.customButtonV : classes.customButton}`}
                                            onClick={onChoosePackage}
                                            disabled={loading}
                                        >
                                            {
                                                forItinerary ?
                                                    t('itinerary.add-to-itinerary') :
                                                    t("itineraryType.choose_package")
                                            }
                                        </Button>
                                    </Grid>
                                )
                            }
                            {/* <Grid item className={classes.alignCenter} style={{ marginTop: 10 }}>
                                <Button variant={"contained"} fullWidth color={"primary"} className={`${['visiteurs'].includes(quotation_code) ? classes.customButtonV : classes.customButton}`} onClick={checkScreen} >{t<string>("itineraryType.choose_package")}</Button>
                            </Grid> */}
                            {
                                // (dateIsAfter || renderScrollButton) && (
                                //     <Grid item className={classes.alignRight} style={{ marginTop: 10 }}>
                                //         <div style={{ fontSize: 12, textDecoration: "underline", cursor: "pointer" }} onClick={scrollToTable}>{t<string>("itineraryType.see_prices")}</div>
                                //     </Grid>
                                // )
                            }
                        </Fragment>
                    )
                }
            </Grid>
            <Dialog open={open} onClose={handleClose} PaperProps={{ style: { borderRadius: fullScreen ? 0 : 16 } }} fullScreen={fullScreen} maxWidth={"md"} fullWidth disableScrollLock>
                <DialogTitle>
                    <Grid container>
                        <Grid item style={{ position: "absolute", top: 9, right: 6 }}>
                            <IconButton edge={"start"} onClick={handleClose}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <div className={`${classes.genericText} ${classes.justify}`} dangerouslySetInnerHTML={{ __html: desc }} />
                </DialogContent>
            </Dialog>
        </CardContent>
    );
};
export default RightPanel;
