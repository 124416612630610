import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
    Alert,
    Box,
    Button,
    CardContent,
    Chip,
    Collapse,
    Divider,
    Grid,
    IconButton,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import {
    AcUnit,
    ArrowRightAltOutlined,
    BuildCircle,
    CardGiftcard,
    CloudCircle,
    CloudOff,
    DarkModeOutlined,
    DateRangeOutlined,
    Edit,
    ErrorOutline,
    HotelOutlined,
    KingBedOutlined,
    ListAlt,
    LocalParking,
    PanToolOutlined,
    PeopleOutlineOutlined,
    PlaceOutlined,
    Pool,
    ReportProblemOutlined,
    StarOutline,
    Wifi
} from "@mui/icons-material";
import { chunk, groupBy, isNumber } from "lodash";
import axios from "axios";
import { CartProductCard, CartProductCardProps } from "./CartProductCard";
import { CartProductIconText } from "./CartProductIconText";
import { CartAccommodationCardDetails } from "./CartAccommodationCardDetails";
import { CartManualAccommodationCardDetails } from "./CartManualAccommodationCardDetails";
import { CartConstructionProductsTableItemMargin } from "./CartConstructionProductsTableItemMargin";
import { CartConstructionProductsTableItemStatus } from "./CartConstructionProductsTableItemStatus";
import { CartProductCardName } from "./CartProductCardName";
import { CartClientTimeModal } from "./CartClientTimeModal";
import { CartProductCardProviderLogo } from "./CartProductCardProviderLogo";
import { getAccommodationPictures } from "./utils/getAccommodationPictures";
import { findProductItineraryError } from "./utils/findProductItineraryError";
import { findPictureUrl } from "./utils/findPictureUrl";
import { insertDivider } from "./utils/insertDivider";
import { useGetPrice } from "./utils/getPrice";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";
import { AccommodationCart } from "../Itinerary/objects/accommodationCart";
import { Currency } from "../../Reducers/objects/currency";
import { ManualProductAccommodationCart } from "../Itinerary/objects/manualProductAccommodationCart";
import { StatusBooking } from "../Itinerary/objects/statusBooking";
import { TravelerGroup } from "../../Reducers/objects/travelerGroup";
import { AppState } from "../../Reducers/Reducers";
import { isProductPackaged } from "./utils/isProductPackaged";

type Props = ({
    type: 'accommodation',
    accommodation: AccommodationCart,
    accommodationsList: (
        (
            {
                is_manual: true
            } & ManualProductAccommodationCart
        ) | (
            {
                is_manual?: false
            } & AccommodationCart
        )
    )[],
} | {
    type: 'manual',
    accommodation: ManualProductAccommodationCart
}) & Pick<CartProductCardProps, 'alwaysOpen'>

const DEFAULT_PICTURE = '/Img/hotel.png';

export const CartAccommodationCard = withRouter<Props>(
    function CartAccommodationCard(props): JSX.Element | null {
        const { t, i18n } = useTranslation();
        const dispatch = useDispatch();
        const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
            return item.language_code === i18n.language;
        })?.id ?? 1);
        const language = useSelector((state: AppState) => state.header.tmp_language);
        const providers = useSelector((state: AppState) => state.trip.providers);
        const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
        const manualProviders = useSelector((state: AppState) => state.trip.manual_providers);
        const traveler_groups = useSelector((state: AppState) => state.trip.traveler_groups);
        const trip = useSelector((state: AppState) => state.trip.data_trip);
        const tripEndDate = useSelector((state: AppState) => state.trip.end_date);
        const user = useSelector((state: AppState) => state.user?.user);
        const [openMargin, setOpenMargin] = useState(false);
        const [editTime, setEditTime] = useState(false);
        const [footerIsTooLarge, setFooterIsTooLarge] = useState(false);
        const groups = useMemo(() => {
            if (props.type === 'accommodation') {
                let tmp_groups: TravelerGroup[] = [];
                let tmp_travelers: number[] = [];
                if (!((props.accommodation.booking_status && props.accommodation.booking_status.status_booking === 'CANCELLED') || props.accommodation.booking_process_state === 'CANCEL')) {
                    for (let i = 0; i < traveler_groups.length; i++) {
                        if (traveler_groups[i]!.default) {
                            tmp_groups.push({ ...traveler_groups[i]! });
                            tmp_travelers = tmp_travelers.concat(traveler_groups[i]!.travelers);
                        }
                    }
                }
                props.accommodation.rooms.map(room => {
                    for (let i = 0; i < tmp_groups.length; i++) {
                        if (tmp_groups[i]!.id === room.group_passenger.id) {
                            tmp_groups.splice(i, 1);
                            break;
                        }
                    }
                    for (let i = 0; i < room.group_passenger.travelers.length; i++) {
                        if (tmp_travelers.includes(room.group_passenger.travelers[i]!)) {
                            tmp_travelers.splice(tmp_travelers.indexOf(room.group_passenger.travelers[i]!), 1);
                        }
                    }
                    if (tmp_travelers.length === 0) {
                        tmp_groups = [];
                    }
                });
                if (props.accommodationsList) {
                    props.accommodationsList.map((other_accommodation) => {
                        if (other_accommodation.id !== props.accommodation.id && !other_accommodation.is_manual) {
                            if (
                                other_accommodation.is_displayed &&
                                props.accommodation.hotel[0]?.id === other_accommodation.hotel[0]?.id &&
                                props.accommodation.start_date === other_accommodation.start_date &&
                                props.accommodation.end_date === other_accommodation.end_date &&
                                props.accommodation.on_request === other_accommodation.on_request &&
                                props.accommodation.is_optional === other_accommodation.is_optional &&
                                props.accommodation.variant === other_accommodation.variant &&
                                (
                                    (props.accommodation.booking_status === null && other_accommodation.booking_status === null) ||
                                    (
                                        props.accommodation.booking_status &&
                                        other_accommodation.booking_status &&
                                        (
                                            (
                                                props.accommodation.booking_status.status_booking !== 'CANCELLED' &&
                                                other_accommodation.booking_status.status_booking !== 'CANCELLED' &&
                                                props.accommodation.booking_process_state !== 'CANCEL' &&
                                                other_accommodation.booking_process_state !== 'CANCEL'
                                            ) ||
                                            (
                                                props.accommodation.booking_status.status_booking === 'CANCELLED' &&
                                                other_accommodation.booking_status.status_booking === 'CANCELLED'
                                            )
                                        )
                                    )
                                )
                            ) {
                                other_accommodation.rooms.map(room => {
                                    for (let i = 0; i < tmp_groups.length; i++) {
                                        if (tmp_groups[i]!.id === room.group_passenger.id) {
                                            tmp_groups.splice(i, 1);
                                            break;
                                        }
                                    }
                                    for (let i = 0; i < room.group_passenger.travelers.length; i++) {
                                        if (tmp_travelers.includes(room.group_passenger.travelers[i]!)) {
                                            tmp_travelers.splice(tmp_travelers.indexOf(room.group_passenger.travelers[i]!), 1);
                                        }
                                    }
                                    if (tmp_travelers.length === 0) {
                                        tmp_groups = [];
                                    }
                                });
                            }
                        }
                    });
                }
                return tmp_groups;
            }
            return [];
        }, [props.type, props.accommodation]);
        const pictures = useMemo(() => {
            if (props.accommodation.hide_pictures) {
                return [DEFAULT_PICTURE];
            }
    
            if (props.type === 'accommodation') {
                const pictures = getAccommodationPictures(props.accommodation);

                return pictures.length > 0 ?
                    pictures.map((picture) => {
                        return findPictureUrl(picture);
                    }).slice(0, 5) :
                    [DEFAULT_PICTURE];
            } else if ((props.accommodation.overload_pictures?.length ?? 0) > 0) {
                return props.accommodation.overload_pictures?.map((item) => {
                    return [
                        item.url,
                        item.thumbnail_big,
                        item.thumbnail_medium,
                        item.thumbnail_little,
                        DEFAULT_PICTURE
                    ].find((item) => {
                        return (item?.length ?? 0) > 0; 
                    }) ?? '';
                }) ?? [];
            } else if (props.accommodation.picture) {
                return [
                    [
                        props.accommodation.picture.url,
                        props.accommodation.picture.thumbnail_big,
                        props.accommodation.picture.thumbnail_medium,
                        props.accommodation.picture.thumbnail_little,
                        DEFAULT_PICTURE
                    ].find((item) => {
                        return (item?.length ?? 0) > 0; 
                    }) ?? ''
                ];
            }
            return [DEFAULT_PICTURE];
        }, [props.type, props.accommodation]);
        const provider = useMemo(() => {
            return providers.concat(manualProviders).find((item) => {
                return item.provider.id === props.accommodation.provider;
            })?.provider;
        }, [props.accommodation, providers, manualProviders]);
        const address = useMemo(() => {
            if (props.type === 'accommodation') {
                let address = '';
                const info = props.accommodation.hotel[0];
                if (info?.city_name) {
                    address = info.city_name + (info.destination_name && info.destination_name !== info.city_name ? `, ${info.destination_name}` : '');
                } else if (info?.destination_name) {
                    address = info.destination_name;
                }
                return address;
            }
            const stepDestination = props.accommodation.start_destination?.data.localization.find((item) => {
                return item.locale === locale;
            })?.name ?? props.accommodation.start_destination?.data.international_name ?? '';
            return stepDestination.length > 0 ?
                `${props.accommodation.address ?? ''} (${stepDestination})` :
                props.accommodation.address ?? '';
        }, [props.accommodation]);
        const { found, iti_error } = useMemo(() => {
            if (props.type === 'accommodation') {
                return findProductItineraryError(
                    'accommodation',
                    {
                        product: props.accommodation,
                        itinerary,
                        stackInfos: trip?.stack_info ?? null
                    }
                );
            }
            const result = findProductItineraryError(
                'manual',
                {
                    product: props.accommodation,
                    itinerary,
                    stackInfos: trip?.stack_info ?? null
                }
            );
            return result ?? { found: false, iti_error: null };
        }, [
            itinerary,
            props.accommodation,
            trip
        ]);

        const footerNode = useRef<HTMLDivElement | null>(null);
        const footerNodeFirstChild = useRef<HTMLDivElement | null>(null);
        const footerNodeSecondChild = useRef<HTMLDivElement | null>(null);
        const getPrice = useGetPrice();
        const totalCost = props.type === 'accommodation' ?
            props.accommodation.rooms?.reduce(
                (prev, current) => {
                    const price = getPrice(current.prices);

                    const additional_products_price = current.additional_products?.reduce(
                        (prev, current) => {
                            const price = getPrice(current.prices);
                            return {
                                cost: prev.cost + price.cost,
                                currency: price.currency
                            };
                        },
                        { cost: 0, currency: undefined } as {cost: number, currency: Currency | undefined}
                    );
                    return {
                        cost: prev.cost + price.cost + (additional_products_price ? additional_products_price.cost : 0),
                        currency: price.currency
                    };
                },
                { cost: 0, currency: undefined } as {cost: number, currency: Currency | undefined}
            ) :
            getPrice(props.accommodation.prices);
        const variantName = props.accommodation.variant &&
            !isNumber(props.accommodation.variant) ?
            props.accommodation.variant.localization?.find((item) => {
                return item.locale === locale;
            })?.title ?? props.accommodation.variant.name :
            null;
        const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
    
        const onShowBookingDetail = () => {
            dispatch({ type: 'CART_TOGGLE_BOOKING_DETAIL', payload: props.accommodation });
        };

        const onCreateResizeObserver = () => {
            if (footerNode.current && footerNodeFirstChild.current && footerNodeSecondChild.current) {
                const observer = new ResizeObserver(() => {
                    if (Math.abs(footerNodeFirstChild.current!.offsetLeft - footerNodeSecondChild.current!.offsetLeft) < 30) {
                        setFooterIsTooLarge(true);
                    } else {
                        setFooterIsTooLarge(false);
                    }
                });
                observer.observe(footerNode.current);
                observer.observe(footerNodeFirstChild.current);
                observer.observe(footerNodeSecondChild.current);
                return () => observer.disconnect();
            }
        };

        const confirmTravelerModification = () => {
            const { headers } = CheckBeforeRequest();
            for (const room of props.accommodation.rooms ?? []) {
                axios({
                    method: 'PATCH',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`,
                    data: {
                        traveler_modification: null
                    }
                }).then((response) => {
                    dispatch({ type: 'ACCOMMODATION_EDIT_CART_BY_ID', payload: response.data });
                }).catch((error) => {
                    console.log(error);
                });
            }
        };

        const confirmTravelerModificationManual = () => {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${props.accommodation.id}/`,
                data: {
                    traveler_modification: null
                }
            }).then(response => {
                dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
            }).catch(error => {
                console.log(error);
            });
        };

        const onGoToCard = () => {
            if (props.type === 'accommodation') {
                let current_groups: any[] = [];
                let traveler_number = 0;
                props.accommodation.rooms?.map(room => {
                    current_groups.push(room.group_passenger);
                    traveler_number += room.group_passenger.travelers.length;
                });
                dispatch({
                    type: 'ACCOMMODATION_GO_TO_CARD',
                    payload: {
                        start_date: props.accommodation.start_date,
                        end_date: props.accommodation.end_date,
                        current_groups: current_groups,
                        traveler_number: traveler_number
                    }
                });
                itinerary.map((step) => {
                    if (step.destination && props.accommodation.start_destination && step.destination.id === props.accommodation.start_destination.id) {
                        if (
                            window.moment.utc(props.accommodation.start_date).isSameOrAfter(
                                window.moment.utc(step.start_date),
                                'd'
                            ) && (
                                window.moment.utc(props.accommodation.end_date).isValid() ?
                                    window.moment.utc(props.accommodation.end_date) :
                                    window.moment.utc(props.accommodation.start_date)
                            ).isSameOrBefore(window.moment.utc(step.end_date), 'd')
                        ) {
                            const bounds = JSON.parse(step.destination.data?.bounds?.replace(/'/g, '"') ?? '{}');
                            dispatch({
                                type: "MATERIAL_SET_ACTIVE_DESTINATION",
                                payload: {
                                    active: step.id,
                                    bounds: {
                                        north: bounds.north ?? 0,
                                        east: bounds.east ?? 0,
                                        south: bounds.south ?? 0,
                                        west: bounds.west ?? 0
                                    },
                                    zoom: step.destination?.data?.zoom_level ?? 4,
                                    start_date: step.start_date.split('T')[0],
                                    end_date: step.end_date.split('T')[0],
                                    destination: step,
                                    reset: true
                                }
                            });
                        }
                    }
                });
                props.router.push(`/${window.url_name}/apps/accommodation-card/${props.accommodation.hotel[0]?.id}`);
            }
        };
    
        useEffect(() => {
        }, [props.accommodation]);
    
        if (props.type === 'accommodation') {
            return (
                <>
                    <CartProductCard
                        type="accommodation"
                        item={props.accommodation}
                        accommodations={props.accommodationsList}
                        mainContent={
                            <Stack>
                                <CartProductCardName
                                    type="accommodation"
                                    content={
                                        <Box sx={{ cursor: 'pointer', textDecoration: 'underline' }} component="span">
                                            {
                                                props.accommodation.localization.find((item) => {
                                                    return item.locale === locale;
                                                })?.name ??
                                                props.accommodation.hotel[0]?.name ??
                                                ''
                                            }
                                            {
                                                variantName &&
                                                ` (${variantName})`
                                            }
                                        </Box>
                                    }
                                    modifiedName={
                                        props.accommodation.localization.find((item) => {
                                            return item.locale === locale;
                                        })?.name ??
                                        props.accommodation.hotel[0]?.name ??
                                        ''
                                    }
                                    providerName={
                                        props.accommodation.hotel[0]?.name ??
                                        ''
                                    }
                                    localization={props.accommodation.localization}
                                    id={props.accommodation.rooms.map((room) => room.id)}
                                    isCustom={props.accommodation.is_custom}
                                    startIcon={HotelOutlined}
                                    onClick={onGoToCard}
                                />
                                <CartProductIconText
                                    text={address}
                                    startIcon={PlaceOutlined}
                                />
                                <CartProductIconText
                                    text={
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <div>
                                                {
                                                    t<string>(
                                                        'cart-material.cart-construction-products-table-date',
                                                        {
                                                            from: window.moment.utc(props.accommodation.start_date).format('DD MMMM'),
                                                            to: window.moment.utc(props.accommodation.end_date).format('DD MMMM')
                                                        }
                                                    )
                                                }
                                            </div>
                                            {
                                                (
                                                    !window.moment.utc(props.accommodation.provider_start_date).isSame(window.moment.utc(props.accommodation.start_date), 'minutes') ||
                                                    !window.moment.utc(props.accommodation.provider_end_date).isSame(window.moment.utc(props.accommodation.end_date), 'minutes')
                                                ) &&
                                                <Tooltip title={t<string>('cart-material.cart-construction-dates-changed-hint')}>
                                                    <PanToolOutlined sx={{ fontSize: '1.2em' }} />
                                                </Tooltip>
                                            }
                                            {
                                                (
                                                    props.accommodation.is_custom ||
                                                    props.accommodation.booking_status
                                                ) &&
                                                (
                                                    quotation_code !== 'visiteurs' ||
                                                    user?.client_full?.type !== 2
                                                ) &&
                                                <IconButton size="small" onClick={() => setEditTime(true)}>
                                                    <Edit fontSize="inherit" />
                                                </IconButton>
                                            }
                                        </Stack>
                                    }
                                    startIcon={DateRangeOutlined}
                                />
                                <Stack
                                    direction={{ md: 'row' }}
                                    spacing={{ md: 2 }}
                                >
                                    <CartProductIconText
                                        text={
                                            t<string>(
                                                'cart-material.cart-construction-rooms-count',
                                                { count: props.accommodation.rooms.length }
                                            )
                                        }
                                        startIcon={KingBedOutlined}
                                    />
                                    <CartProductIconText
                                        text={
                                            [
                                                t<string>(
                                                    'roadbook.adults-count',
                                                    {
                                                        count: props.accommodation.rooms.map((room) => {
                                                            return room.group_passenger.travelers_list?.filter((traveler) => {
                                                                const result = window.moment.utc(tripEndDate).diff(
                                                                    window.moment.utc(traveler.birth_date),
                                                                    'years'
                                                                );
                                                                return result >= 18;
                                                            }).length ?? 0;
                                                        }).reduce((prev, current) => {
                                                            return prev + current;
                                                        }, 0)
                                                    }
                                                ),
                                                (() => {
                                                    const childrenCount = props.accommodation.rooms.map((room) => {
                                                        return room.group_passenger.travelers_list?.filter((traveler) => {
                                                            return window.moment.utc(tripEndDate).diff(
                                                                window.moment.utc(traveler.birth_date),
                                                                'years'
                                                            ) < 18;
                                                        }).length ?? 0;
                                                    }).reduce((prev, current) => {
                                                        return prev + current;
                                                    }, 0);
                                                    return childrenCount > 0 ?
                                                        t<string>(
                                                            'roadbook.children-count',
                                                            { count: childrenCount }
                                                        ) :
                                                        null;
                                                })()
                                            ].filter((item) => item).join(', ')
                                        }
                                        startIcon={PeopleOutlineOutlined}
                                    />
                                    <CartProductIconText
                                        text={
                                            t<string>(
                                                'cart-material.cart-construction-hotel-nights',
                                                {
                                                    count: window.moment.utc(props.accommodation.end_date).startOf('day').diff(
                                                        window.moment.utc(props.accommodation.start_date).startOf('day'),
                                                        'days'
                                                    )
                                                }
                                            )
                                        }
                                        startIcon={DarkModeOutlined}
                                    />
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    {
                                        props.accommodation.hotel[0]?.parking &&
                                        <LocalParking fontSize="small" />
                                    }
                                    {
                                        props.accommodation.hotel[0]?.internet && 
                                        <Wifi fontSize="small" />
                                    }
                                    {
                                        props.accommodation.hotel[0]?.clim &&
                                        <AcUnit fontSize="small" />
                                    }
                                    {
                                        props.accommodation.hotel[0]?.pool &&
                                        <Pool fontSize="small" />
                                    }
                                </Stack>
                            </Stack>
                        }
                        sideContent={
                            <Stack spacing={1} alignItems="center">
                                <Typography variant="caption" sx={{ opacity: 0.6 }}>
                                    {t<string>('cart-material.cart-construction-accommodation-stay-amount')}
                                </Typography>
                                <Typography fontWeight="bold" textAlign="center">
                                    {
                                        new Intl.NumberFormat(
                                            language,
                                            {
                                                style: 'currency', 
                                                currency: totalCost.currency?.iso_code ?? 'EUR'
                                            }
                                        ).format(totalCost.cost)
                                    }
                                    {
                                        props.accommodation.price_change &&
                                        props.accommodation.price_change.price_variation !== 0 &&
                                        <Stack direction="row" alignItems="center" justifyContent="center">
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    color: props.accommodation.price_change.price_variation < 0 ? 'green' : 'red',
                                                    fontSize: '10px'
                                                }}
                                            >
                                                {
                                                    new Intl.NumberFormat(
                                                        language,
                                                        {
                                                            style: 'currency', 
                                                            currency: totalCost.currency?.iso_code ?? 'EUR'
                                                        }
                                                    ).format(
                                                        props.accommodation.rooms.reduce((prev, current) => {
                                                            return prev + (current.price_change?.price_variation ?? 0);
                                                        }, 0)
                                                    )
                                                }
                                            </Typography>
                                            <ArrowRightAltOutlined
                                                sx={{
                                                    transform: props.accommodation.price_change.price_variation < 0 ?
                                                        'rotateZ(90deg)' :
                                                        'rotateZ(-90deg)',
                                                    color: 'green',
                                                    fontSize: '14px'
                                                }}
                                            />
                                        </Stack>
                                    }
                                </Typography>
                                <CartProductCardProviderLogo
                                    logoUrl={
                                        provider?.logo?.url ??
                                        provider?.logo?.thumbnail_big ??
                                        provider?.logo?.thumbnail_medium ??
                                        provider?.logo?.thumbnail_little ??
                                        null
                                    }
                                    name={provider?.name ?? null}
                                    minimal={!!props.accommodation.subprovider_name}
                                />
                                {
                                    user?.client_full?.type !== 2 &&
                                    props.accommodation.subprovider_name &&
                                    <Typography
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            textAlign: 'center',
                                            display: 'inline-block'
                                        }}>
                                        {props.accommodation.subprovider_name}
                                    </Typography>
                                }
                            </Stack>
                        }
                        footer={
                            <Box sx={{ maxWidth: '100%', overflow: 'hidden' }}>
                                <Stack
                                    ref={(element) => {
                                        if (element) {
                                            footerNode.current = element;
                                            onCreateResizeObserver();
                                        }
                                    }}
                                    direction="row"
                                    alignItems="flex-start"
                                    flexWrap="wrap"
                                    columnGap={2}
                                    rowGap={1}
                                >
                                    <div
                                        ref={(element) => {
                                            if (element) {
                                                footerNodeFirstChild.current = element;
                                                onCreateResizeObserver();
                                            }
                                        }}>
                                        {
                                            !isProductPackaged({
                                                product: props.accommodation,
                                                stackInfos: trip?.stack_info ?? null,
                                                connected: true
                                            }) &&
                                            (props.accommodation.rooms?.length ?? 0) === 1 &&
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                <CartConstructionProductsTableItemStatus
                                                    type="accommodation"
                                                    item={props.accommodation}
                                                    bookingProcessState={props.accommodation.booking_process_state}
                                                    bookingStatus={props.accommodation.booking_status}
                                                    agencyNeedToBook={props.accommodation.agency_need_to_book}
                                                />
                                                {
                                                    (props.accommodation.booking_status?.status_booking === StatusBooking.CONFIRMED ||
                                                        props.accommodation.booking_status?.status_booking === StatusBooking.PENDING ||
                                                        props.accommodation.booking_status?.status_booking === StatusBooking.WAITING) &&
                                                    <Typography fontWeight={100} fontSize="0.75rem">
                                                        {
                                                            t<string>(
                                                                'cart-material.cart-construction-reference',
                                                                { ref: props.accommodation.booking_status.item_reference ?? '' }
                                                            )
                                                        }
                                                    </Typography>
                                                }
                                            </Stack>
                                        }
                                        {
                                            !isProductPackaged({
                                                product: props.accommodation,
                                                stackInfos: trip?.stack_info ?? null,
                                                connected: true
                                            }) &&
                                            (props.accommodation.rooms?.length ?? 0) > 1 &&
                                            (props.accommodation.rooms?.length ?? 0) < 5 &&
                                            <Grid
                                                container
                                                flexWrap="wrap"
                                                gap={2}
                                                sx={{
                                                    marginLeft: '0 !important',
                                                    maxHeight: 56,
                                                    width: 'auto',
                                                    overflowY: 'hidden'
                                                }}
                                            >
                                                {
                                                    chunk(props.accommodation.rooms ?? [], 2).map((group, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <Grid item sx={{ display: 'flex' }}>
                                                                    {
                                                                        index !== 0 &&
                                                                        <Divider orientation="vertical" sx={{ marginRight: 2 }} flexItem />
                                                                    }
                                                                    <Stack spacing={1}>
                                                                        {
                                                                            group.map((room, roomIndex) => (
                                                                                <Stack
                                                                                    key={room.id}
                                                                                    direction="row"
                                                                                    spacing={1}
                                                                                    alignItems="center"
                                                                                    sx={{ whiteSpace: 'nowrap' }}
                                                                                >
                                                                                    <Typography variant="caption">
                                                                                        {
                                                                                            t<string>(
                                                                                                'cart-material.cart-construction-room-no',
                                                                                                { no: index * 2 + (roomIndex + 1) }
                                                                                            )
                                                                                        }
                                                                                    </Typography>
                                                                                    <CartConstructionProductsTableItemStatus
                                                                                        type="accommodation"
                                                                                        item={props.accommodation}
                                                                                        bookingProcessState={room.booking_process_state}
                                                                                        bookingStatus={room.booking_status}
                                                                                        agencyNeedToBook={props.accommodation.agency_need_to_book}
                                                                                    />
                                                                                    {
                                                                                        (props.accommodation.booking_status?.status_booking === StatusBooking.CONFIRMED ||
                                                                                            props.accommodation.booking_status?.status_booking === StatusBooking.PENDING ||
                                                                                            props.accommodation.booking_status?.status_booking === StatusBooking.WAITING) &&
                                                                                        <Typography fontWeight={100} fontSize="0.75rem">
                                                                                            {
                                                                                                t<string>(
                                                                                                    'cart-material.cart-construction-reference',
                                                                                                    { ref: room.booking_status?.item_reference ?? '' }
                                                                                                )
                                                                                            }
                                                                                        </Typography>
                                                                                    }
                                                                                </Stack>
                                                                            ))
                                                                        }
                                                                    </Stack>
                                                                </Grid>
                                                            </React.Fragment>
                                                        );
                                                    })
                                                }
                                            </Grid>
                                        }
                                        {
                                            !isProductPackaged({
                                                product: props.accommodation,
                                                stackInfos: trip?.stack_info ?? null,
                                                connected: true
                                            }) &&
                                            (props.accommodation.rooms?.length ?? 0) >= 5 &&
                                            (() => {
                                                const grouppedRooms = groupBy(
                                                    props.accommodation.rooms,
                                                    (room) => room.booking_status?.status_booking ?? 'NONE'
                                                );
                                                return (
                                                    <Stack gap={1}>
                                                        {
                                                            Object.keys((grouppedRooms)).map((status) => {
                                                                const rooms = grouppedRooms[status] ?? [];
                                                                return (
                                                                    <Stack
                                                                        key={status}
                                                                        direction="row"
                                                                        spacing={1}
                                                                        alignItems="center"
                                                                        sx={{ whiteSpace: 'nowrap' }}
                                                                    >
                                                                        <Typography variant="caption">
                                                                            {
                                                                                t<string>(
                                                                                    'cart-material.cart-construction-rooms-count-dot',
                                                                                    { count: rooms.length }
                                                                                )
                                                                            }
                                                                        </Typography>
                                                                        <CartConstructionProductsTableItemStatus
                                                                            type="accommodation"
                                                                            item={props.accommodation}
                                                                            bookingProcessState={rooms[0]?.booking_process_state ?? ''}
                                                                            bookingStatus={rooms[0]?.booking_status ?? null}
                                                                            agencyNeedToBook={props.accommodation.agency_need_to_book}
                                                                        />
                                                                        {
                                                                            rooms.findIndex((room) => {
                                                                                return room.booking_status?.item_reference;
                                                                            }) >= 0 &&
                                                                            <Typography fontWeight={100} fontSize="0.75rem">
                                                                                {rooms.map((room) => room.booking_status?.item_reference).join(', ')}
                                                                            </Typography>
                                                                        }
                                                                    </Stack>
                                                                );
                                                            })
                                                        }
                                                    </Stack>
                                                );
                                            })()
                                        }
                                    </div>
                                    <Stack
                                        ref={(element) => {
                                            if (element) {
                                                footerNodeSecondChild.current = element;
                                                onCreateResizeObserver();
                                            }
                                        }}
                                        direction="row"
                                        alignItems={
                                            !isProductPackaged({
                                                product: props.accommodation,
                                                stackInfos: trip?.stack_info ?? null,
                                                connected: true
                                            }) ||
                                            props.accommodation.rooms?.length === 1 ?
                                                'center' :
                                                'flex-start'
                                        }
                                        alignSelf="stretch"
                                        spacing={1}
                                    >
                                        {
                                            !isProductPackaged({
                                                product: props.accommodation,
                                                stackInfos: trip?.stack_info ?? null,
                                                connected: true
                                            }) &&
                                            <Divider
                                                orientation="vertical"
                                                flexItem
                                                sx={{ visibility: footerIsTooLarge ? 'hidden' : 'visible' }}
                                            /> 
                                        }
                                        {
                                            insertDivider(
                                                [
                                                    !isProductPackaged({
                                                        product: props.accommodation,
                                                        stackInfos: trip?.stack_info ?? null,
                                                        connected: true
                                                    }) &&
                                                    (
                                                        user?.client_full?.type !== 2 ||
                                                        props.accommodation.creator?.client?.type === user.client_full.type
                                                    ) &&
                                                    <>
                                                        {
                                                            !props.accommodation.is_custom ?
                                                                <Chip
                                                                    label={t<string>('cart-material.flux')}
                                                                    size="small"
                                                                    sx={{
                                                                        backgroundColor: '#2ACAEA',
                                                                        color: '#fff'
                                                                    }}
                                                                    icon={<CloudCircle color="inherit" />}
                                                                /> :
                                                                <Chip
                                                                    size="small"
                                                                    label={t<string>('cart-material.offline')}
                                                                    sx={{
                                                                        backgroundColor: '#6A329F',
                                                                        color: '#fff'
                                                                    }}
                                                                    icon={<CloudOff color="inherit" />}
                                                                />
                                                        }
                                                    </>,
                                                    user?.client_full?.type !== 2 &&
                                                    props.accommodation.static_contract &&
                                                    <Chip
                                                        label={ t<string>('accommodation.direct-contract') }
                                                        size="small"
                                                        icon={<ListAlt color="inherit" />}
                                                    />,
                                                    user?.client_full?.type !== 2 &&
                                                    props.accommodation.traveler_modification &&
                                                    <Chip
                                                        color="warning"
                                                        label={
                                                            props.accommodation.traveler_modification === 'SET_IN_TRIP' ?
                                                                t<string>('cart-material.add-to-cart-traveler') :
                                                                t<string>('cart-material.add-to-option-traveler')
                                                        }
                                                        size="small"
                                                        icon={<ErrorOutline color="inherit" />}
                                                        onDelete={confirmTravelerModification}
                                                    />,
                                                    (
                                                        (props.accommodation.resort_fees_text?.length ?? 0) > 0 ||
                                                        props.accommodation.rooms.findIndex((room) => {
                                                            return !room.resort_fees_included &&
                                                                   room.resort_fees &&
                                                                   room.resort_fees !== '0.00';
                                                        }) >= 0
                                                    ) &&
                                                    <Tooltip title={t<string>('cart-material.cart-construction-see-in-details')}>
                                                        <Box sx={{ whiteSpace: 'nowrap' }}>
                                                            <CartProductIconText
                                                                text={t<string>('cart-material.cart-construction-resort-fees')}
                                                                startIcon={ReportProblemOutlined}
                                                                color="#FF8100"
                                                            />
                                                        </Box>
                                                    </Tooltip>,
                                                    groups.length > 0 &&
                                                    <Tooltip title={t<string>('cart-material.cart-construction-see-in-details')}>
                                                        <Box sx={{ whiteSpace: 'nowrap' }}>
                                                            <CartProductIconText
                                                                text={t<string>('cart-material.cart-construction-rooms-missing')}
                                                                startIcon={ReportProblemOutlined}
                                                                color="#FF8100"
                                                            />
                                                        </Box>
                                                    </Tooltip>,
                                                    props.accommodation.booking_status?.status_booking !== StatusBooking.CONFIRMED &&
                                                    props.accommodation.on_request &&
                                                    <Typography variant="caption">
                                                        {t<string>('cart-material.on-request')}
                                                    </Typography>,
                                                    !isProductPackaged({
                                                        product: props.accommodation,
                                                        stackInfos: trip?.stack_info ?? null
                                                    }) &&
                                                    props.accommodation.rooms.findIndex((item) => {
                                                        return !item.is_cancellable;
                                                    }) >= 0 &&
                                                    <Box sx={{ whiteSpace: 'nowrap' }}>
                                                        <CartProductIconText
                                                            text={t<string>('cart-material.not-cancellable')}
                                                            startIcon={ReportProblemOutlined}
                                                            color="#FF8100"
                                                        />
                                                    </Box>
                                                ]
                                            )
                                        }
                                    </Stack>
                                </Stack>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={2}
                                    sx={{ marginTop: 1 }}
                                >
                                    <Button
                                        sx={{
                                            padding: 0,
                                            textTransform: 'none',
                                            color: '#000',
                                            textDecoration: 'underline'
                                        }}
                                        onClick={onShowBookingDetail}
                                    >
                                        {t<string>('cart-material.cart-construction-details-and-cancellation')}
                                    </Button>
                                </Stack>
                            </Box>
                        }
                        alerts={
                            <Collapse in={!found || !!iti_error} unmountOnExit>
                                <CardContent
                                    sx={{
                                        borderTop: !found || !!iti_error ?
                                            '1px solid rgba(0, 0, 0, 0.25)' :
                                            undefined
                                    }}
                                >
                                    <Stack spacing={1}>
                                        {
                                            iti_error &&
                                            <Alert variant="filled" severity="error" sx={{ backgroundColor: '#E74432' }}>
                                                { t<string>('cart-material.itinerary-error-1') }{' '}
                                                {
                                                    iti_error.destination?.data?.localization.find((item) => {
                                                        return item.locale === locale;
                                                    })?.name ??
                                                    iti_error.destination?.data?.name ??
                                                    iti_error.destination?.data?.international_name
                                                } { t<string>('global.from') }{' '}
                                                { window.moment.utc(iti_error.start_date).format('DD/MM/YYYY') }{' '}
                                                { t<string>('global.to') } { window.moment.utc(iti_error.end_date).format('DD/MM/YYYY') }.{' '}
                                                { t<string>('cart-material.itinerary-error-2') }.
                                            </Alert>
                                        }
                                        {
                                            !found &&
                                            <Alert variant="filled" severity="error" sx={{ backgroundColor: '#E74432' }}>
                                                { t<string>('cart-material.no-destination-found') }
                                            </Alert>
                                        }
                                    </Stack>
                                </CardContent>
                            </Collapse>
                        }
                        margin={
                            <MarginWrapper
                                openMargin={openMargin}
                                accommodation={props.accommodation}
                            />
                        }
                        pictures={pictures}
                        alwaysOpen={props.alwaysOpen}
                    >
                        <CartAccommodationCardDetails
                            accommodation={props.accommodation}
                            displayedAccommodations={props.accommodationsList}
                            onChangeOpenMargin={setOpenMargin}
                        />
                    </CartProductCard>
                    {
                        user?.client_full?.type !== 2 &&
                        editTime &&
                        <CartClientTimeModal
                            type="normal"
                            product={props.accommodation}
                            onClose={() => setEditTime(false)}
                        />
                    }
                </>
            );
        }
    
        return (
            <>
                <CartProductCard
                    type="manual-accommodation"
                    item={props.accommodation}
                    mainContent={
                        <Stack>
                            <CartProductCardName
                                type="manual"
                                content={
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <div>
                                            {
                                                props.accommodation.localization?.find((item) => {
                                                    return item.locale === locale;
                                                })?.name ??
                                                props.accommodation.name
                                            }
                                        </div>
                                        {
                                            props.accommodation.stars > 0 &&
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                sx={(theme) => ({
                                                    marginLeft: `${theme.spacing(2)} !important`
                                                })}
                                            >
                                                {
                                                    new Array(props.accommodation.stars ?? 0).fill(null).map((_, index) => (
                                                        <StarOutline
                                                            key={index}
                                                            fontSize="medium"
                                                            fontWeight="bold"
                                                        />
                                                    ))
                                                }
                                            </Stack>
                                        }
                                    </Stack>
                                    
                                }
                                modifiedName={
                                    props.accommodation.localization?.find((item) => {
                                        return item.locale === locale;
                                    })?.name ??
                                    props.accommodation.name
                                }
                                providerName={props.accommodation.name}
                                localization={props.accommodation.localization ?? []}
                                id={props.accommodation.id}
                                isCustom={props.accommodation.is_custom}
                                startIcon={HotelOutlined}
                            />
                            <CartProductIconText
                                text={address}
                                startIcon={PlaceOutlined}
                            />
                            <CartProductIconText
                                text={
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <div>
                                            {
                                                t<string>(
                                                    'cart-material.cart-construction-products-table-date',
                                                    {
                                                        from: window.moment.utc(props.accommodation.start_date).format('DD MMMM'),
                                                        to: window.moment.utc(props.accommodation.end_date).format('DD MMMM')
                                                    }
                                                )
                                            }
                                        </div>
                                        {
                                            (
                                                quotation_code !== 'visiteurs' ||
                                                user?.client_full?.type !== 2
                                            ) &&
                                            <IconButton size="small" onClick={() => setEditTime(true)}>
                                                <Edit fontSize="inherit" />
                                            </IconButton>
                                        }
                                    </Stack>
                                }
                                startIcon={DateRangeOutlined}
                            />
                            <Stack
                                direction={{ md: 'row' }}
                                spacing={{ md: 2 }}
                            >
                                <CartProductIconText
                                    text={
                                        t<string>(
                                            'cart-material.cart-construction-rooms-count',
                                            { count: props.accommodation.rooms?.length ?? 1 }
                                        )
                                    }
                                    startIcon={KingBedOutlined}
                                />
                                <CartProductIconText
                                    text={
                                        [
                                            t<string>(
                                                'roadbook.adults-count',
                                                {
                                                    count: props.accommodation.rooms?.map((room) => {
                                                        return room.group_passenger?.travelers_list?.filter((traveler) => {
                                                            const result = window.moment.utc(tripEndDate).diff(
                                                                window.moment.utc(traveler.birth_date),
                                                                'years'
                                                            );
                                                            return result >= 18;
                                                        }).length ?? 0;
                                                    }).reduce((prev, current) => {
                                                        return prev + current;
                                                    }, 0)
                                                }
                                            ),
                                            (() => {
                                                const childrenCount = props.accommodation.rooms?.map((room) => {
                                                    return room.group_passenger?.travelers_list?.filter((traveler) => {
                                                        return window.moment.utc(tripEndDate).diff(
                                                            window.moment.utc(traveler.birth_date),
                                                            'years'
                                                        ) < 18;
                                                    }).length ?? 0;
                                                }).reduce((prev, current) => {
                                                    return prev + current;
                                                }, 0) ?? 0;
                                                return childrenCount > 0 ?
                                                    t<string>(
                                                        'roadbook.children-count',
                                                        { count: childrenCount }
                                                    ) :
                                                    null;
                                            })()
                                        ].filter((item) => item).join(', ')
                                    }
                                    startIcon={PeopleOutlineOutlined}
                                />
                                <CartProductIconText
                                    text={
                                        t<string>(
                                            'cart-material.cart-construction-hotel-nights',
                                            {
                                                count: window.moment.utc(props.accommodation.end_date).startOf('day').diff(
                                                    window.moment.utc(props.accommodation.start_date).startOf('day'),
                                                    'days'
                                                )
                                            }
                                        )
                                    }
                                    startIcon={DarkModeOutlined}
                                />
                            </Stack>
                        </Stack>
                    }
                    sideContent={
                        <Stack spacing={1} alignItems="center">
                            <Typography variant="caption" sx={{ opacity: 0.6 }}>
                                {t<string>('cart-material.cart-construction-accommodation-stay-amount')}
                            </Typography>
                            <Typography fontWeight="bold">
                                {
                                    new Intl.NumberFormat(
                                        language,
                                        {
                                            style: 'currency', 
                                            currency: totalCost.currency?.iso_code ?? 'EUR'
                                        }
                                    ).format(totalCost.cost)
                                }
                            </Typography>
                            <CartProductCardProviderLogo
                                logoUrl={
                                    provider?.logo?.url ??
                                    provider?.logo?.thumbnail_big ??
                                    provider?.logo?.thumbnail_medium ??
                                    provider?.logo?.thumbnail_little ??
                                    null
                                }
                                name={provider?.name ?? null}
                            />
                        </Stack>
                    }
                    footer={
                        <Stack direction="row" alignItems="center" spacing={2}>
                            {
                                insertDivider(
                                    [
                                        !isProductPackaged({
                                            product: props.accommodation,
                                            stackInfos: trip?.stack_info ?? null,
                                            connected: true
                                        }) &&
                                        <>
                                            <CartConstructionProductsTableItemStatus
                                                type="manual"
                                                item={props.accommodation}
                                                bookingProcessState={props.accommodation.booking_process_state}
                                                bookingStatus={props.accommodation.booking_status}
                                                agencyNeedToBook={props.accommodation.agency_need_to_book}
                                            />
                                            {
                                                (props.accommodation.booking_status?.status_booking === StatusBooking.CONFIRMED ||
                                                props.accommodation.booking_status?.status_booking === StatusBooking.PENDING ||
                                                props.accommodation.booking_status?.status_booking === StatusBooking.WAITING) &&
                                                <Typography fontWeight={100} fontSize="0.75rem">
                                                    {
                                                        t<string>(
                                                            'cart-material.cart-construction-reference',
                                                            { ref: props.accommodation.booking_status.item_reference ?? '' }
                                                        )
                                                    }
                                                </Typography>
                                            }
                                        </>,
                                        !isProductPackaged({
                                            product: props.accommodation,
                                            stackInfos: trip?.stack_info ?? null,
                                            connected: true
                                        }) &&
                                        (
                                            user?.client_full?.type !== 2 ||
                                            props.accommodation.creator?.client?.type === user.client_full.type
                                        ) &&
                                        <>
                                            {
                                                props.accommodation.auto_product === null ?
                                                    <Chip
                                                        label={t<string>('accommodation.manual')}
                                                        size="small"
                                                        sx={{
                                                            backgroundColor: '#6A329F',
                                                            color: '#fff'
                                                        }}
                                                        icon={<BuildCircle color="inherit" />}
                                                    /> :
                                                    <Chip
                                                        label={t<string>('cart-material.annex')}
                                                        size="small"
                                                        sx={{
                                                            backgroundColor: '#f5823b',
                                                            color: '#fff'
                                                        }}
                                                        icon={<CardGiftcard color="inherit" />}
                                                    />
                                            }
                                        </>,
                                        user?.client_full?.type !== 2 &&
                                        props.accommodation.traveler_modification &&
                                        <Chip
                                            color="warning"
                                            label={
                                                props.accommodation.traveler_modification === 'SET_IN_TRIP' ?
                                                    t<string>('cart-material.add-to-cart-traveler') :
                                                    t<string>('cart-material.add-to-option-traveler')
                                            }
                                            size="small"
                                            icon={<ErrorOutline color="inherit" />}
                                            onDelete={confirmTravelerModificationManual}
                                        />,
                                        props.accommodation.resort_fees &&
                                        <Tooltip title={t<string>('cart-material.cart-construction-see-in-details')}>
                                            <Box sx={{ whiteSpace: 'nowrap' }}>
                                                <CartProductIconText
                                                    text={t<string>('cart-material.cart-construction-resort-fees')}
                                                    startIcon={ReportProblemOutlined}
                                                    color="#FF8100"
                                                />
                                            </Box>
                                        </Tooltip>,
                                        props.accommodation.moved_with_recalc &&
                                        (
                                            quotation_code !== 'visiteurs' ||
                                            user?.client_full?.type !== 2
                                        ) &&
                                        <Box sx={{ whiteSpace: 'nowrap' }}>
                                            <CartProductIconText
                                                text={t<string>('cart-material.cart-construction-recalculated-manual-product')}
                                                startIcon={ReportProblemOutlined}
                                                color="#FF8100"
                                            />
                                        </Box>,
                                        props.accommodation.booking_status?.status_booking !== StatusBooking.CONFIRMED &&
                                        props.accommodation.on_request &&
                                        <Typography variant="caption">
                                            {t<string>('cart-material.on-request')}
                                        </Typography>
                                    ]
                                )
                            }
                        </Stack>
                    }
                    alerts={
                        <Collapse in={!found || !!iti_error} unmountOnExit>
                            <CardContent
                                sx={{
                                    borderTop: !found || !!iti_error ?
                                        '1px solid rgba(0, 0, 0, 0.25)' :
                                        undefined
                                }}
                            >
                                <Stack spacing={1}>
                                    {
                                        iti_error &&
                                        <Alert variant="filled" severity="error" sx={{ backgroundColor: '#E74432' }}>
                                            { t<string>('cart-material.itinerary-error-1') }{' '}
                                            {
                                                iti_error.destination?.data?.localization.find((item) => {
                                                    return item.locale === locale;
                                                })?.name ??
                                                iti_error.destination?.data?.name ??
                                                iti_error.destination?.data?.international_name
                                            } { t<string>('global.from') }{' '}
                                            { window.moment.utc(iti_error.start_date).format('DD/MM/YYYY') }{' '}
                                            { t<string>('global.to') } { window.moment.utc(iti_error.end_date).format('DD/MM/YYYY') }.{' '}
                                            { t<string>('cart-material.itinerary-error-2') }.
                                        </Alert>
                                    }
                                    {
                                        !found &&
                                        <Alert variant="filled" severity="error" sx={{ backgroundColor: '#E74432' }}>
                                            { t<string>('cart-material.no-destination-found') }
                                        </Alert>
                                    }
                                </Stack>
                            </CardContent>
                        </Collapse>
                    }
                    margin={
                        <ManualMarginWrapper
                            accommodation={props.accommodation}
                        />
                    }
                    pictures={pictures}
                    alwaysOpen={props.alwaysOpen}
                >
                    <CartManualAccommodationCardDetails accommodation={props.accommodation} />
                </CartProductCard>
                {
                    user?.client_full?.type !== 2 &&
                    editTime &&
                    <CartClientTimeModal
                        type="manual"
                        product={props.accommodation}
                        onClose={() => setEditTime(false)}
                    />
                }
            </>
        );
    }
);

type MarginWrapperProps = {
    openMargin: boolean,
    accommodation: AccommodationCart,
}

function MarginWrapper(props: MarginWrapperProps): JSX.Element {
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const seeAllProductsMargins = useSelector((state: AppState) => state.cartConstruction.seeProductsMargins);

    return (
        <Collapse
            in={
                (
                    seeAllProductsMargins ||
                    props.openMargin
                ) &&
                !isProductPackaged({
                    product: props.accommodation,
                    stackInfos: trip?.stack_info ?? null
                })
            }
        >
            <CardContent
                sx={{
                    borderTop: seeAllProductsMargins || props.openMargin ?
                        '1px solid rgba(0, 0, 0, 0.25)' :
                        undefined
                }}
            >
                <CartConstructionProductsTableItemMargin
                    type="accommodation"
                    item={props.accommodation}
                />
            </CardContent>
        </Collapse>
    );
}

type ManualMarginWrapperProps = {
    accommodation: ManualProductAccommodationCart,
}

function ManualMarginWrapper(props: ManualMarginWrapperProps): JSX.Element {
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const seeAllProductsMargins = useSelector((state: AppState) => state.cartConstruction.seeProductsMargins);

    return (
        <Collapse
            in={
                seeAllProductsMargins &&
                !isProductPackaged({
                    product: props.accommodation,
                    stackInfos: trip?.stack_info ?? null
                })
            }
            unmountOnExit
        >
            <CardContent
                sx={{
                    borderTop: seeAllProductsMargins ?
                        '1px solid rgba(0, 0, 0, 0.25)' :
                        undefined
                }}
            >
                <CartConstructionProductsTableItemMargin
                    type="manual-accommodation"
                    item={props.accommodation}
                />
            </CardContent>
        </Collapse>
    );
}
