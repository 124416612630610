import GetProvider from "../../Common/Functions/GetProvider";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import CheckResponse from '../../Flight/FlightSelected/Functions/CheckResponse';

import GetCookie from '../../Common/Functions/GetCookie';

import { AddBoookingLaunched, SetCartToBook, AddError, EmptyError } from "../../../Actions/Booking";
import { EditAccommodationCartById } from '../../../Actions/Accommodation';
import { EditFlightCartById } from "../../../Actions/Flight";
import { EditCarCart } from "../../../Actions/CarsSearch";
import { EditPoiCartById } from "../../../Actions/Poi";
import { EditTransfersCartById } from '../../../Actions/Transfers';
import { SetDataTrip } from "../../../Actions/Trip";

import axios from 'axios';
import { flatten, uniq } from "lodash";
import { isProductPackaged } from "../../CartMaterial/utils/isProductPackaged";
let get_status = false;

export default (to_book, dispatch, provider_list, manual_provider_list, end_date, t, notifyProvider, bookSpecialPackage, trip) => {
    const toBeBooked = to_book.filter((item) => {
        const stackInfo = trip?.stack_info?.find((stack) => {
            return stack.id === item.stack_info_id;
        });
        return !isProductPackaged({
            product: item,
            stackInfos: trip?.stack_info ?? null
        }) || !isProductPackaged({
            product: item,
            stackInfos: trip?.stack_info ?? null,
            connected: true
        }) ||
        (
            isProductPackaged({
                product: item,
                stackInfos: trip?.stack_info ?? null,
                for_price: true
            }) &&
            (
                !stackInfo ||
                stackInfo.is_custom === true
            )
        );
    });
    get_status = false;
    dispatch(EmptyError());
    let hotel_by_providers = {};
    let temp_to_book = [];
    let product_type = [];
    let product_to_book = [];
    const bookedProducts = [];
    let { pass_check, headers } = CheckBeforeRequest();
    const responses = toBeBooked.map((product) => {
        if (product.is_available && product.is_displayed === true && (product.booking_status === null || (product.booking_status !== null && product.booking_status.status_booking === "ERROR"))) {
            temp_to_book.push(product);
            let provider = GetProvider(provider_list, product.provider);
            if (provider === null) {
                provider = GetProvider(manual_provider_list, product.provider);
            }
            if (!['creole_travel', 'magic_arabia', 'goway_travel', 'rmhtours'].includes(provider.provider.code)) {
                if (product.product_type === 0) {
                    if (hotel_by_providers.hasOwnProperty(provider.provider.code)) {
                        hotel_by_providers[provider.provider.code].push(product.id);
                    } else {
                        hotel_by_providers[provider.provider.code] = [product.id];
                    }
                } else if (!product.is_manual) {
                    let request = {
                        items: [product.id]
                    };
                    if (pass_check) {
                        const response = axios({
                            method: "post",
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/booking/?product_types=${product.product_type}`,
                            headers: headers,
                            data: request
                        });
                        response.then(function (response) {
                            if (!get_status) {
                                axios({
                                    method: "GET",
                                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/?token=${GetCookie("trip_token")}`,
                                    headers: headers
                                }).then(function (response) {
                                    dispatch(SetDataTrip(response.data));
                                });
                                get_status = true;
                            }
                            response.data.map((first_arr) => {
                                first_arr.map((booked_product) => {
                                    if (booked_product.booking_status !== null && booked_product.booking_status === "ERROR") {
                                        booked_product.booking_errors.map((booking_error) => {
                                            let tmp_error = {
                                                error: booking_error.detail,
                                                name: booked_product.name
                                            };
                                            dispatch(AddError(tmp_error));
                                        });
                                    }
                                    switch (booked_product.product_type) {
                                        case 2:
                                            if (booked_product.is_available === false) {
                                                let tmp_error = {
                                                    error: t("booking.not_available_anymore"),
                                                    name: booked_product.name
                                                };
                                                dispatch(AddError(tmp_error));
                                            } else {
                                                bookedProducts.push(booked_product);
                                            }
                                            dispatch(EditCarCart(booked_product));
                                            break;
                                        case 4:
                                            if (booked_product.is_available === false) {
                                                let tmp_error = {
                                                    error: t("booking.not_available_anymore"),
                                                    name: booked_product.is_custom ? booked_product.custom_product.title : booked_product.name
                                                };
                                                dispatch(AddError(tmp_error));
                                            } else {
                                                bookedProducts.push(booked_product);
                                            }
                                            dispatch(EditTransfersCartById(booked_product));
                                            break;
                                        case 6:
                                            let arr = CheckResponse([booked_product], end_date);
    
                                            let flight_name = [];
                                            arr[0].outbounds.map((outbound) => {
                                                let provider = outbound.operate_by.airline_code;
                                                let flight_number = outbound.legs[0].flight_number;
                                                flight_name.push(provider + "-" + flight_number);
                                            });
                                            flight_name = flight_name.join();
    
                                            if (arr.is_available === false) {
                                                let tmp_error = {
                                                    error: t("booking.not_available_anymore"),
                                                    name: flight_name
                                                };
                                                dispatch(AddError(tmp_error));
                                            } else {
                                                bookedProducts.push(arr[0]);
                                            }
                                            dispatch(EditFlightCartById(arr[0]));
                                            break;
                                        case 11:
                                            if (booked_product.is_available === false) {
                                                let tmp_error = {
                                                    error: t("booking.not_available_anymore"),
                                                    name: booked_product.is_custom ? booked_product.custom_product.title : booked_product.name
                                                };
                                                dispatch(AddError(tmp_error));
                                            } else {
                                                bookedProducts.push(booked_product);
                                            }
                                            dispatch(EditPoiCartById(booked_product));
                                            break;
                                        case 12:
                                            if (booked_product.is_available === false) {
                                                let tmp_error = {
                                                    error: t("booking.not_available_anymore"),
                                                    name: booked_product.is_custom ? booked_product.custom_product.title : booked_product.name
                                                };
                                                dispatch(AddError(tmp_error));
                                            } else {
                                                bookedProducts.push(booked_product);
                                            }
                                            dispatch(EditPoiCartById(booked_product));
                                            break;
                                        case 19:
                                        case 8:
                                            if (booked_product.is_available === false) {
                                                let tmp_error = {
                                                    error: t("booking.not_available_anymore"),
                                                    name: t('cart-material.assistance')
                                                };
                                                dispatch(AddError(tmp_error));
                                            } else {
                                                bookedProducts.push(booked_product);
                                            }
                                            dispatch({ type: 'CART_ASSISTANCE_EDIT_BY_ID', payload: booked_product });
                                            break;
                                    }
                                    dispatch(AddBoookingLaunched(1));
                                });
                            });
                        }).catch(function (error) {
                            let name = "";
                            switch (product.product_type) {
                                case 2:
                                    name = product.name;
                                    break;
                                case 4:
                                    name = product.is_custom ? product.custom_product.title : product.name;
                                    break;
                                case 6:
                                    let flight_name = [];
                                    product.outbounds.map((outbound) => {
                                        let provider = outbound.operate_by.airline_code;
                                        let flight_number = outbound.legs[0].flight_number;
                                        flight_name.push(provider + "-" + flight_number);
                                    });
                                    name = flight_name.join();
                                    break;
                                case 11:
                                    name = product.is_custom ? product.custom_product.title : product.name;
                                    break;
                                case 12:
                                    name = product.is_custom ? product.custom_product.title : product.name;
                                    break;
                                case 19:
                                    name = t('cart-material.assistance');
                                    break;
                            }
                            if (error.reponse !== undefined) {
                                let tmp_error = {
                                    error: error.response.data !== undefined ? error.response.data.error : t("booking.api_error"),
                                    name: name
                                };
                                dispatch(AddError(tmp_error));
                                dispatch(AddBoookingLaunched(1));
                            } else {
                                let temp_error = {
                                    error: t("booking.api_error"),
                                    name: name
                                };
                                dispatch(AddError(temp_error));
                                dispatch(AddBoookingLaunched(1));
                            }
                        });
                        return response;
                    }
                }
            } else {
                if (!product_type.includes(product.product_type)) {
                    product_type.push(product.product_type);
                }
                if (!product.is_manual) {
                    product_to_book.push(product.id);
                }
            }
        }
    });

    dispatch(
        SetCartToBook(
            temp_to_book.concat(
                bookSpecialPackage ?
                    to_book.filter((item) => {
                        return isProductPackaged({
                            product: item,
                            stackInfos: trip?.stack_info ?? null,
                            connected: true
                        });
                    }) :
                    []
            )
        )
    );

    if (Object.keys(hotel_by_providers).length !== 0) {
        Object.keys(hotel_by_providers).map((hotel_provider) => {
            let request = {
                items: hotel_by_providers[hotel_provider]
            };
            if (pass_check) {
                const response = axios({
                    method: "post",
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/booking/?product_types=0`,
                    headers: headers,
                    data: request
                });
                response.then(function (response) {
                    response.data.map((first_arr) => {
                        first_arr.map((booked_room) => {
                            if (booked_room.booking_status !== null && booked_room.booking_status === "ERROR") {
                                booked_room.booking_errors.map((booking_error) => {
                                    let tmp_error = {
                                        error: booking_error.detail,
                                        name: booked_room.hotel[0].name + " " + booked_room.category
                                    };
                                    dispatch(AddError(tmp_error));
                                });
                            }
                            dispatch(EditAccommodationCartById(booked_room));
                            if (booked_room.is_available === false) {
                                let tmp_error = {
                                    error: t("booking.not_available_anymore"),
                                    name: booked_room.hotel[0].name + " " + booked_room.category
                                };
                                dispatch(AddError(tmp_error));
                            } else {
                                bookedProducts.push(booked_room);
                            }
                        });
                    });
                    dispatch(AddBoookingLaunched(hotel_by_providers[hotel_provider].length));
                }).catch(function (error) {
                    console.log("hotel booking error :", error);
                    let name = [];
                    toBeBooked.map((product) => {
                        if (product.product_type === 0 && hotel_by_providers[hotel_provider].includes(product.id)) {
                            name.push(product.hotel[0].name);
                        }
                    });
    
                    if (error.reponse !== undefined) {
                        let tmp_error = Object.assign({}, error.response.data);
                        tmp_error = {
                            error: tmp_error.error !== undefined ? tmp_error.error : t("booking.api_error"),
                            name: name.join(" | ")
                        };
                    } else {
                        let tmp_error = {
                            error: t("booking.api_error"),
                            name: name.join("|")
                        };
                        dispatch(AddError(tmp_error));
                    }
                    dispatch(AddBoookingLaunched(hotel_by_providers[hotel_provider].length));
                });
                responses.push(response);
            }
        });
    }
    if (bookSpecialPackage) {
        const products = to_book.filter((item) => {
            return isProductPackaged({
                product: item,
                stackInfos: trip?.stack_info ?? null,
                connected: true
            });
        });
        const ids = uniq(
            products.map((item) => {
                if (item.product_type === 0 && item.is_manual) {
                    return item.product_id;
                }
                return item.id;
            })
        );
        const response = axios({
            method: "post",
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/booking/`,
            headers: headers,
            params: {
                product_types: 24
            },
            data: {
                items: ids
            }
        });
        response.then((response) => {
            axios({
                method: "GET",
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/?token=${GetCookie("trip_token")}`,
                headers: headers
            }).then(function (response) {
                dispatch(SetDataTrip(response.data));
            });
            const flattenProducts = flatten(response.data ?? []);
            for (const booked_product of flattenProducts) {
                const isManual = !!products.find((item) => {
                    return item.id === booked_product.id ||
                           (
                               item.is_manual &&
                               item.product_type === 0 &&
                               item.product_id === booked_product.id
                           );
                })?.is_manual;
                if (isManual) {
                    if (booked_product.is_available === false) {
                        let tmp_error = {
                            error: t("booking.not_available_anymore"),
                            name: booked_product.name
                        };
                        dispatch(AddError(tmp_error));
                    } else {
                        bookedProducts.push(booked_product);
                    }
                    dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: booked_product });
                } else {
                    switch (booked_product.product_type) {
                        case 2:
                            if (booked_product.is_available === false) {
                                let tmp_error = {
                                    error: t("booking.not_available_anymore"),
                                    name: booked_product.name
                                };
                                dispatch(AddError(tmp_error));
                            } else {
                                bookedProducts.push(booked_product);
                            }
                            dispatch(EditCarCart(booked_product));
                            break;
                        case 4:
                            if (booked_product.is_available === false) {
                                let tmp_error = {
                                    error: t("booking.not_available_anymore"),
                                    name: booked_product.is_custom ? booked_product.custom_product.title : booked_product.name
                                };
                                dispatch(AddError(tmp_error));
                            } else {
                                bookedProducts.push(booked_product);
                            }
                            dispatch(EditTransfersCartById(booked_product));
                            break;
                        case 6:
                            let arr = CheckResponse([booked_product], end_date);

                            let flight_name = [];
                            arr[0].outbounds.map((outbound) => {
                                let provider = outbound.operate_by.airline_code;
                                let flight_number = outbound.legs[0].flight_number;
                                flight_name.push(provider + "-" + flight_number);
                            });
                            flight_name = flight_name.join();

                            if (arr.is_available === false) {
                                let tmp_error = {
                                    error: t("booking.not_available_anymore"),
                                    name: flight_name
                                };
                                dispatch(AddError(tmp_error));
                            } else {
                                bookedProducts.push(arr[0]);
                            }
                            dispatch(EditFlightCartById(arr[0]));
                            break;
                        case 11:
                            if (booked_product.is_available === false) {
                                let tmp_error = {
                                    error: t("booking.not_available_anymore"),
                                    name: booked_product.is_custom ? booked_product.custom_product.title : booked_product.name
                                };
                                dispatch(AddError(tmp_error));
                            } else {
                                bookedProducts.push(booked_product);
                            }
                            dispatch(EditPoiCartById(booked_product));
                            break;
                        case 12:
                            if (booked_product.is_available === false) {
                                let tmp_error = {
                                    error: t("booking.not_available_anymore"),
                                    name: booked_product.is_custom ? booked_product.custom_product.title : booked_product.name
                                };
                                dispatch(AddError(tmp_error));
                            } else {
                                bookedProducts.push(booked_product);
                            }
                            dispatch(EditPoiCartById(booked_product));
                            break;
                        case 19:
                        case 8:
                            if (booked_product.is_available === false) {
                                let tmp_error = {
                                    error: t("booking.not_available_anymore"),
                                    name: t('cart-material.assistance')
                                };
                                dispatch(AddError(tmp_error));
                            } else {
                                bookedProducts.push(booked_product);
                            }
                            dispatch({ type: 'CART_ASSISTANCE_EDIT_BY_ID', payload: booked_product });
                            break;
                    }
                }
                const isManualAccommodation = !!products.find((item) => {
                    return item.is_manual &&
                           item.product_type === 0 &&
                           item.product_id === booked_product.id;
                });
                dispatch(
                    AddBoookingLaunched(
                        isManualAccommodation ?
                            (booked_product.rooms?.length ?? 1) :
                            1
                    )
                );
            }
        }).catch((error) => {
            if (error.response?.data?.error) {
                let tmp_error = {
                    error: error.response?.data?.error,
                    name: t(
                        'cart-material.cart-construction-package-name',
                        { name: 0 }
                    )
                };
                dispatch(AddError(tmp_error));
            } else {
                console.error(error);
            }
        });
    }
    if (product_to_book.length !== 0) {
        const response = axios({
            method: "post",
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/booking/?product_types=${product_type.join()}`,
            headers: headers,
            data: {
                items: [...product_to_book]
            }
        });
        response.then(function (response) {
            if (!get_status) {
                axios({
                    method: "GET",
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/?token=${GetCookie("trip_token")}`,
                    headers: headers
                }).then(function (response) {
                    dispatch(SetDataTrip(response.data));
                });
                get_status = true;
            }
            response.data.map((first_arr) => {
                first_arr.map((booked_product) => {
                    if (booked_product.booking_status !== null && booked_product.booking_status === "ERROR") {
                        booked_product.booking_errors.map((booking_error) => {
                            let tmp_error = {
                                error: booking_error.detail,
                                name: booked_product.name
                            };
                            dispatch(AddError(tmp_error));
                        });
                    }
                    switch (booked_product.product_type) {
                        case 0:
                            if (booked_product.is_available === false) {
                                let tmp_error = {
                                    error: t("booking.not_available_anymore"),
                                    name: booked_product.hotel[0].name + " " + booked_product.category
                                };
                                dispatch(AddError(tmp_error));
                            } else {
                                bookedProducts.push(booked_product);
                            }
                            dispatch(EditAccommodationCartById(booked_product));
                            break;
                        case 2:
                            if (booked_product.is_available === false) {
                                let tmp_error = {
                                    error: t("booking.not_available_anymore"),
                                    name: booked_product.name
                                };
                                dispatch(AddError(tmp_error));
                            } else {
                                bookedProducts.push(booked_product);
                            }
                            dispatch(EditCarCart(booked_product));
                            break;
                        case 4:
                            if (booked_product.is_available === false) {
                                let tmp_error = {
                                    error: t("booking.not_available_anymore"),
                                    name: booked_product.is_custom ? booked_product.custom_product.title : booked_product.name
                                };
                                dispatch(AddError(tmp_error));
                            } else {
                                bookedProducts.push(booked_product);
                            }
                            dispatch(EditTransfersCartById(booked_product));
                            break;
                        case 6:
                            let arr = CheckResponse([booked_product], end_date);

                            let flight_name = [];
                            arr[0].outbounds.map((outbound) => {
                                let provider = outbound.operate_by.airline_code;
                                let flight_number = outbound.legs[0].flight_number;
                                flight_name.push(provider + "-" + flight_number);
                            });
                            flight_name = flight_name.join();

                            if (arr.is_available === false) {
                                let tmp_error = {
                                    error: t("booking.not_available_anymore"),
                                    name: flight_name
                                };
                                dispatch(AddError(tmp_error));
                            } else {
                                bookedProducts.push(arr[0]);
                            }
                            dispatch(EditFlightCartById(arr[0]));
                            break;
                        case 11:
                            if (booked_product.is_available === false) {
                                let tmp_error = {
                                    error: t("booking.not_available_anymore"),
                                    name: booked_product.is_custom ? booked_product.custom_product.title : booked_product.name
                                };
                                dispatch(AddError(tmp_error));
                            } else {
                                bookedProducts.push(booked_product);
                            }
                            dispatch(EditPoiCartById(booked_product));
                            break;
                        case 12:
                            if (booked_product.is_available === false) {
                                let tmp_error = {
                                    error: t("booking.not_available_anymore"),
                                    name: booked_product.is_custom ? booked_product.custom_product.title : booked_product.name
                                };
                                dispatch(AddError(tmp_error));
                            } else {
                                bookedProducts.push(booked_product);
                            }
                            dispatch(EditPoiCartById(booked_product));
                            break;
                        case 19:
                        case 8:
                            if (booked_product.is_available === false) {
                                let tmp_error = {
                                    error: t("booking.not_available_anymore"),
                                    name: t('cart-material.assistance')
                                };
                                dispatch(AddError(tmp_error));
                            } else {
                                bookedProducts.push(booked_product);
                            }
                            dispatch({ type: 'CART_ASSISTANCE_EDIT_BY_ID', payload: booked_product });
                            break;
                    }
                    dispatch(AddBoookingLaunched(1));
                });
            });
        }).catch(function (error) {
            /*let name = "";
            switch (product.product_type) {
                case 2:
                    name = product.is_custom ? product.custom_product.title : product.name;
                    break;
                case 4:
                    name = product.is_custom ? product.custom_product.title : product.name;
                    break;
                case 6:
                    let flight_name = [];
                    product.outbounds.map((outbound) => {
                        let provider = outbound.operate_by.airline_code;
                        let flight_number = outbound.legs[0].flight_number;
                        flight_name.push(provider + "-" + flight_number);
                    });
                    name = flight_name.join();
                    break;
                case 11:
                    name = product.is_custom ? product.custom_product.title : product.name;
                    break;
                case 12:
                    name = product.is_custom ? product.custom_product.title : product.name;
                    break;
                case 19:
                    name = t('cart-material.assistance');
                    break;
            } */
            if (error.reponse !== undefined) {
                let tmp_error = {
                    error: error.response.data !== undefined ? error.response.data.error : t("booking.api_error"),
                    name: /*name */''
                };
                dispatch(AddError(tmp_error));
                dispatch(AddBoookingLaunched(1));
            } else {
                let temp_error = {
                    error: t("booking.api_error"),
                    name: /*name */''
                };
                dispatch(AddError(temp_error));
                dispatch(AddBoookingLaunched(1));
            }
        });
        responses.push(response);
    }
    Promise.all(
        responses.filter((item) => item)
    ).finally(() => {
        const version = parseInt(GetCookie('trip_id_version') ?? '-1');
        const providersList = provider_list.concat(manual_provider_list);
        const providers = uniq(
            bookedProducts.filter((product) => {
                return !isProductPackaged({
                    product,
                    stackInfos: trip?.stack_info ?? null,
                    connected: true
                }) &&
                (product.is_manual || product.is_custom);
            }).map((product) => {
                return product.provider;
            })
        ).map((providerId) => {
            return providersList.find((provider) => {
                return provider.provider.id === providerId;
            });
        }).filter((provider) => {
            return provider?.auto_reservation_email;
        });
        for (const provider of providers) {
            notifyProvider(version, provider.provider.id);
        }
    });
};
