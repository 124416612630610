import React, { Fragment, useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { useSnackbar } from "notistack";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import grey from "@material-ui/core/colors/grey";
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CloseIcon from '@material-ui/icons/Close';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import GetDataFromCurrentVersion from "../../Common/Functions/GetDataFromCurrentVersion";
import GetStatusColor from "../TripList/Functions/GetStatusColor";
import GetStatusColorHexa from "./Functions/GetStatusColorHexa";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import RenderField from "./RenderField";

import clsx from "clsx";
import axios from "axios";

import { SetTripInfo } from "../../../Actions/Menu";

//---- CSS ----//
import "../../../Style/Menu/TripList.css";
import TripListInvoice from "./TripListInvoice";

const useRowStyles = makeStyles({
    dueDatePast: {
        color: "red"
    },
    dueDateFuture: {
        color: "#71DD13"
    },
    displayRow: {
        display: "none"
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize12: {
        fontSize: 12
    },
    versionBackground: {
        backgroundColor: grey[200]
    },
    pointer: {
        cursor: "pointer"
    },
    paddingCell: {
        "& .mui-jss-MuiTableCell-root": {
            padding: "10px 0px 10px 16px !important",
            fontSize: 12
        }
    },
    hover: {
        "&:hover": {
            backgroundColor: '#f1f1f1'
        }
    },
    cachedRow: {
        backgroundColor: 'rgb(235, 242, 249)',
        borderLeft: '3px solid rgb(35, 183, 229)'
    },
    ellipsisDestination: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    widthTd: {
        width: "21%"
    },
    topVertivalAlign: {
        verticalAlign: "top",
        marginRight: 4
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight500: {
        fontWeight: 500
    },
    genericBorder: {
        background: "#FFFFFF",
        border: "1px solid #DDDDDD",
        boxSizing: "border-box"
    },
    selectBorder: {
        borderRadius: 30
    },
    statusCell: {
        display: 'inline-flex',
        width: 'max-content'
    }
});

const TripListRow = withRouter(({router, row_index, row, to_show_version, columns, status_choices, user, setUpdate, setDuplicationOpen, duplicationOpen, crmData}) => {
    const theme = useTheme();
    const downDesktop = useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const language = useSelector(store => store.header.language);

    const current_version = row.data[GetDataFromCurrentVersion(row.current_version, row.data, to_show_version)];

    const [dialogVersion, setdialogVersion] = useState(current_version);
    const [open, setOpen] = useState(false);
    const [openMenu, setOpenMenu] = useState(null);
    const [openMenu1, setOpenMenu1] = useState(null);
    const [openMenu2, setOpenMenu2] = useState(false);
    const classes = useRowStyles();
    const { enqueueSnackbar } = useSnackbar();
    const cached_row = localStorage.getItem("trip-list");
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    let sort_column = columns.sort((a, b) => {
        return a.order - b.order;
    });

    const handleClick = (event, version) => {
        if (version !== undefined) {
            setdialogVersion(version);
        }
        setOpenMenu(event.currentTarget);
    };
    const handleClick1 = (event, version) => {
        if (version !== undefined) {
            setdialogVersion(version);
        }
        setOpenMenu1(event.currentTarget);
    };
    const handleClose = () => {
        setOpenMenu(null);
        setOpenMenu1(null);
        if (openMenu2) {
            handleClick2();
        }
    };
    const handleClick2 = (event) => {
        setOpenMenu2(!openMenu2);
    };
    const checkTraverlers = () => {
        let is_traveler_default = true;
        let travelers = dialogVersion.travelers;
        for (traveler_index = 0; traveler_index < travelers.length; traveler_index++) {
            let first_name = travelers[traveler_index].first_name;
            let last_name = travelers[traveler_index].last_name;
            let default_last_name = t("global.adult") + " " + (traveler_index + 1).toString();
            if (first_name !== t("trip.traveler") || last_name !== default_last_name) {
                is_traveler_default = false;
            }
        }
        return is_traveler_default;
    };
    const handleStatus = (event) => {
        let new_status = event.target.value;
        let is_traveler_default = checkTraverlers();
        if (new_status !== dialogVersion.status) {
            if (user.client_full.type === 2 && (new_status === "ADDITEM" || new_status === "ONREQUEST" || new_status === "BOOKED" || new_status === "INPROGRESS" || new_status === "FINISHED")) {
                enqueueSnackbar(t("menu.trip_list.agency_cant_change_status"), { variant: "warning" });
            } else if (user.client_full.type === 2 && (dialogVersion.status === "ONREQUEST" || dialogVersion.status === "BOOKED" || dialogVersion.status === "INPROGRESS" || dialogVersion.status === "FINISHED" || dialogVersion.status === "CANCELLED" || dialogVersion.status === "AGENCY_MOD")) {
                enqueueSnackbar(t("menu.trip_list.agency_cant_change_status"), { variant: "warning" });
            } else if (new_status === "AGENCY_MOD" && (is_traveler_default === true)) {
                enqueueSnackbar(t("menu.trip_list.default_travelers_cant_change_status"), { variant: "warning", autoHideDuration: 10000 });
            } else {
                let { pass_check, headers } = CheckBeforeRequest();
                let new_due_date = null;
                if (new_status === "ONGOING" || new_status === "AGENCY_MOD") {
                    new_due_date = moment().add(3, "d").format();
                } else if (new_status === "TOBOOK" && quotation_code === "verdie") {
                    new_due_date = moment().add(6, "d").format();
                }
                if (new_due_date !== null && moment(new_due_date).day() === 0) {
                    new_due_date = moment(new_due_date).add(1, "d").format();
                }

                let request = {
                    status: new_status,
                    due_date: new_due_date
                };
                if (pass_check) {
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${row.id}/versions/${dialogVersion.id}/`,
                        headers: headers,
                        data: JSON.stringify(request)
                    }).then(function (response) {
                        dispatch({
                            type: "MENU_EDIT_VERSION_STATUS",
                            payload: {
                                trip_reference: row.trip_reference,
                                data: response.data
                            }
                        });
                        handleClose();
                        enqueueSnackbar(t("menu.trip_list.success_change_status"), { variant: "success" });
                    }).catch(function (error) {
                        console.log(error.responseJSON);
                        enqueueSnackbar(t("menu.trip_list.failed_change_status"), { variant: "error" });
                    });
                }
            }
        }
    }
    
    const calculateRest = (version) => {
        const total_paid = version.payments_done.reduce((acc, obj) => { return acc + parseFloat(obj.amount ?? '0'); }, 0);
        return (parseFloat(version.total_cost ?? '0') - total_paid).toFixed(2);
    };
    const calculatePaid = (version) => {
        return version.payments_done.reduce((acc, obj) => { return acc + parseFloat(obj.amount ?? '0'); }, 0);
    };
    

    return (
        <Fragment key={row_index}>
            <TableRow className={ clsx(classes.hover, classes.pointer, classes.paddingCell, { [classes.cachedRow]: parseInt(cached_row) === row.id })} onClick={(elem) => {
                //dispatch(SetTripInfo(row));
                dispatch({
                    type: "MENU_SET_PREV_TRIP_INFO",
                    payload: {
                        trip_info: row
                    }
                });
                localStorage.setItem("trip-list", row.id);
                router.push(`/${window.url_name}/menu/trip-info/${row.id}`);
            }}>
                {
                    crmData?.user && 
                    <TableCell align={"left"} onClick={(e) => {e.stopPropagation()}}>
                        <Fragment>
                            <IconButton size="small" onClick={() => {
                                if (crmData && crmData.invoiceAction !== undefined) { 
                                    console.log('crmData.invoice_trip_id === row.id', crmData.invoice_trip_id, row.id);
                                    if (crmData.invoice_trip_id === row.id) {
                                        crmData.invoiceAction(null);    
                                    } else {
                                        crmData?.invoiceAction(row);
                                    }
                                    return;
                                }
                            }}>
                                {crmData?.invoice_trip_id === row.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        </Fragment>
                    </TableCell>
                }
                <TableCell component="th" scope="row" >
                    <div className={ clsx({ [classes.statusCell]: ["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines'].includes(quotation_code) })}>
                        {
                            row.data.length > 1 && !["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines'].includes(quotation_code) && (
                                <IconButton size="small" onClick={(e) => {
                                    e.stopPropagation();
                                    setOpen(!open);
                                }} >
                                    {open ? <ArrowDropDown /> : <ArrowRight />}
                                </IconButton>
                            )
                        }
                        {
                            row.data.length > 1 && ["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines'].includes(quotation_code) && (
                                <div style={{ marginTop: 19, marginLeft: '-30px' }}>
                                    <IconButton size="small" onClick={(e) => {
                                        e.stopPropagation();
                                        setOpen(!open);
                                    }}>
                                        {open ? <ArrowDropDown /> : <ArrowRight />}
                                    </IconButton>
                                </div>
                            )
                        }
                        {
                            !["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines'].includes(quotation_code) && (
                                <Tooltip title={quotation_code === "volonline" ? t(`trip.status.volonline.${current_version.status}`) : (t("menu.trip_list." + (current_version.status === "TOBOOK" ? (quotation_code === "verdie" ? "TOBOOK_VERDIE" : "TOBOOK") : current_version.status)))}>
                                    <div className={"status" + " " + GetStatusColor(current_version.status)} />
                                </Tooltip>
                            )
                        }
                        {
                            ["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines'].includes(quotation_code) && (
                                <div>
                                    {
                                        current_version.status_contract !== null && (
                                            <div>
                                                <Tooltip title={t("menu.trip_list.TRIP_STATUS_CONTRACT")}>
                                                    <ReceiptIcon fontSize={"small"} style={{ verticalAlign: "2px", fontSize: 15 }}/>
                                                </Tooltip>
                                                <Tooltip title={t("menu.trip_list." + (current_version.status_contract))}>
                                                    <FiberManualRecordIcon style={{ fontSize: 19, color: GetStatusColorHexa(current_version.status_contract) }} />
                                                </Tooltip>
                                            </div>
                                        )
                                    }
                                    {
                                        current_version.status_contract === "CONFIRMED" && current_version.status_modification !== null && (
                                            <div>
                                                {
                                                    current_version.status_modification === "AMENDMENT" && (
                                                        <Tooltip title={t("menu.trip_list.TRIP_STATUS_MODIFICATION")}>
                                                            <SettingsIcon fontSize={"small"} style={{ verticalAlign: "2px", fontSize: 15 }}/>
                                                        </Tooltip>
                                                    )
                                                }
                                                {
                                                    current_version.status_modification === "FIXED_PV" && (
                                                        <Tooltip title={t("menu.trip_list.TRIP_STATUS_BOOKING_PROGRESS")}>
                                                            <AssignmentTurnedInIcon fontSize={"small"} style={{ verticalAlign: "2px", fontSize: 15 }}/>
                                                        </Tooltip>
                                                    )
                                                }
                                                <Tooltip title={t("menu.trip_list." + (current_version.status_modification))}>
                                                    <FiberManualRecordIcon style={{ fontSize: 19, color: GetStatusColorHexa(current_version.status_modification) }} />
                                                </Tooltip>
                                            </div>
                                        )
                                    }
                                    {
                                        current_version.status_contract === "CONFIRMED" && current_version.status_modification === "FIXED_PV" && current_version.status_booking_progress !== null && (
                                            <div>
                                                <Tooltip title={t("menu.trip_list.TRIP_STATUS_BOOKING_PROGRESS")}>
                                                    <AssignmentIcon fontSize={"small"} style={{ verticalAlign: "2px", fontSize: 15 }}/>
                                                </Tooltip>
                                                <Tooltip title={t("menu.trip_list." + (current_version.status_booking_progress))}>
                                                    <FiberManualRecordIcon style={{ fontSize: 19, color: GetStatusColorHexa(current_version.status_booking_progress) }} />
                                                </Tooltip>
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                </TableCell>
                {
                    sort_column.map((column, column_index) => {
                        if (column.active) {
                            if (column.field_name === t("menu.margin_title")) {
                                return <TableCell align={"right"}>0</TableCell>;
                            }
                            if (column.field_name === t("menu.trip_list.total_paid")) {
                                return <TableCell align={"right"}>{Intl.NumberFormat(language, { style: "currency", currency: row?.currency?.iso_code ? row?.currency?.iso_code : 'EUR' }).format(Math.ceil(calculatePaid(current_version)))}</TableCell>;
                            }
                            if (column.field_name === t("global.rest")) {
                                return <TableCell align={"right"}>{Intl.NumberFormat(language, { style: "currency", currency: row?.currency?.iso_code ? row?.currency?.iso_code : 'EUR' }).format(Math.ceil(calculateRest(current_version)))}</TableCell>;
                            }
                            if (column.field_name === t("global.total_price")) {
                                return <TableCell align={"right"}>{Intl.NumberFormat(language, { style: "currency", currency: row?.currency?.iso_code ? row?.currency?.iso_code : 'EUR' }).format(Math.ceil(current_version.total_cost))}</TableCell>;
                            }
                            return (
                                <RenderField key={column_index} row={row} version={current_version} column={column} column_index={column_index} type={"main"}/>
                            );
                        }
                    })
                }
                {
                    !crmData?.user && 
                    <TableCell align={"left"} onClick={(e) => {e.stopPropagation()}}>
                        <Fragment>
                            <IconButton size="small" onClick={(e) => {handleClick(e, current_version)}}>
                                <MoreVertOutlinedIcon/>
                            </IconButton>
                            <Menu
                                anchorEl={openMenu}
                                keepMounted
                                open={Boolean(openMenu)}
                                onClose={handleClose}
                                disableScrollLock={true}
                            >
                                <MenuItem onClick={(e) => {
                                    e.stopPropagation();
                                    handleClose();
                                    // dispatch({
                                    //     type: "MENU_SET_TRIP_DUPLICATE_AND_OPEN_DUPLICATE_MODAL",
                                    //     payload: {
                                    //         trip_to_duplicate: row,
                                    //         duplicate_modal: true,
                                    //         duplicate_step: 1
                                    //     }
                                    // });
                                    dispatch({
                                        type: "ITINERARY_TYPE_SET_ITINERARY_TYPE_DETAILS_DATA_VERSION",
                                        payload: {
                                            itinerary_type_details_data: row,
                                            version: row.current_version
                                        }
                                    });
                                    setDuplicationOpen(!duplicationOpen)
                                }}>{t("menu.trip_list.duplicate_trip")}</MenuItem>
                                <MenuItem onClick={handleClick2}>{t("menu.trip_list.edit_trip_status")}</MenuItem>
                            </Menu>
                        </Fragment>
                    </TableCell>
                }
            </TableRow>
            {
                crmData?.invoice_trip_id === row.id && 
                <TableRow>
                    <TableCell colSpan={20} className="crm-invoice-list">
                        <TripListInvoice crmData={{ invoices: crmData?.invoices }} />
                    </TableCell>
                </TableRow>
            }
            {
                row.data.length > 1 && row.data.map((version, version_index) => {
                    return (
                        <TableRow key={version_index} className={clsx(classes.pointer, { [classes.displayRow]: !open, [classes.versionBackground]: true, [classes.paddingCell]: true })}>
                            <TableCell component="th" scope="row">
                                {
                                    !["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines'].includes(quotation_code) && (
                                        <Tooltip title={t("menu.trip_list." + (version.status === "TOBOOK" ? (quotation_code === "verdie" ? "TOBOOK_VERDIE" : "TOBOOK") : version.status))}>
                                            <div className={"status" + " " + GetStatusColor(version.status)} />
                                        </Tooltip>
                                    )
                                }
                                {
                                    ["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines'].includes(quotation_code) && (
                                        <div>
                                            {
                                                version.status_contract !== null && (
                                                    <div>
                                                        <Tooltip title={t("menu.trip_list.TRIP_STATUS_CONTRACT")}>
                                                            <ReceiptIcon fontSize={"small"} style={{ verticalAlign: "2px", fontSize: 15 }}/>
                                                        </Tooltip>
                                                        <Tooltip title={t("menu.trip_list." + (version.status_contract))}>
                                                            <FiberManualRecordIcon style={{ fontSize: 19, color: GetStatusColorHexa(version.status_contract) }} />
                                                        </Tooltip>
                                                    </div>
                                                )
                                            }
                                            {
                                                version.status_contract === "CONFIRMED" && version.status_modification !== null && (
                                                    <div>
                                                        {
                                                            version.status_modification === "AMENDMENT" && (
                                                                <Tooltip title={t("menu.trip_list.TRIP_STATUS_MODIFICATION")}>
                                                                    <SettingsIcon fontSize={"small"} style={{ verticalAlign: "2px", fontSize: 15 }}/>
                                                                </Tooltip>
                                                            )
                                                        }
                                                        {
                                                            version.status_modification === "FIXED_PV" && (
                                                                <Tooltip title={t("menu.trip_list.TRIP_STATUS_BOOKING_PROGRESS")}>
                                                                    <AssignmentTurnedInIcon fontSize={"small"} style={{ verticalAlign: "2px", fontSize: 15 }}/>
                                                                </Tooltip>
                                                            )
                                                        }
                                                        <Tooltip title={t("menu.trip_list." + (version.status_modification))}>
                                                            <FiberManualRecordIcon style={{ fontSize: 19, color: GetStatusColorHexa(version.status_modification) }} />
                                                        </Tooltip>
                                                    </div>
                                                )
                                            }
                                            {
                                                version.status_contract === "CONFIRMED" && version.status_modification === "FIXED_PV" && version.status_booking_progress !== null && (
                                                    <div>
                                                        <Tooltip title={t("menu.trip_list.TRIP_STATUS_BOOKING_PROGRESS")}>
                                                            <AssignmentIcon fontSize={"small"} style={{ verticalAlign: "2px", fontSize: 15 }}/>
                                                        </Tooltip>
                                                        <Tooltip title={t("menu.trip_list." + (version.status_booking_progress))}>
                                                            <FiberManualRecordIcon style={{ fontSize: 19, color: GetStatusColorHexa(version.status_booking_progress) }} />
                                                        </Tooltip>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </TableCell>
                            {
                                sort_column.map((column, column_index) => {
                                    if (column.active) {
                                        if (column.field_name === t("menu.margin_title")) {
                                            return <TableCell align={"right"}>0</TableCell>;
                                        }
                                        if (column.field_name === t("menu.trip_list.total_paid")) {
                                            return <TableCell align={"right"}>{Intl.NumberFormat(language, { style: "currency", currency: row?.currency?.iso_code ? row?.currency?.iso_code : 'EUR' }).format(Math.ceil(calculatePaid(version)))}</TableCell>;
                                        }
                                        if (column.field_name === t("global.rest")) {
                                            return <TableCell align={"right"}>{Intl.NumberFormat(language, { style: "currency", currency: row?.currency?.iso_code ? row?.currency?.iso_code : 'EUR' }).format(Math.ceil(calculateRest(version)))}</TableCell>;
                                        }
                                        if (column.field_name === t("global.total_price")) {
                                            return <TableCell align={"right"}>{Intl.NumberFormat(language, { style: "currency", currency: row?.currency?.iso_code ? row?.currency?.iso_code : 'EUR' }).format(Math.ceil(version.total_cost))}</TableCell>;
                                        }
                                        return (
                                            <RenderField key={column_index} row={row} version={version} column={column} version_index={version_index} column_index={column_index} type={"version"}/>
                                        );
                                    }
                                })
                            }
                            {
                                !crmData?.user && 
                                <TableCell align={"left"} onClick={(e) => {e.stopPropagation()}}>
                                    <Fragment>
                                        <IconButton size="small" onClick={(e) => {handleClick1(e, version)}}>
                                            <MoreVertOutlinedIcon/>
                                        </IconButton>
                                        <Menu
                                            anchorEl={openMenu1}
                                            keepMounted
                                            open={Boolean(openMenu1)}
                                            onClose={handleClose}
                                            disableScrollLock={true}
                                        >
                                            <MenuItem onClick={(e) => {
                                                e.stopPropagation();
                                                handleClose();
                                                // dispatch({
                                                //     type: "MENU_SET_TRIP_DUPLICATE_AND_OPEN_DUPLICATE_MODAL",
                                                //     payload: {
                                                //         trip_to_duplicate: row,
                                                //         duplicate_modal: true,
                                                //         duplicate_step: 1
                                                //     }
                                                // });
                                                dispatch({
                                                    type: "ITINERARY_TYPE_SET_ITINERARY_TYPE_DETAILS_DATA_VERSION",
                                                    payload: {
                                                        itinerary_type_details_data: row,
                                                        version: version.id
                                                    }
                                                });
                                                setDuplicationOpen(!duplicationOpen)
                                            }}>{t("menu.trip_list.duplicate_trip")}</MenuItem>
                                            <MenuItem onClick={handleClick2}>{t("menu.trip_list.edit_trip_status")}</MenuItem>
                                        </Menu>
                                    </Fragment>
                                </TableCell>
                            }
                        </TableRow>
                    );
                })
            }
            <Dialog open={openMenu2} onClose={handleClick2}>
                <DialogTitle>
                    <Grid container justify={ "space-between" } alignItems={ "center" }>
                        {
                            downDesktop ? (
                                <Grid item>
                                    <IconButton edge={ "end" } onClick={handleClick2}>
                                        <NavigateBeforeIcon/>
                                    </IconButton>
                                    <span style={{ fontSize: 16 }}>{t("menu.trip_list.edit_trip_status")}</span>
                                </Grid>
                            ) : (
                                <Fragment>
                                    <Grid item>
                                        {t("menu.trip_list.edit_trip_status")}
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={handleClick2}><CloseIcon/></IconButton>
                                    </Grid>
                                </Fragment>
                            )
                        }
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item>
                            <Select
                                labelId="change_status_label"
                                id="change_trip_status"
                                value={dialogVersion.status}
                                onChange={handleStatus}
                                className={`${classes.genericBorder} ${classes.selectBorder}`}
                                style={{ paddingLeft: 5 }}
                                MenuProps={{ disableScrollLock: true }}
                                disableUnderline
                            >
                                {
                                    status_choices.map((status, status_index) =>{
                                        let new_value = status === "TOBOOK_VERDIE" ? "TOBOOK" : status;
                                        return (
                                            <MenuItem value={new_value} key={`${status_index}`}>
                                                <Grid container className={classes.itemsCenter}>
                                                    <FiberManualRecordIcon style={{ color: GetStatusColorHexa(status), marginRight: 3 }}/>
                                                    <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`} style={{ paddingTop: 3 }}>
                                                        { t(`trip.status.${quotation_code}.${status}`) }
                                                        {/*{quotation_code === "volonline" ? t(`trip.status.volonline.${status}`) : t(`menu.trip_list.${status}`)}*/}
                                                    </Grid>
                                                </Grid>
                                            </MenuItem>
                                        );
                                    })
                                }
                            </Select>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
});
export default React.memo(TripListRow);
