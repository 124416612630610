import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import { Close, FlightOutlined, Work } from "@mui/icons-material";
import axios from "axios";
import { isEmpty } from "lodash";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { isAxiosError } from "./utils/isAxiosError";
import { diff } from "deep-object-diff";
import { getFlightDiffObject } from "./utils/getFlightDiffObject";
import { useFlightPnrRefresh } from "./network/flightPnrRefresh";
import { useGetPrice } from "./utils/getPrice";
import { useShowError } from "../Utils/showError";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";
import CheckResponse from "../Flight/FlightSelected/Functions/CheckResponse";
import { Flight } from "../Itinerary/network/flight";
import { StatusBooking } from "../Itinerary/objects/statusBooking";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    open: boolean,
    flight: Flight,
    onClose: () => void
}

export function CartConstructionProductsTableEditFlightRetrievePnrModal(props: Props): JSX.Element {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const user = useSelector((state: AppState) => state.user.user);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const tripEndDate = useSelector((state: AppState) => state.trip.end_date);
    const [loading, setLoading] = useState(false);
    const [diffObject, setDiffObject] = useState<{
        origin: ReturnType<typeof getFlightDiffObject>,
        target: ReturnType<typeof getFlightDiffObject>,
        diff: Record<string, unknown>
    } | null>(null);
    const replaceDataRef = useRef<[Flight, Flight] | null>(null);
    const showError = useShowError();
    const getPrice = useGetPrice();
    const refreshPnr = useFlightPnrRefresh({
        onTrigger() {
            setLoading(true);
        },
        async onSuccess(from, to) {
            await onDeleteFlight(from);
            const result = CheckResponse([to], trip?.end_date);
            if (result && result[0]) {
                const originDiffObject = getFlightDiffObject({
                    language,
                    flight: from,
                    price: getPrice(from.prices),
                    user,
                    tripEndDate,
                    t
                });
                const targetDiffObject = getFlightDiffObject({
                    language,
                    flight: result[0],
                    price: getPrice(result[0].prices),
                    user,
                    tripEndDate,
                    t
                });
                setDiffObject({
                    origin: originDiffObject,
                    target: targetDiffObject,
                    diff: diff(originDiffObject, targetDiffObject) as Record<string, unknown>
                });
                replaceDataRef.current = [from, result[0]];
            }
        },
        async onError(error) {
            if (
                isAxiosError(error) &&
                error.response?.status === 404
            ) {
                await onChangeFlightStatus(props.flight, StatusBooking.CANCELLED);
            } else {
                showError(error);
            }
        },
        onFinally() {
            setLoading(false);
        }
    });

    const onChangeFlightStatus = async (flight: Flight, status: StatusBooking | null) => {
        const { headers } = CheckBeforeRequest();
        try {
            const response = await axios({
                method: 'POST',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/status-manager/${flight.id}/status/`,
                data: {
                    status_booking: status,
                    item_reference: flight.booking_status?.item_reference ?? 'UNKNOWN'
                }
            });
            let new_flight = [];
            new_flight.push(response.data);
            let arr = CheckResponse(new_flight, trip?.end_date);
            dispatch({ type: 'FLIGHT_EDIT_CART_BY_ID', payload: arr[0] });
        } catch (error) {
            showError(error as Error);
        }
    };

    const onDeleteFlight = async (flight: Flight) => {
        await onChangeFlightStatus(flight, null);
        try {
            const { headers } = CheckBeforeRequest();
            await axios({
                method: 'DELETE',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${flight.id}/`
            });
        } catch (error) {
            showError(error as Error);
        }
    };

    const onClose = () => {
        if (replaceDataRef.current) {
            dispatch({
                type: 'FLIGHT_REPLACE_CART',
                payload: {
                    from: replaceDataRef.current[0],
                    to: replaceDataRef.current[1]
                }
            });
        } else {
            props.onClose();
        }
    };

    return (
        <>
            <Dialog
                open={props.open}
                onClose={onClose}
                onClick={(event) => event.stopPropagation()}
                maxWidth={
                    diffObject ?
                        'lg' :
                        'md'
                }
                fullWidth
            >
                <DialogTitle>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h5" fontWeight="bold" component="div">
                            {t<string>('cart-material.cart-construction-pnr-retrieve-title')}
                        </Typography>
                        <IconButton onClick={onClose}>
                            <Close />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    {
                        !loading &&
                        <>
                            {
                                !diffObject &&
                                t<string>('cart-material.cart-construction-pnr-retrieve-continue')
                            }
                            {
                                diffObject && !isEmpty(diffObject.diff) &&
                                <>
                                    {
                                        Object.keys(diffObject.diff).filter((key) => {
                                            return key !== 'outbounds';
                                        }).length > 0 &&
                                        <Box sx={{ marginBottom: 2 }}>
                                            <Typography fontWeight="bold" gutterBottom>
                                                {t<string>('cart-material.cart-construction-pnr-retrieve-flight-info-title')}
                                            </Typography>
                                            {
                                                Object.keys(diffObject.diff).filter((key) => {
                                                    return key !== 'outbounds';
                                                }).map((key) => {
                                                    const from = (diffObject.origin as unknown as {[key: string]: string | undefined})[key];
                                                    const to = (diffObject.target as unknown as {[key: string]: string | undefined})[key];

                                                    if (from && to) {
                                                        return (
                                                            <Stack key={key} direction="row" spacing={1}>
                                                                <Typography>
                                                                    {t<string>(`cart-material.cart-construction-pnr-diff-keys.${key}`)}
                                                                </Typography>
                                                                <Typography sx={{ color: 'red' }}>
                                                                    {from}
                                                                </Typography>
                                                                <Typography sx={{ color: 'green' }}>
                                                                    {to}
                                                                </Typography>
                                                            </Stack>
                                                        );
                                                    } else if (from && !to) {
                                                        return (
                                                            <Stack key={key} direction="row" spacing={1}>
                                                                <Typography>
                                                                    {t<string>(`cart-material.cart-construction-pnr-diff-keys.${key}`)}
                                                                </Typography>
                                                                <Typography sx={{ color: 'red' }}>
                                                                    {t<string>('cart-material.cart-construction-pnr-retrieve-deleted')}
                                                                </Typography>
                                                            </Stack>
                                                        );
                                                    } else if (!from && to) {
                                                        return (
                                                            <Stack key={key} direction="row" spacing={1}>
                                                                <Typography>
                                                                    {t<string>(`cart-material.cart-construction-pnr-diff-keys.${key}`)}
                                                                </Typography>
                                                                <Typography sx={{ color: 'green' }}>
                                                                    {t<string>('cart-material.cart-construction-pnr-retrieve-added')}
                                                                </Typography>
                                                            </Stack>
                                                        );
                                                    }
                                                })
                                            }
                                        </Box>
                                    }
                                    {
                                        diffObject.diff.outbounds &&
                                        <>
                                            <Typography fontWeight="bold" gutterBottom>
                                                {t<string>('cart-material.cart-construction-pnr-retrieve-segments-info-title')}
                                            </Typography>
                                            <Typography fontWeight={100} textAlign="center" sx={{ marginTop: 1, marginBottom: 1 }}>
                                                {t<string>('cart-material.cart-construction-pnr-retrieve-segments-before')}
                                            </Typography>
                                            <table width="100%">
                                                <tbody>
                                                    {
                                                        diffObject.origin.outbounds.map((outbound, index) => (
                                                            <React.Fragment key={index}>
                                                                <Box
                                                                    sx={{ textAlign: 'center' }}
                                                                    component="tr"
                                                                >
                                                                    <td>
                                                                        <Stack direction="row" alignItems="center" spacing={1}>
                                                                            <Typography>{outbound.airline}</Typography>
                                                                            <Typography>
                                                                                { t<string>('global.flight') } { index + 1}
                                                                            </Typography>
                                                                        </Stack>
                                                                    </td>
                                                                    <td>
                                                                        <Typography>
                                                                            { window.moment.utc(outbound.departureTime).format('LL') }
                                                                        </Typography>
                                                                    </td>
                                                                    <td>
                                                                        <Typography>
                                                                            {outbound.departurePlace}
                                                                        </Typography>
                                                                    </td>
                                                                    <Box
                                                                        sx={{
                                                                            paddingLeft: 3,
                                                                            paddingRight: 3,
                                                                            textAlign: 'center'
                                                                        }}
                                                                        component="td"
                                                                    >
                                                                        <FlightOutlined
                                                                            sx={{ transform: 'rotateZ(90deg)' }}
                                                                        />
                                                                    </Box>
                                                                    <td>
                                                                        <Typography>
                                                                            {outbound.arrivalPlace}
                                                                        </Typography>
                                                                    </td>
                                                                    <td>
                                                                        <Typography>
                                                                            { window.moment.utc(outbound.arrivalTime).format('LL') }
                                                                        </Typography>
                                                                    </td>
                                                                    <td>
                                                                        <Stack
                                                                            direction="row"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                            spacing={1}
                                                                        >
                                                                            <Box
                                                                                sx={{
                                                                                    position: 'relative',
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center'
                                                                                }}
                                                                            >
                                                                                <Work
                                                                                    fontSize="large"
                                                                                    sx={
                                                                                        outbound.luggages === 0 ?
                                                                                            { color: 'gray' } :
                                                                                            { color: '#E6592F' }
                                                                                    }
                                                                                />
                                                                                <Typography
                                                                                    sx={{
                                                                                        position: 'absolute',
                                                                                        top: 'calc(50% + 4px)',
                                                                                        left: '50%',
                                                                                        transform: 'translate(-50%, -50%)',
                                                                                        color: '#fff'
                                                                                    }}
                                                                                >
                                                                                    {outbound.luggages}
                                                                                </Typography>
                                                                            </Box>
                                                                            <Typography textAlign="center">
                                                                                <div>
                                                                                    {outbound.classType}
                                                                                </div>
                                                                            </Typography>
                                                                        </Stack>
                                                                    </td>
                                                                </Box>
                                                                <Box
                                                                    sx={{ textAlign: 'center' }}
                                                                    component="tr"
                                                                >
                                                                    <td />
                                                                    <td>
                                                                        <Typography variant="caption">
                                                                            { outbound.flightNumber }
                                                                        </Typography>
                                                                    </td>
                                                                    <td>
                                                                        <Typography variant="caption">
                                                                            { window.moment.utc(outbound.departureTime).format('HH:mm') }
                                                                        </Typography>
                                                                    </td>
                                                                    <td />
                                                                    <td>
                                                                        <Typography variant="caption">
                                                                            { window.moment.utc(outbound.arrivalTime).format('HH:mm') }
                                                                        </Typography>
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            outbound.stopOvers === 0 ?
                                                                                <Typography sx={{ textTransform: 'capitalize' }} variant="caption">
                                                                                    {t<string>("flight_search.direct_flight")}
                                                                                </Typography> :
                                                                                <Typography variant="caption">
                                                                                    {
                                                                                        outbound.stopOvers === 1 ?
                                                                                            `1 ${t<string>('flight_search.stopover')}` :
                                                                                            `${outbound.stopOvers} ${t<string>('flight_search.stopovers')}`
                                                                                    }
                                                                                    {
                                                                                        outbound.stopOverIata &&
                                                                                    outbound.stopOverIata
                                                                                    }
                                                                                </Typography>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            outbound.classOfService &&
                                                                            <Box
                                                                                sx={{ opacity: 0.4 }}
                                                                            >
                                                                                {outbound.classOfService}
                                                                            </Box>
                                                                        }
                                                                    </td>
                                                                </Box>
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                            <Typography fontWeight={100} textAlign="center" sx={{ marginTop: 2.5, marginBottom: 1 }}>
                                                {t<string>('cart-material.cart-construction-pnr-retrieve-segments-after')}
                                            </Typography>
                                            <table width="100%">
                                                <tbody>
                                                    {
                                                        diffObject.target.outbounds.map((outbound, index) => (
                                                            <React.Fragment key={index}>
                                                                <Box
                                                                    sx={{ textAlign: 'center' }}
                                                                    component="tr"
                                                                >
                                                                    <td>
                                                                        <Stack direction="row" alignItems="center" spacing={1}>
                                                                            <Typography>{outbound.airline}</Typography>
                                                                            <Typography>
                                                                                { t<string>('global.flight') } { index + 1}
                                                                            </Typography>
                                                                        </Stack>
                                                                    </td>
                                                                    <td>
                                                                        <Typography>
                                                                            { window.moment.utc(outbound.departureTime).format('LL') }
                                                                        </Typography>
                                                                    </td>
                                                                    <td>
                                                                        <Typography>
                                                                            {outbound.departurePlace}
                                                                        </Typography>
                                                                    </td>
                                                                    <Box
                                                                        sx={{
                                                                            paddingLeft: 3,
                                                                            paddingRight: 3,
                                                                            textAlign: 'center'
                                                                        }}
                                                                        component="td"
                                                                    >
                                                                        <FlightOutlined
                                                                            sx={{ transform: 'rotateZ(90deg)' }}
                                                                        />
                                                                    </Box>
                                                                    <td>
                                                                        <Typography>
                                                                            {outbound.arrivalPlace}
                                                                        </Typography>
                                                                    </td>
                                                                    <td>
                                                                        <Typography>
                                                                            { window.moment.utc(outbound.arrivalTime).format('LL') }
                                                                        </Typography>
                                                                    </td>
                                                                    <td>
                                                                        <Stack
                                                                            direction="row"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                            spacing={1}
                                                                        >
                                                                            <Box
                                                                                sx={{
                                                                                    position: 'relative',
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center'
                                                                                }}
                                                                            >
                                                                                <Work
                                                                                    fontSize="large"
                                                                                    sx={
                                                                                        outbound.luggages === 0 ?
                                                                                            { color: 'gray' } :
                                                                                            { color: '#E6592F' }
                                                                                    }
                                                                                />
                                                                                <Typography
                                                                                    sx={{
                                                                                        position: 'absolute',
                                                                                        top: 'calc(50% + 4px)',
                                                                                        left: '50%',
                                                                                        transform: 'translate(-50%, -50%)',
                                                                                        color: '#fff'
                                                                                    }}
                                                                                >
                                                                                    {outbound.luggages}
                                                                                </Typography>
                                                                            </Box>
                                                                            <Typography textAlign="center">
                                                                                <div>
                                                                                    {outbound.classType}
                                                                                </div>
                                                                            </Typography>
                                                                        </Stack>
                                                                    </td>
                                                                </Box>
                                                                <Box
                                                                    sx={{ textAlign: 'center' }}
                                                                    component="tr"
                                                                >
                                                                    <td />
                                                                    <td>
                                                                        <Typography variant="caption">
                                                                            { outbound.flightNumber }
                                                                        </Typography>
                                                                    </td>
                                                                    <td>
                                                                        <Typography variant="caption">
                                                                            { window.moment.utc(outbound.departureTime).format('HH:mm') }
                                                                        </Typography>
                                                                    </td>
                                                                    <td />
                                                                    <td>
                                                                        <Typography variant="caption">
                                                                            { window.moment.utc(outbound.arrivalTime).format('HH:mm') }
                                                                        </Typography>
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            outbound.stopOvers === 0 ?
                                                                                <Typography sx={{ textTransform: 'capitalize' }} variant="caption">
                                                                                    {t<string>("flight_search.direct_flight")}
                                                                                </Typography> :
                                                                                <Typography variant="caption">
                                                                                    {
                                                                                        outbound.stopOvers === 1 ?
                                                                                            `1 ${t<string>('flight_search.stopover')}` :
                                                                                            `${outbound.stopOvers} ${t<string>('flight_search.stopovers')}`
                                                                                    }
                                                                                    {
                                                                                        outbound.stopOverIata &&
                                                                                    outbound.stopOverIata
                                                                                    }
                                                                                </Typography>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            outbound.classOfService &&
                                                                            <Box
                                                                                sx={{ opacity: 0.4 }}
                                                                            >
                                                                                {outbound.classOfService}
                                                                            </Box>
                                                                        }
                                                                    </td>
                                                                </Box>
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </>
                                    }
                                </>
                            }
                            {
                                diffObject && isEmpty(diffObject.diff) &&
                                <Alert variant="filled" severity="info">
                                    {t<string>('cart-material.cart-construction-pnr-retrieve-no-change')}
                                </Alert>
                            }
                        </>
                    }
                    {
                        loading &&
                        <Box
                            sx={{
                                textAlign: 'center',
                                padding: 4,
                                visibility: 'hidden'
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    }
                </DialogContent>
                <DialogActions>
                    {
                        !diffObject &&
                        <>
                            <Button onClick={onClose}>
                                {t<string>('shared.cancel')}
                            </Button>
                            <Button onClick={() => refreshPnr(props.flight)}>
                                {t<string>('global.continue')}
                            </Button>
                        </>
                    }
                    {
                        diffObject &&
                        <Button onClick={onClose}>
                            {t<string>('global.close')}
                        </Button>
                    }
                </DialogActions>
            </Dialog>
            <LoadingBackDrop open={loading} />
        </>
    );
}
